import _ from 'lodash';
import { Observable } from 'rxjs/Observable';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { IGridExportColumn } from './IGridExportColumn.interface';

export class MetricsGridConfig {
	public lastUpdated?: Date;

	constructor(
		public groups?: Array<IGridColumnGroup>,
		public rowExpansion?: (paren: any) => Observable<Array<any>>,
		public enableExcelExport?: boolean,
		public enableDelete?: boolean,
		public disableDeleteButton?: boolean
	) {	}

	public getAllColumns = (): Array<IGridExportColumn> => {
		const columns: Array<IGridExportColumn> = [];
		this.groups.forEach(g => {
			if (g.visible) {
				g.columns.forEach(c => {
					if (!c.skipExport) {
						columns.push({
							group: g.title,
							isPercent: c.isPercent,
							isGroupingEnabled: c.isGroupingEnabled,
							displayName: g.title + ' ' + c.title,
							field: c.field,
							minuend: c.minuend,
							subtrahend: c.subtrahend,
							isString: c.isString,
							showPercentDifferenceFrom: c.showPercentDifferenceFrom,
							hasDecimals: c.hasDecimals
						});

					}
				});
			}
		});
		return columns;
	}

	/**
	 * Deep clones this object and translates group titles, group column selector titles and
	 * column titles.
	 */
	public cloneAndTranslate(translate: (text: string) => string): MetricsGridConfig {
		const newConfig = {...this};
		newConfig.groups.forEach(group => {
			group.title = translate(group.title);
			group.columnSelectorTitle = translate(group.columnSelectorTitle);
			if (group.columns && group.columns.length) {
				group.columns.forEach((column) => {
					column.title = translate(column.title);
				});
			}
		});
		return (<MetricsGridConfig>newConfig);
	}
}

