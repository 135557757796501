import {
  Component,
  ViewEncapsulation,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { Router, UrlTree, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { map } from 'rxjs/operators';
import { zip } from 'rxjs/observable/zip';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';
import { PageOptionsNg, SortOptions, SortOptionsNg } from '../models';
import {
  BreadcrumbService,
  ActiveFilterService,
  ColumnDefinitionService,
  MetricsGridConfig,
  ViewService,
  ComparisonMode,
  ClientProfileService,
  GridTableNgComponent,
  FormatService,
  GridSortingService,
  IntroJsService,
} from './../core';
import { HeaderService } from '../header';
import { EquipmentService } from './equipment.service';
import { DownloadsService } from '../downloads';
import { CategoryCard } from '../models';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'rdo-categories',
  templateUrl: 'categories.component.html',
  styleUrls: ['categories.component.scss', 'equipment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesComponent implements OnInit, OnDestroy {
  @ViewChild('productTypeGrid') productTypeGrid: GridTableNgComponent;
  selectedCategory: CategoryCard;
  productTypes: any;
  frozenRows: any;
  loading = false;
  cardChanging = true;
  cards: Array<CategoryCard>;
  gridConfig: MetricsGridConfig;
  enableExport: boolean;
  paging = new PageOptionsNg();
  sorting: SortOptionsNg;
  totalCount = 0;
  frozenWidth: string;
  GRID_NAME: string = 'PRODUCT_TYPE_CATEGORIES';
  mode: ComparisonMode = ComparisonMode.Benchmark;
  protected subscriptions = [];
  private clientProfileServiceSubscription: Subscription;
  protected categoriesPageSize = 6;
  protected categoriesPage = 1;

  constructor(
    private authenticationService: AuthenticationService,
    private filterService: ActiveFilterService,
    private equipmentService: EquipmentService,
    private downloadsService: DownloadsService,
    private columnService: ColumnDefinitionService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private clientProfileService: ClientProfileService,
    private viewService: ViewService,
    private route: ActivatedRoute,
    private formatService: FormatService,
    protected translateService: TranslateService,
    private gridSortingService: GridSortingService,
    private introJsService: IntroJsService
  ) {
    this.setGridPageSize();
  }

  ngOnInit() {
    this.setCategoryPageSize();
    this.gridSortingService.setGridName(this.GRID_NAME);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.configureGrid().subscribe((isconfigured) => {
      if (isconfigured === true) {
        this.subscriptions.push(
          this.route.params.subscribe((params) => {
            const category = params.category
              ? decodeURIComponent(params.category)
              : null;
            if (category) {
              this.renderAllCategories();
            }
            this.selectedCategory = new CategoryCard({
              RouseCategory: category,
            });
            this.updateCategoryIcon();
            this.load(false, true);
          })
        );
        this.subscriptions.push(
          this.filterService.filterChange.subscribe(() => {
            this.cardChanging = true;
            this.paging.page = 1;
            if (this.productTypeGrid) {
              this.productTypeGrid.resetPaginator();
            }
            this.load(true, true);
          })
        );
        this.subscriptions.push(
          this.authenticationService.userInfoView.subscribe((userInfo) => {
            if (this.gridConfig) {
              this.enableExport = userInfo.HasClientAccessToDownloads;
              this.gridConfig.enableExcelExport =
                userInfo.HasClientAccessToExportData;
            }
          })
        );
        this.subscriptions.push(
          this.translateService.onLangChange.subscribe(() => this.load())
        );
      }
    });
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  load = (reloadCards: boolean = false, resetPaginator: boolean = false) => {
    if (!this.cards || reloadCards) {
      this.authenticationService.startSentryTransaction(this.router.routerState.snapshot.url);
      this.cardChanging = true;
      zip(
        this.equipmentService
          .getCategoryCards()
          .pipe(map((d) => <CategoryCard[]>d)),
        this.viewService.getComparisonMode()
      ).subscribe((results: [CategoryCard[], ComparisonMode]) => {
        this.cards = results[0];
        this.mode = results[1];
        const cat = _.find(
          this.cards,
          (c: CategoryCard) =>
            c.RouseCategory === this.selectedCategory.RouseCategory
        );
        if (!cat && this.cards.length > 0) {
          this.selectCategory(this.cards[0]);
        }
        this.updateCategoryIcon(cat);
        this.loadGridData(resetPaginator);
        this.authenticationService.finishSentryTransaction();
      });
    } else {
      this.loadGridData(resetPaginator);
    }
    this.updateBreadcrumbs();
  };

  updateCategoryIcon(cat?: CategoryCard) {
    if (!cat) {
      const cat = _.find(
        this.cards,
        (c: CategoryCard) =>
          c.RouseCategory === this.selectedCategory.RouseCategory
      );
    }
    this.selectedCategory.CategoryIcon = cat ? cat.CategoryIcon : null;
  }

  updateBreadcrumbs = () => {
    const links = [
      {
        title: this.selectedCategory.RouseCategory || 'main.tabs.equipment.all',
        class: 'active',
      },
    ];
    this.breadcrumbService.update(links);
  };

  selectCategory = (category: CategoryCard) => {
    this.selectedCategory = category;
    if (category.RouseCategory) {
      this.router.navigate(['equipment', { category: category.RouseCategory }]);
    } else {
      this.router.navigate(['equipment']);
    }
  };

  loadGridData = (resetPaginator: boolean = false) => {
    this.loading = true;
    if (resetPaginator) {
      this.paging.page = 1;
      this.paging.first = 1;
    }
    const sorting = this.gridSortingService.getSortOptions();

    this.equipmentService
      .getProductTypes(
        this.selectedCategory.RouseCategory,
        this.paging,
        sorting
      )
      .subscribe((x) => {
        if (x.Items && x.Items.length > 0) {
          this.productTypes = x.Items.splice(1);
          // x.Items[0].Description = 'main.tabs.equipment.product_types.total';
          this.frozenRows = [x.Items[0]];
        } else {
          this.productTypes = x.Items;
          this.frozenRows = [];
        }
        this.totalCount = x.TotalCount;
        this.loading = false;
        this.cardChanging = false;
        if (resetPaginator && this.productTypeGrid) {
          this.productTypeGrid.resetPaginator();
        }
      });
  };

  exportExcel = (args: any) => {
    this.loading = true;
    if (!this.sorting) {
      this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
      this.sorting.sortOrder =
        this.gridSortingService.getSortDirectionOrDefault();
    }
    const sortOptions = new SortOptions(
      this.sorting.sortField,
      this.sorting.sortOrder === -1
    );
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.equipmentService
      .getProductTypesDownload(
        this.selectedCategory.RouseCategory,
        translatedConfig,
        sortOptions
      )
      .subscribe((blob) => {
        this.loading = false;
        this.cardChanging = false;
        this.downloadsService.saveExcelBlob(blob);
      });
  };

  isSelected = (category: CategoryCard): boolean => {
    return category.RouseCategory === this.selectedCategory.RouseCategory;
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }
    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.loadGridData();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }
    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();
    this.loadGridData();
  };

  private createProductTypeRoute = (productType: string): UrlTree => {
    const params = {
      category: decodeURIComponent(this.selectedCategory.RouseCategory || ''),
      sortOn: this.sorting.sortField,
      desc: !(this.sorting.sortOrder === 1),
    };
    return this.router.createUrlTree(['product-types', productType, params], {
      relativeTo: this.route,
    });
  };

  private resolveProductTypeUrl = (productType: string): string => {
    if (productType === 'TOTAL') {
      return null;
    }
    return this.createProductTypeRoute(productType).toString();
  };

  private navigateToProductType = (productType: string): boolean => {
    const urlTree = this.createProductTypeRoute(productType);
    this.router.navigateByUrl(urlTree);
    return false;
  };

  private configureGrid = (): Observable<boolean> => {
    const result = new BehaviorSubject<boolean>(false);
    this.clientProfileServiceSubscription = this.clientProfileService
      .getClientAttributes()
      .subscribe((attributes) => {
        if (this.clientProfileServiceSubscription) {
          this.clientProfileServiceSubscription.unsubscribe();
        }
        let calculatedProductTypeLength = attributes.MaxProdTypeLen * 7.25;
        calculatedProductTypeLength =
          calculatedProductTypeLength < 175 ? 175 : calculatedProductTypeLength;
        //this.frozenWidth = `${calculatedProductTypeLength}px`;
        const currfilter = this.filterService.getCurrentFilter();
        this.gridConfig = new MetricsGridConfig(
          [
            {
              title: '',
              visible: false,
              locked: true,
              forceCanClose: true,
              keyColumn: true,
              columnSelectorTitle:
                'main.core.tables.titles.dimensions.cat_class',
              columns: [
                {
                  title: 'main.core.tables.titles.dimensions.cat_class',
                  field: 'ProductType',
                  sortColumn: 'ProductType',
                  headerStyle: {
                    'text-align': 'left',
                  },
                  cellStyle: {
                    'text-align': 'left',
                  },
                  width: 90,
                },
              ],
            },
            {
              title: '',
              visible: true,
              locked: true,
              columnSelectorTitle:
                'main.core.common.counts.product_types.singular',
              expandable: false,
              keyColumn: true,
              columns: [
                {
                  title: 'main.core.common.counts.product_types.singular',
                  field: 'Description',
                  sortColumn: 'Description',
                  headerStyle: {
                    'text-align': 'left',
                  },
                  cellStyle: {
                    'text-align': 'left',
                    'text-transform': 'uppercase',
                  },
                  width: 100,
                  maxWidth: 500,
                  minWidth: 115,
                  autoWidth: true,
                  urlFactory: (v, r) =>
                    this.resolveProductTypeUrl(r.ProductType),
                  linkClick: (v, r) =>
                    this.navigateToProductType(r.ProductType),
                },
              ],
            },
            ...this.columnService.ExtendedMetricSetColumnGroups(),
          ],
          null,
          this.enableExport
        );
        result.next(true);
      });
    return result.asObservable();
  };

  /**
   * Adds category cards to the categories list as the user scrolls down through it.
   */
  scrollHandler() {
    const scrollElement: any = document.querySelectorAll(
      '.categories-scroll-list'
    )[0];
    if (scrollElement) {
      const scrollBottom =
        scrollElement.scrollHeight - scrollElement.clientHeight;
      if (Math.floor(scrollBottom - scrollElement.scrollTop) < 2) {
        this.categoriesPage++;
      }
    }
  }

  /**
   * Sets the initial category page size based on the screen height and the category card height.
   */
  setCategoryPageSize() {
    const cardHeightPx = 97;
    const menuBarsHeightPx = 195;
    this.categoriesPageSize = Math.floor(
      (window.innerHeight - menuBarsHeightPx) / cardHeightPx
    );
  }

  /**
   * Changes the default grid page size from 20 to 10 only when a guide is currently running.
   * This is done to improve performance in old computers.
   */
  setGridPageSize() {
    if (this.introJsService.aGuideIsRunning) {
      this.paging.pageSize = 10;
    }
  }

  /**
   * Shows all available categories.
   */
  renderAllCategories() {
    this.categoriesPage =
      this.cards && this.cards.length
        ? Math.ceil(this.cards.length / this.categoriesPageSize) + 1
        : 1000;
  }
}
