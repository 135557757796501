import { Component, OnInit, Output, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { map, filter } from 'rxjs/operators';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import {
  ActiveFilterService,
  FormatService,
  ClientProfileService,
} from '../core';
import { ChangeLog, ClientMonth, CurrentRate, FilterValues } from '../models';
import { ChangesLogService } from './changes-log.service';
import { AuthenticationService } from '../core/authentication/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY } from 'rxjs';
import * as Sentry from '@sentry/angular';
import { FilterProfileService } from '../filter/profiles/filter-profile.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'changes-log-entry',
  templateUrl: 'changes-log-entry.component.html',
  styleUrls: ['changes-log-entry.component.scss'],
})
export class ChangesLogEntryComponent implements OnInit, OnDestroy {
  public changeLog = new ChangeLog();
  public productTypeVisible = false;
  private productType: string;
  private category: string;
  private currentFilter: FilterValues;
  //public clientMonth = new ClientMonth(-1, new Date());
  public currentRate = new CurrentRate();
  public currencyMaskOptions = {
    allowNegative: false,
    precision: 0,
    prefix: '',
  };
  private subscriptions = [];
  @Output() closeClicked;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    protected filterService: ActiveFilterService,
    private formatService: FormatService,
    private clientProfileService: ClientProfileService,
    private changeLogService: ChangesLogService,
    private toasterService: ToastrService,
    private translateService: TranslateService,
    private filterProfileService: FilterProfileService
  ) {
    this.closeClicked = this.changeLogService.changesLogCreated;
  }

  ngOnInit() {
    this.subscriptions.push(
      this.authenticationService.selectedClientId.subscribe((clientid) => {
        if (clientid) {
          this.filterProfileService.wait4CurrentProfile().subscribe(() => {
            this.currencyMaskOptions.prefix =
              this.formatService.selectedCurrencySymbol;
          });
          this.currentFilter = this.filterService.getCurrentFilter();

          const dateFromFilterExists =
            this.currentFilter && this.currentFilter.hasOwnProperty('dateFrom'); // eslint-disable-line  no-prototype-builtins
          if (!dateFromFilterExists) {
            const filterSubscription =
              this.filterService.filterParams.subscribe((searchParams) => {
                filterSubscription.unsubscribe();
                this.filterChanged(this.filterService.getCurrentFilter());
              });
          }
          this.filterService.filterChange.subscribe(this.filterChanged);
          this.filterService.alertFilterChange$.subscribe(this.filterChanged);
          this.router.events
            .pipe(
              filter((event) => event instanceof NavigationEnd),
              map(() => this.route),
              map((route) => {
                while (route.firstChild) {
                  route = route.firstChild;
                }
                return route;
              }),
              filter((route) => route.outlet === 'primary')
            )
            .subscribe((route) => {
              let routeparent = null; // trying to circumvent null / undefined errors
              try {
                routeparent = route.parent;
              } catch (ex) {
                const s = ex;
              } finally {
              }
              if (routeparent != null) {
                this.productType = route.parent.snapshot.params.productType;
                this.category = route.parent.snapshot.params.category;
                this.load();
              }
            });
        }
      })
    );
  }

  ngOnDestroy() {
    while (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.pop().unsubscribe();
    }
  }

  closeClick = () => {
    this.changeLogService.changesLogCreated.next();
  };

  isSaveDisabled = () => {
    if (this.productTypeVisible) {
      return false;
    }
    if (this.changeLog.Comment) {
      return false;
    }
    return true;
  };

  save = () => {
    this.changeLog.MonthId = this.filterService.clientMonth.MonthID; // clientMonth.MonthID;
    this.changeLog.ClientProductType = this.productType;
    /*if (this.productType && !this.currentFilter.useRouseSchema) {
      this.changeLog.ClientProductType = this.productType;
    } else if (this.productType && this.currentFilter.useRouseSchema) {
      (this.changeLog as any).RouseProductTypeList = [this.productType];
      (this.changeLog as any).RouseClientProductType = this.productType;
    }*/

    this.changeLog.MonthlyBookRevenueRate =
      this.currentRate.MonthlyBookRevenueRate;
    this.changeLog.NewMonthlyBookRevenueRate =
      this.changeLog.NewMonthlyBookRevenueRate ||
      this.currentRate.MonthlyBookRevenueRate;
    this.changeLog.MonthlyFloorRevenueRate =
      this.currentRate.MonthlyFloorRevenueRate;
    this.changeLog.NewMonthlyFloorRevenueRate =
      this.changeLog.NewMonthlyFloorRevenueRate ||
      this.currentRate.MonthlyFloorRevenueRate;
    this.changeLog.WeeklyBookRevenueRate =
      this.currentRate.WeeklyBookRevenueRate;
    this.changeLog.NewWeeklyBookRevenueRate =
      this.changeLog.NewWeeklyBookRevenueRate ||
      this.currentRate.WeeklyBookRevenueRate;
    this.changeLog.WeeklyFloorRevenueRate =
      this.currentRate.WeeklyFloorRevenueRate;
    this.changeLog.NewWeeklyFloorRevenueRate =
      this.changeLog.NewWeeklyFloorRevenueRate ||
      this.currentRate.WeeklyFloorRevenueRate;
    this.changeLog.DailyBookRevenueRate = this.currentRate.DailyBookRevenueRate;
    this.changeLog.NewDailyBookRevenueRate =
      this.changeLog.NewDailyBookRevenueRate ||
      this.currentRate.DailyBookRevenueRate;
    this.changeLog.DailyFloorRevenueRate =
      this.currentRate.DailyFloorRevenueRate;
    this.changeLog.NewDailyFloorRevenueRate =
      this.changeLog.NewDailyFloorRevenueRate ||
      this.currentRate.DailyFloorRevenueRate;

    this.changeLogService.createChangeLog(this.changeLog).subscribe(
      (resp) => {
        const message = this.productTypeVisible
          ? this.translateService.instant(
              'main.tabs.changes_log.entry.changes_saved_successfully'
            )
          : this.translateService.instant(
              'main.tabs.changes_log.entry.comments_saved_successfully'
            );
        this.toasterService.success(
          this.translateService.instant('main.tabs.changes_log.entry.success'),
          message
        );
        this.changeLog = new ChangeLog();
        this.closeClick();
      },
      (err: Error) => {
        console.error(err);
        this.toasterService.error(
          this.translateService.instant('main.tabs.changes_log.entry.error'),
          this.translateService.instant(
            'main.tabs.changes_log.entry.an_error_occurred_while_saving'
          )
        );
      }
    );
  };

  private load = () => {
    this.changeLog = new ChangeLog();
    this.productTypeVisible = false;

    if (!this.currentFilter) {
      return;
    }
    if (this.productType && !this.currentFilter.useRouseSchema) {
      this.productTypeVisible = true;
      this.getCurrentRates();
    }
  };

  private filterChanged = (filterValues: FilterValues) => {
    this.currentFilter = filterValues;
    this.load();
  };

  private getCurrentRates = () => {
    //if (!this.productType || this.clientMonth.MonthID === -1) {
    if (!this.productType || this.filterService.clientMonth.MonthID === -1) {
      return;
    }
    //this.changeLogService.getCurrentRates(this.productType, this.clientMonth.MonthID, this.category).subscribe(resp => {
    this.changeLogService
      .getCurrentRates(
        this.productType,
        this.filterService.clientMonth.MonthID,
        this.category
      )
      .subscribe(
        (resp) => {
          if (resp) {
            this.currentRate = resp[0];
          }
        },
        (err: Error) => {
          Sentry.captureException(err);
          return EMPTY;
        }
      );
  };
}
