import { Injectable } from '@angular/core';
import introJs from 'intro.js/intro.js';
import { IntroJsApiService } from './intro-js-api.service';
import { StepActionType, SystemTriggers, TriggerType, UserGuide, UserGuideStep } from '../models';
import { GuidedToursService } from './guided-tours.service';
import { zip } from 'rxjs';
import { FilterProfileService } from '../../../filter/profiles/filter-profile.service';
import { environment } from '../../../../environments/environment';
import { CookieConsentService } from '../../services';

@Injectable({providedIn: 'root'})
export class IntroJsService {
  private introJS = introJs();
  private guide2restart: any;
  private userOptedOut = false;
  public userGuides: UserGuide[];
  public allUserGuides: UserGuide[];
  public aGuideIsRunning: boolean = false;
  private readonly DELAYED_FIRST_LOGIN_GUIDE = 'DELAYED_FIRST_LOGIN_GUIDE';

  constructor(
    private introJsApiService: IntroJsApiService,
    private guidedToursService: GuidedToursService,
    private filterProfileService: FilterProfileService,
    private cookieConsentService: CookieConsentService
  ) {
    // Load guides from the API once when starting the application
    zip(
      this.guidedToursService.wait4ServiceToBeReady(),
      this.introJsApiService.loadUserGuides()
    ).subscribe((results: [boolean, any]) => {
      this.allUserGuides = results[1];
      this.userGuides = this.guidedToursService.filterStepsByPermissions(this.allUserGuides);
    });
    this.cookieConsentService.cookiesAccepted.subscribe(value => {
      if(value) {
        this.onCookiesAccepted();
      }
    });
  }

  /**
   * Function used to start the service from somewhere else.
   */
  public initService() {
    this.guidedToursService.getLoadedProfile().subscribe(profile => {
      this.userOptedOut = profile ? profile.is_rdo_app_guidance_optout : this.userOptedOut;
      this.guidedToursService.$serviceIsReady.subscribe(isReady => {
        if(isReady) {
          this.onLogin();
        }
      });
    });
  }

  /**
   * Executed a System Login guide if it exists.
   */
  private onLogin() {
    this.userGuides = this.guidedToursService.filterStepsByPermissions(this.allUserGuides);
    if(!this.userOptedOut && environment.enableUnpromptedGuidance) {
      if(this.guidedToursService.isFirstLogin()) {
        if(!this.cookieConsentService.blackBarIsActuallyVisible.value) {
          this.runFirstLoginGuide();
          this.createDefaultProfile();
        } else {
          this.waitForCookiesInteraction();
        }
      } else if(this.cookieConsentService.cookiesAccepted.value){
        this.createDefaultProfile();
      }
      // Add here execution of guides that should be checked on every login
    }
  }

  /**
   * Creates a default profile that doesn't opt out from guides.
   * This is used to identify the current user as a user who has
   * logged in before.
   */
  private createDefaultProfile() {
    if(!this.guidedToursService.profileExists()) {
      this.guidedToursService.createOptOutOption(false).subscribe();
    }
  }

  /**
   * Adds a record in the local storage that tells wether this user
   * should have seen the welcome guide or not right after accepting
   * cookies. This record will be checked on after cookies are
   * accepted or rejected and based on its existance the first login
   * guide will be triggered.
   */
  private waitForCookiesInteraction() {
    if (localStorage) {
      localStorage.setItem(this.DELAYED_FIRST_LOGIN_GUIDE, 'true');
    } else {
      console.warn('no localStorage available to set "DELAYED_FIRST_LOGIN_GUIDE"');
    }
  }

  /**
   * Returns true if the fist login guide is delayed.
   */
  public firstLoginGuideIsDelayed(): boolean {
    return localStorage && (localStorage.getItem(this.DELAYED_FIRST_LOGIN_GUIDE) === 'true');
  }

  /**
   * Called after cookies are accepted to trigger any delayed first
   * login guides.
   */
  public onCookiesAccepted() {
    const isDelayed = this.firstLoginGuideIsDelayed();
    if(isDelayed) {
      localStorage.removeItem(this.DELAYED_FIRST_LOGIN_GUIDE);
      this.runFirstLoginGuide();
      this.createDefaultProfile();
    }
  }

  /**
   * Runs a guide associated with the first login.
   */
  private runFirstLoginGuide() {
    const loginGuide = this.userGuides.find(x =>
      x.trigger_type === TriggerType.SYSTEM &&
      x.trigger_value === SystemTriggers.FIRST_LOGIN);
    this.executeGuide(loginGuide);
  }

  /**
   * Searches for guides that should be triggered by user input and runs them if needed.
   */
  private onUserInput(elemId: string, cssSelector: string) {
    const userInputGuide = this.userGuides.find(x => x.trigger_type === TriggerType.USER &&
      (x.trigger_value && (x.trigger_value === elemId || x.trigger_value === cssSelector))
      );
    this.executeGuide(userInputGuide);
  }

  /**
   * Executed a guide with the given name, if it exists.
   * This function is used to call a second guide after an initial one was called.
   */
  private executeGuideByName(guideName: string, delay?: number) {
    delay = delay ? delay : 1;
    setTimeout(() => {
      const namedGuide = this.userGuides.find(x => x.name === guideName);
      this.executeGuide(namedGuide);
    }, delay);
  }

  /**
   * Executes a given User Guide.
   */
  public executeGuide(userGuide: UserGuide, stepIndex: number = 0): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;
    this.aGuideIsRunning = true;
    if (userGuide) {
      // Set options to execute introJs
      const options = userGuide.options.asIntroJsOptions();
      this.introJS.setOptions(options);

      // Handle Actions if they exist
      if(userGuide.options.hasActionStep()) {
        this.introJS.onafterchange(function() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias
          const introJsScope = this;
          setTimeout(() => {
            vm.handleOnAfterChange(introJsScope, userGuide);
          }, 0);
        });
        this.introJS.onbeforechange(()=>{
        });
      }
      this.introJS.oncomplete(() => {this.handleFinishedGuide(userGuide);});
      this.introJS.onexit(() => {this.handleFinishedGuide(userGuide);});
      this.wait4Dependencies(userGuide, () => {
        this.handleGuideStart(userGuide, stepIndex);
      });
    }
  }

  /**
   * Starts the given user guide after having configured it within the introJs variable.
   * Handles starting from a given step index as well if needed.
   */
  private handleGuideStart(userGuide: UserGuide, stepIndex: number): void {
    if(stepIndex > 0) {
      this.introJS.start();
      this.introJS.goToStep(stepIndex + 1);
    } else {
      // Run Setup before running the guide
      // eslint-disable-next-line no-eval, no-unused-expressions
      userGuide.options.setupActionCode && window.eval(userGuide.options.setupActionCode); // TODO:  What is this eval for???
      setTimeout(() => {
        this.introJS.start();
      }, 0);
    }
  }

  /**
   * Waits until the list of elements, from which the given guide depends,
   * are rendered before executing the guide.
   */
  private wait4Dependencies(userGuide: UserGuide, callback: () => unknown): void {
    const requirements = userGuide.options.requiredElements;
    let allElementsHaveBeenRendered = true;
    if(requirements && requirements.length) {
      requirements.forEach(selector => {
        const elem = document.querySelector(selector);
        allElementsHaveBeenRendered = allElementsHaveBeenRendered && !!elem;
      });
    }
    if(allElementsHaveBeenRendered) {
      callback();
    } else {
      setTimeout(() => {
        this.wait4Dependencies(userGuide, callback);
      }, 250);
    }
  }

  private handleOnAfterChange(introJsScope: any, userGuide: any) {
    const currentStep = userGuide.options.steps[introJsScope._currentStep];
    this.runServiceFunction(currentStep.serviceFunction, currentStep.serviceFunctionParameters);
    switch(currentStep.type) {
      case StepActionType.HIGHLIGHT:
        this.handleHighlightStep(introJsScope, userGuide, currentStep);
        break;
      case StepActionType.MESSAGE:
        this.handleHighlightStep(introJsScope, userGuide, currentStep);
        break;
      case StepActionType.ACTION:
        this.handleActionStep(introJsScope, userGuide, currentStep);
        break;
      case StepActionType.REFRESH_FORWARD:
        this.handleRefreshStep(introJsScope, userGuide, currentStep);
        break;
      case StepActionType.REFRESH_BACKWARDS:
        this.handleRefreshStep(introJsScope, userGuide, currentStep);
        break;
      case StepActionType.RESTARTING_ACTION:
        this.handleRestartingActionStep(introJsScope, userGuide, currentStep);
        break;
    }
  }

  /**
   * Code that gets executed every time a guide is completed.
   */
  private handleFinishedGuide(userGuide: any): void {
    if(userGuide.options.finishingActionCode && userGuide.options.finishingActionCode.length) {
      // eslint-disable-next-line no-eval
      window.eval(userGuide.options.finishingActionCode); // TODO: What is this eval doing????
    }
    this.aGuideIsRunning = false;
    this.showGlobalSpinner(false);
  }

  /**
   * Available funcitons are the ones within this service.
   * This is intended to use: showGlobalSpinner
   */
  private runServiceFunction(functionName: string, args: Array<any>): void {
    if(functionName && this[functionName]) {
      try {
        this[functionName](...args);
      }
      catch(err) {}
    }
  }

  showGlobalSpinner(value: boolean): void {
    this.filterProfileService.toggleSpinner(value);
  }

  /**
   * Fetches the list of steps before or after the current one and feeds them into
   * introJs to refresh them, so that the dom elements that they reference become
   * visible to introJs.
   */
  private handleRefreshStep(introJsScope: any, userGuide: any, currentStep: UserGuideStep) {
    this.showGlobalSpinner(true);
    const refreshSteps = this.getRefreshableSteps(introJsScope, userGuide, currentStep);
    const waitForElement: string = currentStep.waitForElement;
    if(waitForElement && waitForElement.length) {
      this.handleWait4Element(waitForElement, introJsScope, refreshSteps);
    } else {
      this.finishRefresh(introJsScope, refreshSteps);
    }
  }

  /**
   * Filter the current guide's steps returning only steps that can be refreshed
   * (meaning that contain en "element" field) and that have not yet been executed.
   */
  private getRefreshableSteps(introJsScope: any, userGuide: any, currentStep: UserGuideStep) {
    let countedSteps = 0;
    const actionType: StepActionType = currentStep.type;
    const stepsCount: number = currentStep.stepsCount;
    const currentIndex = introJsScope._currentStep;
    // Filter steps that can't be refreshed and that don't make sense to refresh
    let refreshSteps = userGuide.options.steps.filter((x: any, index: number) => {
      return x.element &&
      (
        (actionType === StepActionType.REFRESH_FORWARD && introJsScope._direction !== 'backward' && index >= currentIndex) ||
        (actionType === StepActionType.REFRESH_BACKWARDS && introJsScope._direction === 'backward' && index <= currentIndex)
      );
    });
    // Filter steps based on stepsCount parameter
    refreshSteps = refreshSteps.filter(() => {
      countedSteps++;
      return !stepsCount || (stepsCount && countedSteps <= stepsCount);
    });
    return refreshSteps;
  }

  /**
   * Triggers a timeout to wait for an element to be rendered up to
   * a maximum of "waitAttempts" times.
   */
  private handleWait4Element(waitForElement: string, introJsScope: any, refreshSteps: any[], waitAttempts: number = 20) {
    const element = document.querySelector(waitForElement);
    if(element) {
      this.finishRefresh(introJsScope, refreshSteps);
    } else {
      setTimeout(() => {
        this.handleWait4Element(waitForElement, introJsScope, refreshSteps, waitAttempts--);
      }, 200);
    }
  }

  /**
   * Executed the refresh of the given list of steps if there are any.
   * Then it auto steps backwards or forward depending on the current
   * direction.
   */
  private finishRefresh(introJsScope: any, refreshSteps: any[]) {
    if(refreshSteps && refreshSteps.length) {
      introJsScope.refresh(refreshSteps);
    }
    this.autoStep(introJsScope);
  }

  private handleRestartingActionStep(introJsScope: any, userGuide: any, currentStep: any) {
    this.runActionCode(introJsScope, currentStep);
    if(introJsScope._direction !== 'backward') {
      const stepIndex = introJsScope._currentStep + 1;
      this.introJS.exit();
      setTimeout(() => {
        this.executeGuide(userGuide, stepIndex);
      }, 100);
    } else {
      this.handleRefreshStep(introJsScope, userGuide, currentStep);
    }
  }

  /**
   * Runs the logic that needs to take place when running an action type step.
   * @param introJsScope the scope of the current guide being executed
   * @param userGuide the current guide being executed
   * @param currentStep the current step object
   */
  private handleActionStep(introJsScope: any, userGuide: any, currentStep: any) {
    this.runActionCode(introJsScope, currentStep);
    this.autoStep(introJsScope);
    // this.action2NewGuide(userGuide, introJsScope, currentStep);
  }

  /**
   * Same as an action step, but with no auto step.
   */
  private handleHighlightStep(introJsScope: any, userGuide: any, currentStep: any) {
    this.runActionCode(introJsScope, currentStep);
  }

  /**
   * Runs the given string as a piece of javascript code.
   * @param actionCode 
   */
  private runActionCode(introJsScope: any, currentStep: any) {
    const code2Use = introJsScope._direction !== 'backward' ? currentStep.actionCode : currentStep.undoActionCode;
    if(code2Use && code2Use.length) {
      this.showGlobalSpinner(true);
      const wrappedCode = `
      let vm = this;
      setTimeout(function() {
        ${code2Use}
      }, 1);`;
      // eslint-disable-next-line no-eval
      window.eval(wrappedCode); // TODO: What is this eval doing???
    }
  }

  /**
   * Automatically moves into the next or previous step of the current guide
   * being executed.
   */
  private autoStep(introJsScope: any) {
    setTimeout(() => {
      if (introJsScope._direction !== 'backward') {
        introJsScope.nextStep();
      } else {
        introJsScope.previousStep();
      }
      this.showGlobalSpinner(false);
    }, 250);
  }

  /**
   * Runs a new guide that exists as a parameter within the current guide.
   */
  private action2NewGuide(userGuide: any, introJsScope: any, currentStep: any) {
    const isFinalStep = userGuide.options.steps.length -1 === introJsScope._currentStep;
    if(currentStep.nextguide && isFinalStep) {
      this.executeGuideByName(currentStep.nextguide, 5000);
    }
  }

  handleClickOut(event: PointerEvent, classNames: Array<string>, callback: () => unknown): void {
    if(!this.aGuideIsRunning) {
        const strClassList = JSON.stringify((<any>event.target).classList);
        this.handleClassNames(strClassList, classNames, callback);
    }
  }

  private handleClassNames(strClassList: string, classNames: Array<string>, callback: () => unknown): void {
      let elemIncluded = false;
      classNames.forEach(name => {
          elemIncluded = elemIncluded || strClassList.includes(name);
      });
      if(!elemIncluded) {
          callback();
      }
  }
}
