import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Translation Service
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HotkeyModule } from 'angular2-hotkeys';
import { ToastrModule } from 'ngx-toastr';
import { Angulartics2Module } from 'angulartics2';
import 'hammerjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CallbackComponent } from './callback/callback.component';
import { ChangesLogModule } from './changes-log/changes-log.module';
import { ClientSelectorComponent } from './client-selector/client-selector.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { HttpLanguageLoader } from './core';
import { AuthenticationService } from './core/authentication/authentication.service';
import { AuthGuard } from './core/guards/auth.guard';
import { ConfigService } from './core/http/config.service';
import { RdoHttpService } from './core/http/rdo-http.service';
import { ActiveFilterService } from './core/query/active-filter.service';
import { ProfileService } from './core/user/profile.service';
import { CustomGridsModule } from './custom-grids/custom-grids.module';
import { CustomerModule } from './customer/customer.module';
import { DownloadModule } from './download/download.module';
import { DownloadsModule } from './downloads/downloads.module';
import { EquipmentModule } from './equipment/equipment.module';
import { FilterModule } from './filter/filter.module';
import { GeographyModule } from './geography/geography.module';
import { HeaderModule } from './header/header.module';
import { NavigationModule } from './navigation/navigation.module';
import { RentalAssetsModule } from './rental-assets/rental-assets.module';
import { AppRouterModule } from './router/app-router.module';
import { SalesRepModule } from './sales-rep/sales-rep.module';
import { SignoutComponent } from './signout/signout.component';
import { SummaryModule } from './summary/summary.module';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { UnsubscribeModule } from './unsubscribe/unsubscribe.module';
import { CoreModule } from './core/core.module';
import { MaintenanceModeModule } from "./maintenance-mode/maintenance-mode.module";
import {MaintenanceModeService} from './maintenance-mode/maintenance-mode.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({ declarations: [
        AppComponent,
        // SummaryComponent,
        CallbackComponent,
        SignoutComponent,
        ClientSelectorComponent,
        CookieConsentComponent,
        // HeaderComponent
        UnauthorizedComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        Angulartics2Module.forRoot(),
        HotkeyModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right'
        }),
        SummaryModule,
        FilterModule,
        HeaderModule,
        NavigationModule,
        CustomerModule,
        EquipmentModule,
        SalesRepModule,
        GeographyModule,
        AppRouterModule,
        DownloadsModule,
        DownloadModule,
        CustomGridsModule,
        ChangesLogModule,
        RentalAssetsModule /*,
        ClientSwitcherModule*/,
        AppRoutingModule,
        MatDialogModule,
        BrowserAnimationsModule,
        CommonModule,
        MatIconModule,
        CoreModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: HttpLanguageLoader,
                deps: [HttpClient, AuthenticationService]
            }
        }),
        UnsubscribeModule,
        MaintenanceModeModule], providers: [AuthGuard, AuthenticationService, RdoHttpService, ConfigService, ActiveFilterService, ProfileService, MaintenanceModeService
        // {
        //     // processes all errors
        //     provide: ErrorHandler,
        //     useClass: GlobalErrorHandler,
        //   },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: HttpErrorInterceptor,
        //     multi: true
        //   }
        , provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
