import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';
import { QueryParams } from '../core/http/query-params';
import * as _ from 'lodash';

import { ProductQuery, PagedQuery, CustomerQuery, CustomerExportQuery, StandardGridsGroupedQuery } from '../models';

import {
	PageOptions,
	SortOptions,
	RdoHttpService,
	ExportSpreadsheetBody,
	ViewService,
	ComparisonModeSortOptions,
	QueryService,
	MetricsGridConfig,
	RevenueMappingService,
	ActiveFilterService,
	ExcelExportService
} from '../core';

import { FilterInfoService } from '../filter/services/filter-info.service';
import * as filterFunctions from './../filter/functions/filter.functions';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
	providedIn: 'root'
})
export class CustomerService {
	private isGetRequest = false;

	constructor(
		private rdoHttp: RdoHttpService,
		private viewService: ViewService,
		private filterInfoService: FilterInfoService,
		private filterService: ActiveFilterService,
		private revenueMappingService: RevenueMappingService,
		private queryService: QueryService,
		private excelExportService: ExcelExportService,
		private translateService: TranslateService
	) {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
	}

	public getProductTypeCustomers(productType: string, category?: string, paging: PageOptions = new PageOptions(), sorting: SortOptions = new SortOptions()): Observable<any> {
		const pagedSorted = new ProductQuery();
		pagedSorted.ClientProductType = productType;
		if (category) {
			pagedSorted.RouseCategoryList = [this.filterInfoService.getRouseCategoryId(category)];
		}
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
		return this.queryService
			.getPagedSorted('metrics/product-types/customers', paging, sorting, false, null, pagedSorted);
	}

	public getCustomers = (paging: PageOptions = new PageOptions(), sorting: SortOptions = new SortOptions(),
		params: QueryParams = new QueryParams()): Observable<any> => {
		this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
		const groupedQuery = new StandardGridsGroupedQuery();

        groupedQuery.groupBySalesRep = params?.get('groupBySalesRep')?.toString() === 'true';
        groupedQuery.groupByProductType = params?.get('groupByProductType')?.toString() === 'true';
        
		return this.queryService.getPagedSorted('metrics/customers', paging, sorting, this.isGetRequest, params, groupedQuery)
			.pipe(map(r => {
				r.Items = _.map(r.Items, (i: any) => {
					i.MonthlyRevenueRate = i.MonthlyRevenue;
					i.MonthlyBenchmarkedRevenueRate = i.MonthlyBenchmarkedRevenue;

					i.WeeklyRevenueRate = i.WeeklyRevenue;
					i.WeeklyBenchmarkedRevenueRate = i.WeeklyBenchmarkedRevenue;

					i.DailyRevenueRate = i.DailyRevenue;
					i.DailyBenchmarkedRevenueRate = i.DailyBenchmarkedRevenue;

					return i;
				});
				return r;
			}));
	}

	public getCustomersDownload = (sorting: SortOptions = new SortOptions(), gridConfig: MetricsGridConfig): Observable<Record<any, any>[]> => {
		const pagedSorted = new PagedQuery();
		pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), this.filterInfoService.getFilterExport());
		const salesRepsGroup = _.find(gridConfig.groups, (group) => group.columnSelectorTitle ===
			this.translateService.instant('main.core.common.counts.sales_reps.count'));
		if (salesRepsGroup && salesRepsGroup.visible) {
			body.groupBySalesRep = 'true';
		}
		const productTypesGroup = _.find(gridConfig.groups, (group) => group.columnSelectorTitle ===
			this.translateService.instant('main.core.common.counts.product_types.count'));
		if (productTypesGroup && productTypesGroup.visible) {
			body.groupByProductType = 'true';
		}
		if (this.filterService.getCurrentFilter().useRouseSchema) {
			body.useRouseSchema = 'true';
		}
		return this.excelExportService.generateGridSpreadsheet('metrics/customers-download', body, pagedSorted);
	}

	public getCustomerCards = (customerId: number, sort: SortOptions): Observable<any> => {

		const pagedSorted = new CustomerQuery();
		pagedSorted.SetSorting(sort);
		pagedSorted.CustomerId = customerId;

		return this.rdoHttp
			.post('metrics/customers/cards', pagedSorted)
			.pipe(map(res => res));
	}

	public getDashboardCardData = (customer: number): Observable<any> => {
		const pagedSorted = new CustomerQuery();
		pagedSorted.CustomerId = customer;
		pagedSorted.Page = 1;
		pagedSorted.PageSize = 1;

		return this
			.rdoHttp
			.post('metrics/customers', pagedSorted)
			.pipe(map(res => res));
	}

	public getRevenueByMonthData = (customer: number, monthsToReturn: number): Observable<any> => {
		const pagedSorted = new CustomerQuery();
		pagedSorted.CustomerId = customer;
		pagedSorted.MonthsToReturn = monthsToReturn;

		return this.filterService.filterParams.pipe(switchMap(f => {
			const override = (<any>Object).assign({}, f);
			override.MonthIDStart = null;
			override.MonthIDEnd = null;

			return this.rdoHttp
				.post('metrics/product-types/dashboard', pagedSorted, override)
				.pipe(map(res => {
					return (res as any).Items;
				}));
		}));
	}

	public getTopSalesReps = (customer: number): Observable<any> => {
		return this.viewService.getComparisonModeSortOptions(ComparisonModeSortOptions.Difference).pipe(switchMap(sorting => {
			const pagedSorted = new CustomerQuery();
			pagedSorted.CustomerId = customer;

			pagedSorted.SetSorting(sorting);

			return this
				.rdoHttp
				.post('metrics/customers/dashboard/sales-reps', pagedSorted)
				.pipe(map(res => res));
		}));
	};

	public getTopProductTypes = (customer: number): Observable<any> => {
		return this.viewService.getComparisonModeSortOptions(ComparisonModeSortOptions.Difference).pipe(switchMap(sorting => {
			const pagedSorted = new CustomerQuery();
			pagedSorted.CustomerId = customer;

			pagedSorted.SetSorting(sorting);
			return this
				.rdoHttp
				.post('metrics/customers/dashboard/product-types', pagedSorted)
				.pipe(map(res => res));
		}));
	}

	public getRateTypes = (customer: number): Observable<any> => {
		const pagedSorted = new CustomerQuery();
		pagedSorted.CustomerId = customer;

		return this
			.rdoHttp
			.post('metrics/customers/dashboard/rate-types', pagedSorted)
			.pipe(map(p => this.revenueMappingService.processRateTypesResponse((p as any).Items[0])));
	}

	public getCustomerSalesRepsProductTypes = (customerId: number, salesRepId: string, sorting: SortOptions = new SortOptions()): Observable<any> => {
		const pagedSorted = new CustomerQuery();
		pagedSorted.CustomerId = customerId;
		pagedSorted.SalesRepId = salesRepId;
		pagedSorted.Page = 1;
		pagedSorted.PageSize = 500;

		pagedSorted.SetSorting(sorting);

		return this
			.rdoHttp
			.post('metrics/sales-reps/customers/product-types', pagedSorted)
			.pipe(map((r: any) => {
				return r.Items;
			}));
	}

	public getCustomerSalesRepsProductTypesDownload = (
		customerId: number,
		salesRepId: string,
		paging: PageOptions = new PageOptions(),
		sorting: SortOptions = new SortOptions(),
		gridConfig: MetricsGridConfig
	): Observable<any> => {
		const pagedSorted = new CustomerExportQuery();
		pagedSorted.CustomerId = customerId;
		pagedSorted.SalesRepId = salesRepId;
		pagedSorted.Page = 1;
		pagedSorted.Columns = JSON.stringify(gridConfig.getAllColumns()); //OPTIMIZE?
		pagedSorted.Filters = JSON.stringify(this.filterInfoService.getFilterExport()); //OPTIMIZE?

		return this.queryService.getPagedSorted('metrics/sales-reps/customers/product-types-download', paging, sorting, false, null, pagedSorted);
	}

}
