<rdo-breadcrumb></rdo-breadcrumb>
<router-outlet></router-outlet>
<rdo-overlay [enabled]="loading"></rdo-overlay>
<div class="container-fluid" id="changes-log">
	<div class="row scroll-container">
		<div class="col-xs-12 scroll">
			<div class="checkbox-container">
				<div class="checkbox-outer-div">
					<div class="checkbox-item">
						<input class="cursor-pointer" type="checkbox" 
						title="{{selectAllTitle | translate}}" [(ngModel)]="selectAll"
						(ngModelChange)="toggleSelectAll()">
						<label></label>
					</div>
				</div>

				<div class="checkbox-outer-div" *ngFor="let item of other; let i = index">
					<div class="checkbox-item" *ngIf="i < resultsLength">
						<input type="checkbox" class="cursor-pointer"
						*ngIf="data.Items[i].IsMine" [(ngModel)]="selectAllModel[i]"
						(ngModelChange)="updateDeleteUi(selectAllModel[i])">
						<span *ngIf="!data.Items[i].IsMine">
							<i class="fa fa-square disabled-icon"></i></span>
						<label></label>
					</div>
				</div>
			</div>
		
			<rdo-metrics-grid *ngIf="data"
				[gridConfig]="gridConfig"
				[pagedData]="data"
				[loading]="loading" 
				[sortOn]="sorting.sortOn"
				[desc]="sorting.descending"
				[disablePaging] = "false"
				(descChange)="changeSortDirection()"
				(sortOnChange)="changeSortColumn($event)"
				(pageChange)="changePage($event)"
				(pageSizeChange)="changePageSize($event)"
				[name]="gridName"
				(downloadExcelClick)="exportExcel($event)"
				(deleteClick)="deleteChangeLogEntries($event)">
			</rdo-metrics-grid>
		</div>
  	</div>
</div>




