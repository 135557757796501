import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import * as Sentry from '@sentry/browser';
import { environment } from './environments/environment';

Sentry.addTracingExtensions();
Sentry.init({
  environment: environment.platform,
  dsn: 'https://8827ba54c91f4331baabb37cb3320e81@o940752.ingest.sentry.io/6161354',
  ignoreErrors: [
    'Non-Error exception captured',
    'Uncompressed Asset',
    "TypeError: You provided 'undefined' where a stream was expected",
  ],
  integrations: [
    Sentry.browserTracingIntegration({
      instrumentNavigation: true,
    }),
    // Registers the Replay integration,
    // which automatically captures Session Replays
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring. We recommend adjusting this value in production
  tracesSampleRate: environment.sentryTraceSampleRate, //0.4,
  tracePropagationTargets: ['localhost', /^https:\/\/.*rouseanalytics\.com/],

  // Replay for 100% of sessions with an error in production and stage
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: environment.platform === 'Production' ? 1.0 : 0,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (environment.platform === 'Production' && window) {
  window.console.log = function () {};
  window.console.warn = function () {};
  window.console.error = function () {};
  window.console.time = function () {};
  window.console.timeEnd = function () {};
}
