import { FormatService } from '../query';
import { IEllipseable } from '../pipes/rdo-ellipsis.pipe';
import { IColumnConfig } from './IColumnConfig.interface';
import { AnyKindOfDictionary } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { RdoBooleanPipe } from '../pipes';

export class GridTableParent {
	protected allowDoubleHeader: boolean = false;

	constructor(
		protected formatService: FormatService,
		protected translateService: TranslateService
	) { }

	/**
	 * Manual workaround that solves primeNg p-tables missalignment issue.
	 */
	protected handleScrollMargin(): void {
		setTimeout(() => {
			const nonFrozenBody = document.querySelectorAll('.ui-table-scrollable-body')[1];
			if (nonFrozenBody) {
				const widthWithScrollBar = (<any>nonFrozenBody).offsetWidth;
				const widthWithoutScrollBar = nonFrozenBody.clientWidth;
				const scrollbarWidth = widthWithScrollBar - widthWithoutScrollBar;
				const paddedHeight = document.querySelectorAll('.ui-table-scrollable-header.ui-widget-header')[1].clientHeight + 1;
				//(<any>document.querySelectorAll('.ui-table-scrollable-header.ui-widget-header')[1]).style.marginRight = scrollbarWidth + 'px';
				(<any>document.querySelectorAll('.ui-table-missing-scrollable-header')[0]).style.width = scrollbarWidth + 'px';
				(<any>document.querySelectorAll('.ui-table-missing-scrollable-header')[0]).style.height = paddedHeight + 'px';
			}
		}, 1);
	}

	/**
	 * Sets the frozen width considering the values in the frozen rows and
	 * in the frozen columns.
	 */
	protected updatefrozenWidth(frozenRows: any[], frozenColumns: any[]): string {
		const frozenRowsWidth = this.getFrozenRowsWidth(frozenRows, frozenColumns);
		const frozenColumnsWidth = frozenColumns.reduce((a, b) => a + (b.columns.reduce((c, d) => c + (d.width || 0), 0) || 0), 0);
		const frozenWidth = `${Math.max(frozenColumnsWidth, frozenRowsWidth)}px`;
		return frozenWidth;
	}

	/**
	 * Returns the width assigned to the whole frozen rows group.
	 * 
	 * Details: Frozen rows have fields which names are within the frozen columns.
	 * This method compares the text length of the frozen row fields and
	 * the actual frozen culumn titles and returns the maximum on each case
	 * until adding them all up. This way, every column has enough room for
	 * both the frozenRow value, and the frozen column title.
	 */
	private getFrozenRowsWidth(frozenRows: any[], frozenColumns: any[]) {
		let wholeWidth = 0;
		if (frozenRows && frozenRows[0]) {
			frozenColumns[0].columns.forEach((column: any) => {
				wholeWidth += Math.max(
					this.formatService.getTextWidth(frozenRows[0][column.field]),
					this.formatService.getTextWidth(column.title));
			});
		}
		return wholeWidth;
	}

	protected configureDoubleLinedColumns(columns: Array<IColumnConfig>) {
		let doubleLined = false;
		columns.forEach(column => {
			doubleLined = doubleLined || (<IEllipseable><unknown>column).hasTwoLines;
		});
		this.allowDoubleHeader = doubleLined;
	}

	protected handleValue(value: any) {
		let result = '';
		const type = typeof value;
		switch (type) {
			case 'boolean':
				// eslint-disable-next-line no-case-declarations
				const rbp = new RdoBooleanPipe(this.translateService);
				result = rbp.transform(value);
				break;
			case 'string':
				result = this.formatService.translateAndFormat(value, true);
				break;
			default:
				result = value;
				break;
		}
		return result;
	}
}
