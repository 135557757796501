import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import {SummaryComponent} from './../summary';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {   path: 'summary',
                component: SummaryComponent,
                data: {title:'main.tabs.summary.page_title'}
            }],
            { onSameUrlNavigation: 'reload' }
        )
    ]
})
export class AppRouterModule { }
