import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Router, ActivatedRoute, UrlTree } from "@angular/router";
import { Location } from "@angular/common";
import { Observable } from "rxjs/Observable";
import { Subscription } from "rxjs/Subscription";
import { map } from "rxjs/operators";
import { LazyLoadEvent } from "primeng/api";
import {
  ActiveFilterService,
  BreadcrumbService,
  ColumnDefinitionService,
  MetricsGridConfig,
  ClientProfileService,
  GridTableExpandedNgComponent,
  FormatService,
  GridSortingService,
} from "../core";
import { DownloadsService } from "../downloads";
import { EquipmentService } from "../equipment";
import { HeaderService } from "../header";
import { SalesRepBaseComponent } from "./sales-rep-base.component";
import { SelectedSalesRepService } from "./selected-sales-rep.service";
import { PageOptionsNg, SortOptionsNg, SortOptions } from "../models";
import { AuthenticationService } from "../core/authentication/authentication.service";

@Component({
  selector: "rdo-product-type-customers",
  template: `
    <rdo-overlay [enabled]="loading"></rdo-overlay>
    <rdo-grid-table-expanded-ng
      #productTypesGrid
      *ngIf="data && totalCount > 0"
      [name]="gridName"
      [dataKey]="dataKey"
      [gridConfig]="gridConfig"
      [rowExpansion]="gridConfig.rowExpansion"
      [pagedData]="data"
      [frozenRows]="frozenRows"
      [sorting]="sorting"
      [(paging)]="paging"
      (pageOnChange)="changePage($event)"
      (lazyLoadOnChange)="changeLazyLoad($event)"
      [totalCount]="totalCount"
      [frozenWidth]="frozenWidth"
      [showColumnSelector]="true"
      (downloadExcelClick)="exportExcel($event)"
    >
    </rdo-grid-table-expanded-ng>
  `,
})
export class SalesRepProductTypesComponent extends SalesRepBaseComponent implements OnInit, OnDestroy {

	@ViewChild('productTypesGrid') productTypesGrid: GridTableExpandedNgComponent;
	loading = true;
	gridName = 'SALESREP_PRODUCTTYPES_GRID';
	data: any;
	salesRepId: string;
	gridConfig: MetricsGridConfig;
	frozenRows: any;
	paging = new PageOptionsNg();
	sorting: SortOptionsNg;
	totalCount = 0;
	frozenWidth: string;
	dataKey = 'ProductType';
	private clientProfileServiceSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private columnService: ColumnDefinitionService,
    private filterService: ActiveFilterService,
    private headerService: HeaderService,
    breadcrumbService: BreadcrumbService,
    selectedSalesRepService: SelectedSalesRepService,
    private equipmentService: EquipmentService,
    private downloadsService: DownloadsService,
    private clientProfileService: ClientProfileService,
    private formatService: FormatService,
    private gridSortingService: GridSortingService,
  ) {
    super(selectedSalesRepService, breadcrumbService);
  }

	ngOnInit() {
		this.gridSortingService.setGridName(this.gridName);
		this.sorting = this.gridSortingService.getSortOptionsNg();

		const component = this; // eslint-disable-line @typescript-eslint/no-this-alias
		let salesRepId = component.salesRepId;
		component.subscriptions.push(component.route.parent.params.subscribe(params => {
			salesRepId = params.salesRep;
			if (component.salesRepId !== salesRepId) {
				component.salesRepId = salesRepId;
				component.configureGrid();
				component.load();
			}
		}));
		component.subscriptions.push(
			component.selectedSalesRepService.salesRepChange.subscribe(s => {
				component.salesRepId = s.SalesRepId.toString();
				salesRepId = s.SalesRepId.toString();
				this.paging.page = 1;
				if (component.productTypesGrid) {
					component.productTypesGrid.resetPaginator();
				}
				component.load();
			}));
		component.subscriptions.push(
			component.filterService.filterChange.subscribe(() => {
				component.load();
			})
		);
		component.subscriptions.push(component.authenticationService.userInfoView.subscribe(userInfo => {
			if (component.gridConfig) {
				component.gridConfig.enableExcelExport = userInfo.HasClientAccessToExportData;
			}
		}));
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	load = () => {
		const sorting = this.getSorting();

		this.loading = true;
		this.equipmentService.getSalesRepProductTypes(this.salesRepId, this.paging, sorting).subscribe(result => {
			this.loading = false;
			if (result.Items && result.Items.length > 0) {
				this.data = result.Items;
			} else {
				this.data = [];
			}
			this.totalCount = result.TotalCount;
		});
		this.updateBreadcrumbs([
			{ title: 'main.core.common.counts.product_types.plural', class: 'active' }
		]);
	}

	changePage = (event: any) => {
		if (event.rows !== this.paging.pageSize) {
			this.paging.pageSize = event.rows;
		}
		this.paging.page = event.first < 1 ? 1 : ((event.first / event.rows) + 1);
		this.load();
	}

	changeLazyLoad = (event: LazyLoadEvent) => {
		if (this.sorting.sortField === event.sortField && this.sorting.sortOrder === event.sortOrder) {
			return;
		}

		this.gridSortingService.setSortOption(event.sortField, event.sortOrder === -1);

		this.sorting.sortField = event.sortField || this.gridSortingService.defaultSortColumn;
		this.sorting.sortOrder = event.sortOrder || -1;
		this.load();
	}

	exportExcel = (args: any) => {
		this.loading = true;
		const sorting = this.getSorting();
		let salesRep = '';
		this.selectedSalesRepService.SalesRep.subscribe(result => salesRep = result.SalesRepName);
		const translatedConfig = this.gridConfig.cloneAndTranslate(text => this.formatService.translateAndFormat(text, false));
		this.subscriptions.push(this.equipmentService.getSalesRepProductTypesDownload(salesRep, this.salesRepId, sorting, translatedConfig).subscribe(blob => {
			this.loading = false;
			this.downloadsService.saveExcelBlob(blob);
		}));
	}

	private handleTransactionCountClick = (record: any, parent: any): boolean => {
		this.updateBreadcrumbs([
			{
				title: parent.Description,
				class: 'active'
			},
			{
				title: record.CustomerName,
				class: 'active'
			}]);

		const urlTree = this.getTransactionUrlTree(record, parent);
		this.router.navigateByUrl(urlTree);
		return false;
	}

	private getTransactionUrlTree = (record: any, parent: any): UrlTree => {
		return this.router.createUrlTree(['transactions',
			{
				productType: parent.ProductType,
				customer: record.CustomerId
			}],
			{
				relativeTo: this.route.parent
			});
	}

	private generateTransactionsLink = (record: any, parent: any): string => {
		const urlTree = this.getTransactionUrlTree(record, parent);
		return this.location.prepareExternalUrl(urlTree.toString());
	}

	private getSorting = (): SortOptions => {
		this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
		this.sorting.sortOrder = this.gridSortingService.getSortDirectionOrDefault();
		return new SortOptions(this.sorting.sortField, !(this.sorting.sortOrder === 1));
	}

	private configureGrid = () => {
		this.clientProfileServiceSubscription = this.clientProfileService.getClientAttributes().subscribe(attributes => {
			if (this.clientProfileServiceSubscription) {
				this.clientProfileServiceSubscription.unsubscribe();
			}
			let calculatedProductTypeLength = attributes.MaxProdTypeLen * 7.25;
			calculatedProductTypeLength = calculatedProductTypeLength < 175 ? 175 : calculatedProductTypeLength;
			this.frozenWidth = `${calculatedProductTypeLength + 175 + 110}px`;
			this.gridConfig = new MetricsGridConfig(
				[
					{
						title: '',
						visible: true,
						locked: true,
						columnSelectorTitle: 'main.core.common.counts.product_types.singular',
						columns: [
							{
								title: 'main.core.common.counts.product_types.singular',
								field: 'Description',
								sortColumn: 'Description',
								width: 100,
								maxWidth: 500,
								minWidth: 115,
								autoWidth: true,
								headerStyle: {
									'text-align': 'left'
								},
								cellStyle: {
									'text-align': 'left',
									'text-transform': 'uppercase'
								},
								childConfig: {
									field: ''
								}
							},
							this.columnService.CustomerCount(this.columnService.Customer()),
							this.columnService.TransactionCount(
								this.columnService.TransactionCount(
									null,
									(v, r, p) => this.generateTransactionsLink(r, p),
									(v, r, p) => this.handleTransactionCountClick(r, p)
								)
							)
						]
					},
					...this.columnService.StandardMetricSetColumnGroups()
				],
				p => this.loadChildren(p),
				true
			);
		});
	}


	private loadChildren = (parent: any): Observable<Array<any>> => {
		const sorting = this.getSorting();
		return this.equipmentService.getSalesRepProductTypesCustomers(this.salesRepId, parent.ProductType, sorting)
			.pipe(map(items => {
				return items.map(this.convert)
			}));
	}

	private convert = (item: any): any => {
		const clone = (<any>Object).assign({}, item);
		clone.Category = null;
		clone.CustomerCount = item.CustomerName;
		return clone;
	}
}
