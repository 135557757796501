import { Injectable } from '@angular/core';
import { Observable } from 'rxjs'; //Observable';
import { of } from 'rxjs'; //observable/of';
import * as _ from 'lodash';

@Injectable({
	providedIn: 'root'
}
)
export class ProfileService {

	public clear = () => {
		sessionStorage.clear();
	}

	public saveOptions = (key: string, options: any) => {
		sessionStorage.setItem(key, JSON.stringify(options));
	}

	public getOptions = (key: string): string => {
		const options = sessionStorage.getItem(key);
		const result: string = !options ? JSON.parse('{}') : JSON.parse(options);
		return result;
	}
}
