import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SummaryComponent } from './summary/summary.component';
import { CallbackComponent } from './callback/callback.component';
import { AuthGuard } from './core/guards/auth.guard';
import { SignoutComponent } from './signout/signout.component';
import { DownloadsComponent } from './downloads';
import { DownloadComponent } from './download';
import { CustomGridsComponent } from './custom-grids';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { ProductTypeDashboardComponent } from './equipment';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

const routes: Routes = [
    {
        path: '',
        component: SummaryComponent,
        canActivate: [AuthGuard]
    }, {
        path: 'unsubscribe',
        component: UnsubscribeComponent,
        data: { title: 'main.tabs.unsubscribe.page_title' }
    }, {
        path: 'summary',
        component: SummaryComponent,
        canActivate: [AuthGuard],
        data: { title: 'main.tabs.summary.page_title' }
    }, {
        path: 'summary/:ccm',
        component: SummaryComponent,
        canActivate: [AuthGuard],
        data: { title: 'main.tabs.summary.page_title' }
    }, {
        path: 'callback',
        component: CallbackComponent,
        data: { title: 'main.navigation.page_titles.callback' }
    }, {
        path: 'signout',
        component: SignoutComponent,
        data: { title: 'main.navigation.page_titles.signout' }
    }, {
        path: 'downloads',
        component: DownloadsComponent,
        data: { title: 'main.tabs.downloads.page_titles.root' }
    }, {
        path: 'download/:filename',
        component: DownloadComponent,
        data: { title: 'main.tabs.downloads.page_titles.download' }
    }, {
        path: 'custom-grids',
        component: CustomGridsComponent,
        canActivate: [AuthGuard],
        data: { title: 'main.tabs.custom_grids.page_titles.root' }
    }, {
        path: 'unauthorized',
        component: UnauthorizedComponent,
        data: { title: 'main.navigation.page_titles.unauthorized' }
    }, {
        path: 'equipment',
        component: ProductTypeDashboardComponent,
        data: { title: 'main.tabs.equipment.product_types.page_titles.root' }
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
