import { Injectable, Inject, LOCALE_ID } from '@angular/core';
import { DateTime } from 'luxon';
import {
  getCurrencySymbol,
  DecimalPipe,
  DatePipe,
  PercentPipe,
} from '@angular/common';
import { LocaleFormat } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { RdoLocalizedDatePipe } from '../pipes/rdo-localized-date.pipe';
import { LocaleService } from '../services/locale.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FormatService {
  private decimalPipe: DecimalPipe;
  private datePipe: DatePipe;
  private percentPipe: PercentPipe;
  private localeFormat: LocaleFormat;

  get selectedCurrencySymbol(): string {
    return this.localeFormat
      ? getCurrencySymbol(this.localeFormat.CurrencyCode, 'narrow')
      : '';
  }

  constructor(
    @Inject(LOCALE_ID) private _locale: string,
    private translateService: TranslateService,
    private localeService: LocaleService
  ) {
    this.decimalPipe = new DecimalPipe(_locale);
    this.datePipe = new DatePipe(_locale);
    this.percentPipe = new PercentPipe(_locale);
  }

  public getLocaleFormat() {
    return this.localeFormat;
  }

  public getDateLocale() {
    if (this.localeFormat && this.localeFormat.LocaleID === 'de-DE') {
      return 'en-GB';
    }
    return this.localeFormat ? this.localeFormat.LocaleID : 'en-US';
  }

  public setLocaleFormat(localeFormat: LocaleFormat) {
    if (localeFormat) {
      localeFormat.LocaleID =
        localeFormat.LocaleID === 'ja' || localeFormat.LocaleID === 'jp-JP'
          ? 'ja-JP'
          : localeFormat.LocaleID;
    }
    this.localeFormat = localeFormat;
  }

  /**
   * Converts string to date, adjusting time zone to local
   */
  public stringToDate(val: string, timezone?: string): Date {
    if (!val) {
      return;
    }

    let localAdjusted: Date = null;
    if (timezone) {
      localAdjusted = DateTime.fromISO(val, { zone: timezone });
    } else {
      const local = new Date(val);
      localAdjusted = new Date(
        local.getUTCFullYear(),
        local.getUTCMonth(),
        local.getUTCDate(),
        local.getUTCHours(),
        local.getUTCMinutes()
      );
    }

    return localAdjusted;
  }

  public formatNumber2decimal(val: number) {
    return this.decimalPipe.transform(val, '1.2-2');
  }

  public formatDate(
    val: any,
    format: string = 'y MMM',
    locale?: string
  ): string {
    const date = this.stringToDate(val);
    if (date) {
      return this.formatLocalizedDateTime(date, { format, locale });
    }
    return;
  }

  public formatDateTime(val: any, format: string = 'y MMM'): string {
    const date = Date.parse(val);
    if (date) {
      return this.datePipe.transform(date, format);
    }
    return;
  }

  public formatCurrency(value: number, includeDollarSymbol: boolean): string {
    if (value == null || isNaN(value)) {
      return '-';
    }
    let formattedValue = this.decimalPipe.transform(value, '1.0-0');
    if (includeDollarSymbol) {
      if (value >= 0) {
        formattedValue = `${this.selectedCurrencySymbol}${formattedValue}`;
      }
      if (value < 0) {
        if (formattedValue.length === 1) {
          formattedValue = `(${this.selectedCurrencySymbol}${formattedValue})`;
        } else {
          formattedValue = `(${this.selectedCurrencySymbol}${formattedValue.substr(1, formattedValue.length - 1)})`;
        }
      }
    } else {
      if (value >= 0) {
        return formattedValue;
      }
      if (value < 0) {
        if (formattedValue.length === 1) {
          formattedValue = '(' + formattedValue + ')';
        } else {
          formattedValue =
            '(' + formattedValue.substr(1, formattedValue.length - 1) + ')';
        }
      }
    }
    return formattedValue;
  }

  public formatPercent(value: number): string {
    if (value === null) {
      return '-';
    }
    return this.percentPipe.transform(value, '1.1-1');
  }

  public formatNumber(value: number, decimalPrecision: number): string {
    if (value === null) {
      return '-';
    }
    let formattedValue = this.decimalPipe.transform(
      value,
      '1.0-' + decimalPrecision
    );
    if (value < 0) {
      if (formattedValue.length === 1) {
        formattedValue = '(' + formattedValue + ')';
      } else {
        formattedValue =
          '(' + formattedValue.substr(1, formattedValue.length - 1) + ')';
      }
    }
    if (Math.abs(Number(formattedValue)) % 1 === 0) {
      formattedValue = formattedValue + '.0';
    }
    return formattedValue;
  }

  public getDifferencePercent(a: number, b: number) {
    const diff = a - b;
    let result = 0;
    if (b !== 0 && b) {
      result = diff / b;
    }
    return result;
  }

  public formatCurrencyDifference(
    a: number,
    b: number,
    includeDollarSymbol = false,
    requireBothValuesToBeNonZero = false
  ): string {
    if (
      requireBothValuesToBeNonZero &&
      (a === 0 || b === 0 || a === null || b === null)
    ) {
      return `- / -`;
    }
    const diff = a - b;
    const percent = this.getDifferencePercent(a, b) * 100;
    if (!b) {
      if (!a) {
        return `- / -`;
      }
      return `${this.formatCurrency(diff, includeDollarSymbol)} / -`;
    }
    return `${this.formatCurrency(diff, includeDollarSymbol)} / ${percent.toFixed(1)}%`;
  }

  public formatPercentDifference(a: number, b: number): string {
    const diff = a - b;
    const percent = this.getDifferencePercent(a, b) * 100;
    if (!b) {
      if (!a) {
        return `- / -`;
      }
      return `${percent} / -`;
    }
    return `${diff.toFixed(1)}% ${this.translateService.instant('main.core.common.counts.pts')} / ${percent.toFixed(1)}%`;
  }

  public capitalize(text: string): string {
    let result = '';
    if (text && text.split) {
      const arr = text.split(' ');
      arr.forEach((word: string) => {
        // Capitalize words that are longer than 3 characters
        if (word.length > 3) {
          result +=
            word.charAt(0).toUpperCase() +
            word.slice(1, word.length).toLowerCase() +
            ' ';
        } else {
          result += word + ' ';
        }
      });
      // Remove additional space at the end
      result = result.substring(0, result.length - 1);
    }
    return result;
  }

  /**
   * Used for translating text that contains one or more translation paths
   * separated by spaces and (optionally) a unit or symbol at the end, which
   * is separated from the translation paths by a comma. Mostly used in Geography.
   */
  public translateAndFormat(text: string | any, removeAlls?: boolean): string {
    let result = '';
    const breadcrumbSplitter = ' - ';
    if (text || text === 0) {
      if (!isNaN(text)) {
        // It's just a number
        result = text;
      } else if (
        text.includes &&
        this.selectedCurrencySymbol &&
        text.includes(', ' + this.selectedCurrencySymbol)
      ) {
        // Currency translation
        const arr = text.split(', ');
        result =
          this.translateService.instant(arr[0]) +
          ', ' +
          this.selectedCurrencySymbol;
      } else if (
        (text.includes && text.includes(breadcrumbSplitter)) ||
        removeAlls
      ) {
        // It's a more complex translation
        result = this.translateComposedText(
          text,
          breadcrumbSplitter,
          removeAlls
        );
      } else {
        // Plain translation (default)
        try {
          result = this.translateService.instant(text);
        } catch (err) {
          console.error(err);
        }
      }
    }
    return result;
  }

  /**
   * Translates a text composed by several translateable or non-translateable elements.
   * It's mostly used with text obtained from a breadcrumb.
   * @param text Text from a breadcrumb that contains translation paths along with other symbols.
   * @param breadcrumbSplitter A string that separates breadcrumb text content.
   * @param removeAlls Remove the word "all" from the result (considering language).
   */
  translateComposedText(
    text: string | any,
    breadcrumbSplitter: string,
    removeAlls?: boolean
  ): string {
    let composed = '';
    composed += this.deepSplitAndTranslate(
      text,
      [breadcrumbSplitter, ' '],
      removeAlls
    );
    return composed;
  }

  /**
   * Splits the given text using the given plitters. Also removes the "ALL" word when
   * instructed to do so. The splitting is done recursively in the order defined within
   * the splitters array.
   * @param text
   * @param splitters
   * @param removeAlls
   */
  private deepSplitAndTranslate(
    text: string,
    splitters: string[],
    removeAlls?: boolean
  ) {
    let composed = '';
    if (splitters.length) {
      const txtArr = text.split(splitters[0]);
      txtArr.forEach((splitText: string) => {
        let translatedText: string;
        if (removeAlls) {
          translatedText = this.removeAlls(splitText);
        }
        if (!translatedText) {
          translatedText =
            splitters.length > 1
              ? this.deepSplitAndTranslate(
                  splitText,
                  splitters.slice(1, splitters.length),
                  removeAlls
                )
              : splitText && splitText.length > 1
                ? this.translateService.instant(splitText)
                : splitText;
        }
        composed += translatedText + splitters[0];
      });
      composed = composed.substring(0, composed.length - splitters[0].length);
    }
    return composed;
  }

  private removeAlls(text: string) {
    let translatedText: string;
    const allPath = '.all';
    if (this.endsWith(text, allPath)) {
      const path = text.replace(allPath, '');
      const translatedObj = this.translateService.instant(path);
      translatedText = translatedObj.title
        ? translatedObj.title
        : translatedObj.plural;
    }
    return translatedText;
  }

  private endsWith(str: string, suffix: string) {
    return str.indexOf(suffix, str.length - suffix.length) !== -1;
  }

  public formatLocalizedDateTime(
    val: any,
    options?: {
      format?: string;
      ignoreLocale?: boolean;
      placeholder?: string;
      locale?: string;
    }
  ): string {
    const defaultOptions = {
      placeholder: '-',
      ignoreLocale: false,
      format: 'short',
    };
    const { placeholder, ignoreLocale, format, locale } = Object.assign(
      {},
      defaultOptions,
      options
    );
    if (!val) {
      return placeholder;
    }
    const date = Date.parse(val);

    if (date) {
      const datePipe = ignoreLocale
        ? new DatePipe('en-US')
        : RdoLocalizedDatePipe.createInstance(locale || this.getDateLocale());
      return datePipe.transform(date, format);
    }

    return;
  }

  /**
   * Measures the length in pixels of the given text based on it's
   * content and it's font configuration (css).
   */
  public getTextWidth(
    text: string,
    font: string = 'bold small Roboto',
    iconWidth: number = 10,
    translateText: boolean = true
  ): number {
    const translatedText = translateText
      ? this.translateAndFormat(text, false)
      : text;
    // re-use canvas object for better performance
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = font;
    const metrics = context.measureText(translatedText);
    let toUse = metrics.width;
    const zoomLevel = Math.round(window.devicePixelRatio * 100);
    if (zoomLevel <= 50) {
      toUse = toUse * (60 / zoomLevel);
    }
    return Math.floor(toUse) + iconWidth;
  }

  public locale() {
    return this.localeService.getLocale();
  }
}
