import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { map, switchMap } from 'rxjs/operators';
import { ProductQuery } from './../../../models';
import * as _ from 'lodash';
import { FilterInfoService } from '../../../filter';
import * as filterFunctions from '../../../filter/functions/filter.functions';
import { RevenueMappingService, ExcelExportService } from './../../../core';
import {
    PageOptions,
    SortOptions,
    RdoHttpService,
    ExportSpreadsheetBody,
    QueryService,
    ViewService,
    MetricsGridConfig,
} from './../../../core';

@Injectable()
export class ProductTypeSalesRepService {
    private isGetRequest = false;

    constructor(
        private viewService: ViewService,
        private filterInfoService: FilterInfoService,
        private queryService: QueryService,
        private rdoHttp: RdoHttpService,
        private revenueMappingService: RevenueMappingService,
        private excelExportService: ExcelExportService
    ) {
        this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
    }

    public getProductTypeSalesReps(
        productType: string, category?: string, paging: PageOptions = new PageOptions(),
        sorting: SortOptions = new SortOptions()): Observable<any> {

        const pagedSorted = new ProductQuery();
        pagedSorted.ClientProductType = productType;
        if (category) {
            pagedSorted.RouseCategoryList = [this.filterInfoService.getRouseCategoryId(category)];
        }
        this.isGetRequest = filterFunctions.isGetRequest(this.filterInfoService);
        return this.queryService.getPagedSorted('metrics/product-types/sales-reps', paging, sorting, false, null, pagedSorted);
    }

    public getProductTypeSalesRepsDownload = (
        equipment: string,
        productType: string,
        gridConfig: MetricsGridConfig,
        category?: string,
        sorting: SortOptions = new SortOptions()
    ): Observable<object[]> => {
        const pagedSorted = new ProductQuery();
        pagedSorted.ClientProductType = productType;
        if (category) {
            pagedSorted.RouseCategoryList = [this.filterInfoService.getRouseCategoryId(category)];
        }
        const filters = this.filterInfoService.getFilterExport();
        filters['Product Type'] = equipment;
        if (category) {
            filters.Category = category;
        }
        pagedSorted.SetSorting(sorting);
        const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
        return this.excelExportService.generateGridSpreadsheet('metrics/product-types/sales-reps-download', body, pagedSorted);
    }

    public getProductTypeSalesRepCustomers = (
        productType: string, salesRep: string, category?: string, sorting: SortOptions = new SortOptions()) => {

        const pagedSorted = new ProductQuery();
        pagedSorted.Page = 1;
        pagedSorted.PageSize = 500;
        pagedSorted.ClientProductType = productType;
        pagedSorted.SalesRepId = salesRep;
        if (category) {
            pagedSorted.RouseCategoryList = [this.filterInfoService.getRouseCategoryId(category)];
        }
        pagedSorted.SetSorting(sorting);
        return this
            .rdoHttp
            .post('metrics/product-types/sales-reps/customers', pagedSorted)
            .pipe(map((r: any) => {
                return r.Items;
            }));
    }
}
