import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { first } from 'rxjs/operators';
import { LazyLoadEvent } from 'primeng/api';
import { HeaderService } from './../../../header';
import { DownloadsService } from './../../../downloads';
import {
  PageOptionsNg,
  SortOptionsNg,
  SortOptions,
  UserInfo,
} from './../../../models';
import { EquipmentService } from './../../equipment.service';
import { ProductTypeBaseComponent } from './../product-type-base.component';
import { SelectedProductTypeService } from './../selected-product-type.service';
import { ProductTypesRouteParams } from './../product-type-base.component';
import { AuthenticationService } from './../../../../app/core/authentication/authentication.service';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  ActiveFilterService,
  MetricsGridConfig,
  ColumnDefinitionService,
  BreadcrumbService,
  ClientProfileService,
  GridTableNgComponent,
  FormatService,
  GridSortingService,
} from './../../../core';

@Component({
  selector: 'rdo-rental-assets',
  templateUrl: 'rental-assets.component.html',
  styleUrls: ['rental-assets.component.scss'],
})
export class RentalAssetsComponent
  extends ProductTypeBaseComponent
  implements OnInit, OnDestroy
{
  @ViewChild('rentalAssetsGrid') rentalAssetsGrid: GridTableNgComponent;
  public loading = true;
  public data: any;
  public gridConfig: MetricsGridConfig;
  public frozenRows: any;
  public paging = new PageOptionsNg();
  public sorting: SortOptionsNg;
  public totalCount: any;
  public frozenWidth: string;
  public gridName: string = 'PRODUCT_TYPE_RENTALASSETS_GRID';
  private selectedAsset: string;
  private clientProfileServiceSubscription: Subscription;

  constructor(
    private authenticationService: AuthenticationService,
    public filterService: ActiveFilterService,
    private columnService: ColumnDefinitionService,
    private equipmentService: EquipmentService,
    private route: ActivatedRoute,
    public selectedProductTypeService: SelectedProductTypeService,
    public breadcrumbService: BreadcrumbService,
    private headerService: HeaderService,
    private downloadsService: DownloadsService,
    private clientProfileService: ClientProfileService,
    private formatService: FormatService,
    private gridSortingService: GridSortingService
  ) {
    super(selectedProductTypeService, breadcrumbService, filterService);
  }

  ngOnInit() {
    this.gridSortingService.setGridName(this.gridName);
    this.sorting = this.gridSortingService.getSortOptionsNg();

    this.subscriptions.push(
      this.route.params.subscribe((params) => {
        this.selectedAsset = params.asset
          ? decodeURIComponent(params.asset)
          : null;
        if (this.selectedAsset) {
          this.breadcrumbService.update([
            {
              title: 'main.tabs.equipment.rental_assets.title',
              class: 'active',
            },
          ]);
          this.params = new ProductTypesRouteParams();
          this.configureGrid().subscribe((isconfigurationcompleted) => {
            if (isconfigurationcompleted === true) {
              this.authenticationService.userInfoView
                .pipe(first())
                .subscribe((userInfo: UserInfo) => {
                  this.gridConfig.enableExcelExport =
                    userInfo.HasClientAccessToExportData;
                });
              this.load();
            }
          });
        }
      })
    );

    this.subscriptions.push(
      this.route.parent.params.subscribe((params) => {
        const routeParams = this.getRouteParams(params);
        if (!this.selectedAsset) {
          if (this.hasRouteChanged(this.params, routeParams)) {
            this.params = routeParams;
            this.configureGrid().subscribe((isconfigurationcompleted) => {
              if (isconfigurationcompleted === true) {
                this.authenticationService.userInfoView
                  .pipe(first())
                  .subscribe((userInfo: UserInfo) => {
                    this.gridConfig.enableExcelExport =
                      userInfo.HasClientAccessToExportData;
                  });
                this.load();
                if (!this.params.productType) {
                  this.breadcrumbService.update([
                    {
                      title: 'main.tabs.equipment.rental_assets.title',
                      class: 'active',
                    },
                  ]);
                }
              }
            });
          }
        }
      })
    );

    this.subscriptions.push(
      this.filterService.filterChange.subscribe(() => {
        this.paging.page = 1;
        if (this.rentalAssetsGrid) {
          this.rentalAssetsGrid.resetPaginator();
        }
        this.load();
      })
    );
    this.subscriptions.push(
      this.selectedProductTypeService.productTypeChange.subscribe((p) => {
        this.updateBreadcrumbs([
          {
            title: 'main.tabs.equipment.rental_assets.title',
            class: 'active',
          },
        ]);

        if (this.params.productType !== p.ProductType) {
          this.params.productType = p.ProductType;
          this.selectedProductTypeService.loading = true;
          this.paging.page = 1;
          if (this.rentalAssetsGrid) {
            this.rentalAssetsGrid.resetPaginator();
          }
          this.load();
        }
      })
    );
    if (!this.selectedProductTypeService.selectedProductType) {
      this.subscriptions.push(
        this.selectedProductTypeService.productType.subscribe((p) => {
          this.updateBreadcrumbs([
            {
              title: 'main.tabs.equipment.rental_assets.title',
              class: 'active',
            },
          ]);
        })
      );
    }
  }

  load = () => {
    this.loading = true;
    const sorting = this.getSorting();
    const asset = this.selectedAsset === 'all' ? null : this.selectedAsset;
    this.equipmentService
      .getProductTypeEquipment(
        this.params.productType,
        this.paging,
        sorting,
        this.params.category,
        asset
      )
      .subscribe((result) => {
        this.loading = false;
        this.selectedProductTypeService.loading = false;
        if (result.Items && result.Items.length > 0) {
          this.data = result.Items;
          this.totalCount = result.TotalCount;
          // this.gridConfig.lastUpdated = result.Items[0].LastUpdatedDate || null;
        } else {
          this.data = [];
          this.totalCount = 0;
        }
      });
  };

  changePage = (event: any) => {
    if (event.rows !== this.paging.pageSize) {
      this.paging.pageSize = event.rows;
    }
    this.paging.page = event.first < 1 ? 1 : event.first / event.rows + 1;
    this.load();
  };

  changeLazyLoad = (event: LazyLoadEvent) => {
    if (
      this.sorting.sortField === event.sortField &&
      this.sorting.sortOrder === event.sortOrder
    ) {
      return;
    }
    this.gridSortingService.setSortOption(
      event.sortField,
      event.sortOrder === -1
    );

    this.sorting.sortField =
      event.sortField || this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      event.sortOrder || this.gridSortingService.getSortDirectionOrDefault();
    this.load();
  };

  exportExcel = (args: any) => {
    this.loading = true;
    const sorting = this.getSorting();
    const asset = this.selectedAsset === 'all' ? null : this.selectedAsset;
    const translatedConfig = this.gridConfig.cloneAndTranslate((text) =>
      this.formatService.translateAndFormat(text, false)
    );
    this.subscriptions.push(
      this.equipmentService
        .getProductTypeEquipmentDownload(
          this.params.productType,
          translatedConfig,
          sorting,
          this.params.category,
          asset
        )
        .subscribe((blob) => {
          this.loading = false;
          this.downloadsService.saveExcelBlob(blob);
        })
    );
  };

  private getSorting = (): SortOptions => {
    this.sorting.sortField = this.gridSortingService.getSortFieldOrDefault();
    this.sorting.sortOrder =
      this.gridSortingService.getSortDirectionOrDefault();
    return new SortOptions(
      this.sorting.sortField,
      !(this.sorting.sortOrder === 1)
    );
  };

  private configureGrid = (): Observable<boolean> => {
    const result = new BehaviorSubject<boolean>(false);
    this.clientProfileServiceSubscription = this.clientProfileService
      .getClientAttributes()
      .subscribe((attributes) => {
        if (this.clientProfileServiceSubscription) {
          this.clientProfileServiceSubscription.unsubscribe();
        }
        const linkDsl = (v, r) => {
          const sorting = this.getSorting();
          const params = {
            productType: this.params.productType,
            reloadCards: true,
            sortOn: sorting.sortOn,
            desc: sorting.descending,
          };
          return ['/rental-assets', r.RouseEquipmentID, params];
        };
        let calculatedEquipmentLength = attributes.MaxEquipmentLen * 8;
        calculatedEquipmentLength =
          calculatedEquipmentLength < 120 ? 120 : calculatedEquipmentLength;
        let calculatedProductTypeLength = attributes.MaxProdTypeLen * 7.25;
        calculatedProductTypeLength =
          calculatedProductTypeLength < 175 ? 175 : calculatedProductTypeLength;
        this.frozenWidth = `${calculatedEquipmentLength}px`;
        this.gridConfig = new MetricsGridConfig(
          this.columnService.RentalAssetsColumnGroups(
            linkDsl,
            calculatedEquipmentLength,
            calculatedProductTypeLength
          )
        );
        // console.log('rental-assets-component.ts:configureGrid.next(true)');
        result.next(true);
      });
    return result.asObservable();
  };
}
