import { ElementRef, Injectable } from "@angular/core";

import { MetricsGridConfig, IGridColumnGroup } from "../../core";
import { ProfileService } from "./../../core/user";

import * as _ from "lodash";

/**
 * Persists grid configurations across component reloads
 */
@Injectable()
export class GridConfigService {
  private configs: any = {};
  private _dummyDiv: ElementRef;

  public set DummyDiv(div: ElementRef) {
    this._dummyDiv = div;
  }
  public get DummyDiv(): ElementRef {
    return this._dummyDiv;
  }
  constructor(private profileService: ProfileService) {}
  /**
   * applies previously stored (if any) column group visibility settings
   * to given grid configuration and returns same instance
   */
  public applyVisibilityOptions(
    grid: string,
    config: MetricsGridConfig,
  ): MetricsGridConfig {
    let options = this.configs[grid];
    if (!options) {
      options = this.profileService.getOptions(this.getKey(grid));
      this.configs[grid] = options;
      return this.applyOptions(options, config);
    }

    return this.applyOptions(options, config);
  }
  /**
   * saves current column group visibility settings
   */
  public saveVisibilityOptions(grid: string, config: MetricsGridConfig) {
    this.configs[grid] = _.map(config.groups, (g: IGridColumnGroup) => {
      return {
        visible: g.visible,
      };
    });
    this.profileService.saveOptions(this.getKey(grid), this.configs[grid]);
  }

  private applyOptions(
    options: any,
    gridConfig: MetricsGridConfig,
  ): MetricsGridConfig {
    _.zipWith(gridConfig.groups, options, (g: IGridColumnGroup, v: any) => {
      if (v) {
        // mutate original
        g.visible = v.visible;
      }
      return g;
    });
    return gridConfig;
  }
  private getKey(gridName: string): string {
    return `GRID_CFG_${gridName}`;
  }

  public getConfig(gridName: string): MetricsGridConfig {
    return this.configs[gridName];
  }
}
