import { Injectable, EventEmitter, Output, Directive } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';

import { FilterInfoService } from '../filter';
import { RdoHttpService, QueryService, MetricsGridConfig, ExcelExportService } from '../core';

import {
	CurrentRate,
	ChangeLog,
	DeleteChangeLogQuery,
	SortOptions,
	PageOptions,
	PagedQuery,
	ExportSpreadsheetBody,
	ProductQuery
}
from '../models';

@Directive()
@Injectable()
export class ChangesLogService {
	@Output() changesLogCreated = new EventEmitter();

	constructor(
		private filterInfoService: FilterInfoService,
		private queryService: QueryService,
		private rdoHttp: RdoHttpService,
        private excelExportService: ExcelExportService
	) {
	}	

    public getCurrentRates = (clientProductType: string, currentMonthId: number, category: string = null): Observable<CurrentRate> => {
        const url = 'change-logs/current-rates';
        const query = new ProductQuery();
        query.CurrentMonthId = currentMonthId;
        query.ClientProductType = clientProductType;

        if (category) {
            query.RouseCategoryList = [this.filterInfoService.getRouseCategoryId(category)];
        }

        return this
            .rdoHttp
            .post(url, query)
            .pipe(map(res => res as CurrentRate));
	}

	public createChangeLog = (changeLog: ChangeLog): Observable<any> => {
		const url = 'change-logs';
		return this
			.rdoHttp
			.post(url, changeLog);
	}

	public getChangeLogEntries = (paging: PageOptions = new PageOptions(), sorting: SortOptions = new SortOptions()): Observable<any> => {
        const url = 'change-logs/change-log-entries';

        return this.queryService.getPagedSorted(url, paging, sorting, false);
	}

	public getChangeLogsDownload = (sorting: SortOptions = new SortOptions(), gridConfig: MetricsGridConfig): Observable<Record<any,any>[]> => {
		const url = 'change-logs/change-log-entries-download';
		const filters = this.filterInfoService.getFilterExport();
        const pagedSorted = new PagedQuery();		
        pagedSorted.SetSorting(sorting);
		const body = new ExportSpreadsheetBody(gridConfig.getAllColumns(), filters);
		return this.excelExportService.generateGridSpreadsheet(url, body, pagedSorted);
	}

	public deleteChangeLogs = (ids: Array<number>): Observable<any> => {	
		const url = 'change-logs/change-log-entries-delete';
		const query = new DeleteChangeLogQuery({ ChangeLogIDRange: ids });
		
		return this.rdoHttp.patch(url, query);
	}
}
