/**
 * Image class
 * @private
 */
var Image = /** @class */ (function () {
    function Image() {
    }
    return Image;
}());
export { Image };
