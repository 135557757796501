import { Injectable } from '@angular/core';
import { HighchartsConfiguration } from './highcharts.directive';
import { FormatService, ActiveFilterService } from './../../../core/query';
import { MathService } from './../../../core/math';
import { TranslateService } from '@ngx-translate/core';
import { RdoLocalizedDatePipe } from '../../pipes';
import { LocaleService } from '../../services';
import { CHART_COLORS } from './chart-color-constants';

const MONTHS_CACHE = {};

@Injectable()
export abstract class ChartService {
  protected colors = [
    '#385723',
    '#E2F0D9',
    '#FFCDCD',
    '#8A0000',
    '#EEEEEE',
    'rgba(167, 167, 167, 0.3)',
    '#000000',
    'rgba(167, 167, 167, 0.2)',
    'rgba(0, 0, 0, 0.2)',
  ];
  protected title = { text: null };
  protected credits = { enabled: false };
  protected legend = {
    enabled: false,
    itemHiddenStyle: {
      symbolOpacity: 0.3,
      color: CHART_COLORS.TRANSPARENCY,
      textDecoration: 'none',
    },
  };
  protected plotOptions = { column: { grouping: false } };
  protected loading = { showDuration: 100, hideDuration: 100 };
  protected chartColor1 = 'rgba(153, 168, 189,1)';
  protected chartColor1Transparent = 'rgba(153, 168, 189,0.2)';
  protected chartColor2 = 'rgba(153, 168, 189,0.3)';
  protected chartColor2Transparent = 'rgba(153, 168, 189,0.1)';
  protected chart;
  public months = [];

  protected _activeFilterService: ActiveFilterService;
  protected _mathService: MathService;
  protected _formatService: FormatService;
  protected _translateService: TranslateService;

  constructor(
    mathService: MathService,
    private formatService: FormatService,
    translateService: TranslateService,
    activeFilterService: ActiveFilterService
  ) {
    this.months = ChartService.getLocalizedMonths(this.formatService);
    this._activeFilterService = activeFilterService;
    this._mathService = mathService;
    this._formatService = formatService;
    this._translateService = translateService;
  }

  public static getLocalizedMonths(
    formatService: FormatService,
    locale?: string
  ) {
    if (locale && MONTHS_CACHE[locale]) {
      return MONTHS_CACHE[locale];
    }

    const months = [];
    const locDatePipe = RdoLocalizedDatePipe.createInstance(
      locale || formatService.getDateLocale()
    );
    for (let i = 1; i <= 12; i++) {
      const dateMonth = i < 10 ? '0' + i : i;
      const pipedDate = locDatePipe.transform(
        new Date(dateMonth + '/01/2020'),
        'MMM yyyy'
      );
      let month = pipedDate.split(' ')[0];
      month = month.charAt(0).toUpperCase() + month.substr(1, month.length);
      months.push(month);
    }

    if (locale) {
      MONTHS_CACHE[locale] = months;
    }

    return months;
  }

  public loadChart(chart: any, data: any): HighchartsConfiguration {
    const config = new HighchartsConfiguration();
    config.options = this.getOptions(chart, data);
    config.onLoadCallback = (chart) => {
      this.chart = chart;
    };
    return config;
  }

  protected abstract getOptions(chart: any, data: any): object;

  protected getTooltipPosition(chart: any, point: any): any {
    return this.getTooltipPositionCustom(chart, point, 0, 0, 0);
  }

  public getTooltipPositionCustom(
    chart: any,
    point: any,
    xRightTranslation: number,
    xLeftTranslation: number,
    yTranslation: number
  ): any {
    const currentWidth = chart.width;
    let plotX = point.plotX;
    let plotY = yTranslation ? point.plotY : 0;
    if (plotX < currentWidth * 0.65) {
      plotX += xRightTranslation;
    } else if (plotX >= currentWidth * 0.65) {
      plotX -= xLeftTranslation;
    }
    if (yTranslation) {
      plotY += yTranslation;
    }

    return {
      x: plotX,
      y: plotY,
    };
  }

  protected isCurrentDate(month: number, year: number): boolean {
    const filterValues = this._activeFilterService.getCurrentFilter();
    const filterDate = filterValues.dateFrom;
    return filterDate.getMonth() === month && filterDate.getFullYear() === year;
  }

  protected isDateSelected(obj: any): boolean {
    const filterValues = this._activeFilterService.getCurrentFilter();
    return (
      obj.dateFrom >= filterValues.dateFrom &&
      obj.dateFrom < filterValues.dateTo
    );
  }
}
