<ng-container *ngIf="this.service.isLoaded && this.service.isActive && !this.service.isReloading">
    <div class="maintenance-mode">
        <div class="container animated">
            <h1>{{'main.maintenance.title' | translate}}</h1>
            <p>{{'main.maintenance.description' | translate}}</p>
            <time class="bold">{{this.service.nextEndTime | date:'MMM d, y, h:mm a'}}</time>
            <a routerLink="/signout">{{ 'main.navigation.sign_out' | translate }}</a>
        </div>
    </div>
</ng-container>
<!--
<ng-container *ngIf="!this.service.isContentVisible">
-->
<div [hidden]="!this.service.isContentVisible">
    <ng-content ></ng-content>
</div>
<!--</ng-container>-->

