import { Injectable, Component } from '@angular/core';
import { FormatService } from './../../core/query';
import { MathService } from './../../core/math';
import { LocaleService } from '../services';
import { IColumnConfig, IGridColumnGroup } from './index';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ColumnDefinitionService {
  constructor(
    private mathService: MathService,
    private localeService: LocaleService,
    private formatService: FormatService
  ) {}

  public filterSortableColumns(column: IColumnConfig): string | boolean {
    if (
      this.localeService.getLocale() === 'fr-CA' ||
      this.localeService.getLocale() === 'ja-JP' ||
      this.localeService.getLocale() === 'de-DE'
    ) {
      const nonSortable = [
        'Download',
        'Description',
        'ClientProductTypeDescription',
        'RouseProductType',
        'ProductTypeDescription',
        'RentalStatus',
        'ClientCategory',
        'RouseCategory',
        'EquipmentType',
      ];
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < nonSortable.length; i++) {
        if (nonSortable[i] === column.sortColumn) {
          return false;
        }
      }
    }
    return column.sortColumn;
  }

  public getFormattedDateFactory(
    format = 'M/d/YYYY',
    timezone?: string
  ): (v, r) => string {
    return (v, r) => {
      return this.formatService.formatLocalizedDateTime(
        this.formatService.stringToDate(v, timezone),
        { format }
      );
    };
  }

  public getFormattedDateTimeFactory(
    format = 'M/d/YYYY, h:mm a',
    timezone?: string,
    locale?: string
  ): (v, r) => string {
    return (v, r) => {
      const date = this.formatService.stringToDate(v, timezone);
      return this.formatService.formatLocalizedDateTime(date, {
        format,
        locale,
      });
    };
  }

  public CurrencyColumn(
    title: string,
    field: string,
    width: number = null
  ): IColumnConfig {
    return {
      title: title,
      field: field,
      sortColumn: field,
      valueFactory: (v, r) => `${this.formatService.formatCurrency(v, false)}`,
      classFactory: (v, r) => {
        return { 'text-danger': r[field] < 0, 'text-muted': !r[field] };
      },
      width: width,
    };
  }

  public CurrencyDifferenceColumn(
    title: string,
    minuend: string,
    subtrahend: string,
    sortColumn: string = null,
    requireBothValuesToBeNonZero = false
  ): IColumnConfig {
    return {
      title: title,
      field: 'ClientRevenue',
      subtrahend: subtrahend,
      minuend: minuend,
      sortColumn: sortColumn,
      showPercentDifferenceFrom: 'showPercentDifferenceFrom',
      width: 150,
      valueFactory: (v, r) =>
        `${this.formatService.formatCurrencyDifference(r[minuend], r[subtrahend], false, requireBothValuesToBeNonZero)}`,
      classFactory: (v, r) => {
        return {
          'text-danger': r[minuend] - r[subtrahend] < 0,
          'text-muted': !(r[minuend] - r[subtrahend]),
        };
      },
    };
  }

  public PercentColumn(
    title: string,
    field: string,
    sortable: boolean = false,
    width: number = null,
    drawBorder: boolean = false,
    headerStyle: any = null
  ): IColumnConfig {
    return {
      title: title,
      field: field,
      isPercent: true,
      showPercentDifferenceFrom: 'showPercentDifferenceFrom',
      headerStyle: headerStyle,
      sortColumn: sortable ? field : null,
      valueFactory: (v, r) => `${this.formatService.formatPercent(v)}`,
      classFactory: (v, r) => {
        return {
          'text-danger': r[field] < 0,
          'text-muted': !r[field],
          'grid-border-left': drawBorder,
        };
      },
      width: width,
    };
  }

  public PercentDifferenceColumn(
    title: string,
    minuend: string,
    subtrahend: string,
    sortColumn: string = null,
    showPercentDifferenceFrom: string = 'showPercentDifferenceFrom'
  ): IColumnConfig {
    return {
      title: title,
      field: minuend,
      isPercent: true,
      subtrahend: subtrahend,
      minuend: minuend,
      showPercentDifferenceFrom: showPercentDifferenceFrom,
      sortColumn: sortColumn,
      valueFactory: (v, r) =>
        `${this.formatService.formatPercent(this.mathService.substractWithNullCheck(r[minuend], r[subtrahend]))}`,
      classFactory: (v, r) => {
        return {
          'text-danger': r[minuend] - r[subtrahend] < 0,
          'text-muted': !(r[minuend] - r[subtrahend]),
        };
      },
    };
  }

  public PercentDifferenceWithUnitsColumn(
    title: string,
    unitsField: string,
    minuend: string,
    subtrahend: string,
    sortColumn: string = null
  ): IColumnConfig {
    return {
      title: title,
      field: unitsField,
      sortColumn: sortColumn,
      subtrahend: subtrahend,
      minuend: minuend,
      showPercentDifferenceFrom: 'UnitsColumn',
      width: 200,
      valueFactory: (v, r) =>
        `${
          this.formatService.formatNumber(r[unitsField], 1) +
          ' main.core.common.counts.units / ' +
          this.formatService.formatPercent(
            this.mathService.substractWithNullCheck(r[minuend], r[subtrahend])
          ) +
          ' main.core.common.counts.pts'
        }`,
      classFactory: (v, r) => {
        return {
          'text-danger': r[minuend] - r[subtrahend] < 0,
          'text-muted': !(r[minuend] - r[subtrahend]),
        };
      },
    };
  }

  public CurrencyDifferenceWithoutUnitsColumn(
    title: string,
    unitsField: string,
    minuend: string,
    subtrahend: string,
    sortColumn: string = null
  ): IColumnConfig {
    return {
      title: title,
      field: unitsField,
      sortColumn: sortColumn,
      subtrahend: subtrahend,
      minuend: minuend,
      showPercentDifferenceFrom: 'UnitsColumn',
      width: 200,
      valueFactory: (v, r) =>
        `${
          this.formatService.formatCurrency(r[unitsField], false) +
          ' / ' +
          this.formatService.formatPercent(
            this.mathService.substractWithNullCheck(r[minuend], r[subtrahend])
          ) +
          ' main.core.common.counts.pts'
        }`,
      classFactory: (v, r) => {
        return {
          'text-danger': r[minuend] - r[subtrahend] < 0,
          'text-muted': !(r[minuend] - r[subtrahend]),
        };
      },
    };
  }

  public NumberColumn(
    title: string,
    field: string,
    decimalPrecision: number,
    width: number = null,
    drawBorder: boolean = false,
    headerStyle: any = null
  ): IColumnConfig {
    return {
      title: title,
      field: field,
      headerStyle: headerStyle,
      valueFactory: (v, r) =>
        `${this.formatService.formatNumber(v, decimalPrecision)}`,
      classFactory: (v, r) => {
        return {
          'text-danger': r[field] < 0,
          'text-muted': !r[field],
          'grid-border-left': drawBorder,
        };
      },
      sortColumn: field,
      width: width,
    };
  }
  public NumberDifferenceColumn(
    title: string,
    minuend: string,
    subtrahend: string,
    sortColumn: string
  ): IColumnConfig {
    return {
      title: title,
      field: sortColumn,
      subtrahend: subtrahend,
      minuend: minuend,
      sortColumn: sortColumn,
      hasDecimals: true,
      //showPercentDifferenceFrom: 'showPercentDifferenceFrom',
      valueFactory: (v, r) =>
        `${this.formatService.formatNumber(this.mathService.substractWithNullCheck(r[minuend], r[subtrahend]), 1)}`,
      classFactory: (v, r) => {
        return {
          'text-danger': r[minuend] - r[subtrahend] < 0,
          'text-muted': !(r[minuend] - r[subtrahend]),
        };
      },
    };
  }

  ProductTypeCount(childConfig?: IColumnConfig): IColumnConfig {
    return {
      title: I18N_COUNTS.PRODUCT_TYPES,
      field: 'ProductTypeCount',
      sortColumn: 'ProductTypeCount',
      width: 85,
      // maxWidth: 500,
      // minWidth: 85,
      //autoWidth: true,
      childConfig: childConfig,
    };
  }

  CustomerCount(childConfig?: IColumnConfig): IColumnConfig {
    return {
      title: I18N_COUNTS.CUSTOMERS,
      field: 'CustomerCount',
      sortColumn: 'CustomerCount',
      width: 68,
      minWidth: 68,
      maxWidth: 220,
      childConfig: childConfig,
    };
  }

  DistrictCount(): IColumnConfig {
    return {
      title: 'main.tabs.geography.districts.plural',
      field: 'DistrictCount',
      sortColumn: 'DistrictCount',
      childConfig: {
        field: 'ClientDistrict',
      },
      width: 65,
      minWidth: 65,
      maxWidth: 180,
      valueFactory: (v, r) => {
        return v === null || v === 0 || v === '0' ? '-' : v;
      },
    };
  }

  BranchCount(): IColumnConfig {
    return {
      title: 'main.tabs.geography.branches.plural',
      field: 'LocationCount',
      sortColumn: 'LocationCount',
      childConfig: {
        field: 'LocationCode',
      },
      width: 65,
      minWidth: 65,
      maxWidth: 180,
      valueFactory: (v, r) => {
        return v === null || v === 0 || v === '0' ? '-' : v;
      },
    };
  }

  SalesRepCount(childConfig?: IColumnConfig): IColumnConfig {
    return {
      title: I18N_COUNTS.SALES_REPS,
      field: 'SalesRepCount',
      sortColumn: 'SalesRepCount',
      width: 68,
      minWidth: 68,
      maxWidth: 180,
      childConfig: childConfig,
    };
  }

  Customer(dsl?: ILinkDslResolver): IColumnConfig {
    return {
      field: 'CustomerName',
      minWidth: 110,
      width: 110,
      maxWidth: 250,
      isString: true,
      autoWidth: true,
      linkDsl: dsl,
    };
  }

  ProductType(dsl?: ILinkDslResolver): IColumnConfig {
    return {
      field: 'Description',
      cellStyle: {
        'text-transform': 'uppercase',
        'text-align': 'left',
      },
      minWidth: 115,
      width: 115,
      maxWidth: 300,
      autoWidth: true,
      linkDsl: dsl,
    };
  }

  SalesRep(dsl?: ILinkDslResolver): IColumnConfig {
    return {
      field: 'SalesRepName',
      minWidth: 100,
      width: 100,
      maxWidth: 300,
      isString: true,
      autoWidth: true,
      linkDsl: dsl,
    };
  }

  TransactionCount(
    childConfig?: IColumnConfig,
    url: IUrlResolver = null,
    clickHandler: ILinkClickHandler = null
  ): IColumnConfig {
    return {
      title: I18N_COUNTS.TRANSACTIONS,
      field: 'TransactionCount',
      sortColumn: 'TransactionCount',
      urlFactory: url,
      width: 79,
      linkClick: clickHandler,
      childConfig: childConfig,
    };
  }

  OtherRevenueColumnGroup(): IGridColumnGroup {
    const creditAmountColumn = this.CurrencyColumn(
      `${I18N_COLUMNS.CREDIT_AMOUNT}, ${this.formatService.selectedCurrencySymbol}`,
      'CreditAmount'
    );
    creditAmountColumn.width = 125;

    const excessMileageColumn = this.CurrencyColumn(
      `${I18N_COLUMNS.EXCESS_MILEAGE_REVENUE}, ${this.formatService.selectedCurrencySymbol}`,
      'ExcessMileageRevenue'
    );
    excessMileageColumn.width = 175;

    const unclassifiedRevenueColumn = this.CurrencyColumn(
      `${I18N_COLUMNS.UNCLASSIFIED_REVENUE}, ${this.formatService.selectedCurrencySymbol}`,
      'UnclassifiedRevenue'
    );
    unclassifiedRevenueColumn.width = 150;

    return {
      title: I18N_TITLES.OTHER_REVENUE,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.RE_RENT_REVENUE}, ${this.formatService.selectedCurrencySymbol}`,
          'ReRentRevenue'
        ),
        creditAmountColumn,
        excessMileageColumn,
        unclassifiedRevenueColumn,
      ],
    };
  }

  IsSubstitution(): IColumnConfig {
    return {
      title: I18N_COLUMNS.IS_SUBSTITUTION,
      field: 'IsSubstitution',
      sortColumn: 'IsSubstitution',
      width: 160,
    };
  }
  IsNationalAcct(): IColumnConfig {
    return {
      title: I18N_COLUMNS.NATIONAL_ACCT,
      field: 'IsNationalAcct',
      sortColumn: 'IsNationalAcct',
      width: 160,
    };
  }
  IsRpo(): IColumnConfig {
    return {
      title: I18N_COLUMNS.RPO,
      field: 'IsRPO',
      sortColumn: 'IsRPO',
      width: 160,
    };
  }
  IsOffshore(): IColumnConfig {
    return {
      title: I18N_COLUMNS.OFFSHORE,
      field: 'IsOffshore',
      sortColumn: 'IsOffshore',
      width: 160,
    };
  }
  IsSpecialPricing(): IColumnConfig {
    return {
      title: I18N_COLUMNS.SPECIAL_PRICING,
      field: 'IsSpecialPricing',
      sortColumn: 'IsSpecialPricing',
      width: 160,
    };
  }
  IsContract(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CONTRACT,
      field: 'IsContract',
      sortColumn: 'IsContract',
      width: 160,
    };
  }
  NationalAcctCode(): IColumnConfig {
    return {
      title: I18N_COLUMNS.NATIONAL_ACCT_CODE,
      field: 'NationalAcctCode',
      sortColumn: 'NationalAcctCode',
      width: 150,
      isString: true,
    };
  }
  ContractPriceNo(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CONTRACT_PRICE_NO,
      field: 'ContractPriceNo',
      sortColumn: 'ContractPriceNo',
      width: 150,
      isString: true,
    };
  }
  ContractStartDate(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CONTRACT_START_DATE,
      field: 'ContractStartDate',
      sortColumn: 'ContractStartDate',
      valueFactory: (v, r) => {
        const locale = this.localeService.getLocale();
        const result = this.formatService.formatDate(v, 'y MMM', locale);
        return result;
      },
      width: 150,
    };
  }
  CycleBillNo(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CYCLE_BILL_NO,
      field: 'CycleBillNo',
      sortColumn: 'CycleBillNo',
    };
  }
  JobsiteZipCode(): IColumnConfig {
    return {
      title: I18N_COLUMNS.JOB_SITE_ZIP_CODE,
      field: 'JobsiteZipCode',
      sortColumn: 'JobsiteZipCode',
      isString: true,
    };
  }
  BranchZipCode(): IColumnConfig {
    return {
      title: I18N_COLUMNS.BRANCH_ZIP_CODE,
      field: 'BranchZipCode',
      sortColumn: 'BranchZipCode',
      width: 150,
      isString: true,
    };
  }
  IsOutlier(): IColumnConfig {
    return {
      title: I18N_COLUMNS.OUTLIER,
      field: 'IsOutlier',
      sortColumn: 'IsOutlier',
      width: 160,
    };
  }
  OutlierReason(): IColumnConfig {
    return {
      title: I18N_COLUMNS.OUTLIER_REASON,
      field: 'OutlierReason',
      sortColumn: 'OutlierReason',
      width: 450,
      isString: true,
    };
  }
  TransactionAttributesColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.TRANSACTION_ATTRIBUTES,
      visible: true,
      expandable: true,
      columns: [
        this.IsSubstitution(),
        this.IsNationalAcct(),
        this.NationalAcctCode(),
        this.IsRpo(),
        this.IsOffshore(),
        this.IsSpecialPricing(),
        this.IsContract(),
        this.ContractPriceNo(),
        this.CycleBillNo(),
        this.ContractStartDate(),
        this.IsOutlier(),
        this.OutlierReason(),
      ],
    };
  }

  ClientRegion(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CLIENT_REGION,
      field: 'ClientRegion',
      sortColumn: 'ClientRegion',
      isString: true,
    };
  }

  ClientDistrict(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CLIENT_DISTRICT,
      field: 'ClientDistrict',
      sortColumn: 'ClientDistrict',
      isString: true,
    };
  }

  LocationCode(): IColumnConfig {
    return {
      title: I18N_COLUMNS.LOCATION_CODE,
      field: 'LocationCode',
      sortColumn: 'LocationCode',
      isString: true,
    };
  }

  RouseRegion(): IColumnConfig {
    return {
      title: I18N_COLUMNS.ROUSE_REGION,
      field: 'RouseRegion',
      sortColumn: 'RouseRegion',
      isString: true,
    };
  }

  RouseDistrict(): IColumnConfig {
    return {
      title: I18N_COLUMNS.ROUSE_DISTRICT,
      field: 'RouseDistrict',
      sortColumn: 'RouseDistrict',
      isString: true,
    };
  }

  RouseMarket(): IColumnConfig {
    return {
      title: I18N_COLUMNS.ROUSE_MARKET,
      field: 'RouseMarket',
      sortColumn: 'RouseMarket',
      isString: true,
    };
  }
  LocationDetailsColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.LOCATION_DETAIL,
      visible: false,
      expandable: true,
      columns: [
        this.JobsiteZipCode(),
        this.BranchZipCode(),
        this.ClientRegion(),
        this.ClientDistrict(),
        this.LocationCode(),
        this.RouseRegion(),
        this.RouseDistrict(),
        this.RouseMarket(),
      ],
    };
  }

  ClientCategory(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CLIENT_CATEGORY,
      field: 'ClientCategory',
      sortColumn: 'ClientCategory',
      width: 150,
      isString: true,
    };
  }

  ClientProductType(): IColumnConfig {
    return {
      title: I18N_COLUMNS.CLIENT_PRODUCT_TYPE,
      field: 'ClientProductType',
      sortColumn: 'ClientProductType',
      classFactory: (a, b) => 'text-uppercase',
      width: 150,
      isString: true,
    };
  }

  RouseCategory(): IColumnConfig {
    return {
      title: I18N_COLUMNS.ROUSE_CATEGORY,
      field: 'RouseCategory',
      sortColumn: 'RouseCategory',
      width: 250,
      isString: true,
    };
  }

  RouseProductType(): IColumnConfig {
    return {
      title: I18N_COLUMNS.ROUSE_PRODUCT_TYPE,
      field: 'RouseProductType',
      sortColumn: 'RouseProductType',
      width: 250,
      isString: true,
    };
  }
  Description(): IColumnConfig {
    return {
      title: I18N_COLUMNS.PRODUCT_TYPE_DESCRIPTION,
      field: 'Description',
      sortColumn: 'Description',
      cellStyle: {
        'text-align': 'right',
      },
      width: 140,
      maxWidth: 500,
      minWidth: 140,
      autoWidth: true,
    };
  }

  EquipmentId(): IColumnConfig {
    return {
      title: I18N_COLUMNS.EQUIPMENT_ID,
      field: 'EquipmentId',
      sortColumn: 'EquipmentId',
      isString: true,
    };
  }

  EquipmentTypeDetailColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.EQUIPMENT_TYPE_DETAIL,
      visible: true,
      expandable: true,
      columns: [
        this.Description(),
        this.ClientCategory(),
        this.ClientProductType(),
        this.EquipmentId(),
        this.RouseCategory(),
        this.RouseProductType(),
      ],
    };
  }

  TotalRevenueColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.REVENUE_VS_BOOK_RATE_REVENUE,
      columnSelectorTitle: I18N_TITLES.REVENUE_VS_BOOK_RATE_REVENUE,
      visible: true,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_TOTAL}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueTotal'
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueBook'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueTotal',
          'RevenueBook',
          'RevenueBookDifference'
        ),
      ],
    };
  }

  BenchmarkedRevenueComparisonColumnGroup(
    visible: boolean = true
  ): IGridColumnGroup {
    return {
      title: I18N_TITLES.REVENUE_VS_BENCHMARK_RATE_REVENUE,
      columnSelectorTitle: I18N_TITLES.REVENUE_VS_BENCHMARK_RATE_REVENUE,
      visible: visible,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_COMPARED}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueBenchmarked',
          115
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueBenchmarked',
          'RevenueBench',
          'RevenueBenchmarkedDifference'
        ),
      ],
    };
  }
  TopQuartileColumnGroup(): IGridColumnGroup {
    const premiumColumn = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_TQ_PREMIUM}, ${this.formatService.selectedCurrencySymbol}`,
      'PremiumTQ'
    );
    premiumColumn.width = 125;

    const transactionCountWidth =
      this.localeService.getLocale() === 'de-DE' ? 127 : 110;
    return {
      title: I18N_TITLES.PREMIUM_TO_BENCHMARK_TOP_QUARTILE,
      columnSelectorTitle: I18N_TITLES.PREMIUM_TO_BENCHMARK_TOP_QUARTILE,
      headerStyle: { 'background-color': '#3f873f' },
      expandable: true,
      columns: [
        premiumColumn,
        this.CurrencyColumn(
          `${I18N_COLUMNS.REVENUE_ABOVE_TQ}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueAboveTQ',
          125
        ),
        this.CurrencyColumn(
          I18N_COLUMNS.TRANSACTIONS,
          'TransactionsAboveTQ',
          transactionCountWidth
        ),
      ],
    };
  }
  BottomQuartileColumnGroup(): IGridColumnGroup {
    const discountColumn = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_BQ_DISCOUNT}, ${this.formatService.selectedCurrencySymbol}`,
      'DiscountBQ'
    );
    discountColumn.width = 125;
    const transactionCountWidth =
      this.localeService.getLocale() === 'de-DE' ? 127 : 110;
    return {
      title: I18N_TITLES.DISCOUNT_TO_BENCHMARK_BOTTOM_QUARTILE,
      columnSelectorTitle: I18N_TITLES.DISCOUNT_TO_BENCHMARK_BOTTOM_QUARTILE,
      headerStyle: { 'background-color': '#cb2f2f' },
      expandable: true,
      columns: [
        discountColumn,
        this.CurrencyColumn(
          `${I18N_COLUMNS.REVENUE_BELOW_BQ}, ${this.formatService.selectedCurrencySymbol}`,
          'RevenueBelowBQ',
          125
        ),
        this.CurrencyColumn(
          I18N_COLUMNS.TRANSACTIONS,
          'TransactionsBelowBQ',
          transactionCountWidth
        ),
      ],
    };
  }
  NewMonthlyRevenueTotalColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_TOTAL}, ${this.formatService.selectedCurrencySymbol}`,
      'NewMonthlyRevenueTotal'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      columnSelectorTitle:
        I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          'NewMonthlyRevenueBook'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'NewMonthlyRevenueTotal',
          'NewMonthlyRevenueBook',
          'NewMonthlyRevenueBookDifference'
        ),
      ],
    };
  }
  NewMonthlyRevenueBenchmarkedColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_COMPARED}, ${this.formatService.selectedCurrencySymbol}`,
      'NewMonthlyRevenueBenchmarked'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      columnSelectorTitle:
        I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'NewMonthlyRevenueBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'NewMonthlyRevenueBenchmarked',
          'NewMonthlyRevenueBench',
          'NewMonthlyRevenueBenchmarkedDifference'
        ),
      ],
    };
  }
  NewMonthlyRatesColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.NEW_MONTHLY_RATE_COMPARISON,
      columnSelectorTitle: I18N_TITLES.NEW_MONTHLY_RATE_COMPARISON,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT}, ${this.formatService.selectedCurrencySymbol}`,
          'NewMonthlyRate'
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'NewMonthlyRateBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'NewMonthlyRate',
          'NewMonthlyRateBench',
          'NewMonthlyRateDifference',
          true
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          `NewMonthlyBookRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.FLOOR}, ${this.formatService.selectedCurrencySymbol}`,
          `NewMonthlyFloorRate`
        ),
      ],
    };
  }
  MonthlyRevenueTotalColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_TOTAL}, ${this.formatService.selectedCurrencySymbol}`,
      'MonthlyRevenueTotal'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      columnSelectorTitle:
        I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          'MonthlyRevenueBook'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'MonthlyRevenueTotal',
          'MonthlyRevenueBook',
          'MonthlyRevenueBookDifference'
        ),
      ],
    };
  }
  MonthlyRevenueBenchmarkedColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_COMPARED}, ${this.formatService.selectedCurrencySymbol}`,
      'MonthlyRevenueBenchmarked'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      columnSelectorTitle:
        I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'MonthlyRevenueBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'MonthlyRevenueBenchmarked',
          'MonthlyRevenueBench',
          'MonthlyRevenueBenchmarkedDifference'
        ),
      ],
    };
  }
  MonthlyRatesColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.MONTHLY_RATE_COMPARISON,
      columnSelectorTitle: I18N_TITLES.MONTHLY_RATE_COMPARISON,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT}, ${this.formatService.selectedCurrencySymbol}`,
          'MonthlyRate'
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'MonthlyRateBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'MonthlyRate',
          'MonthlyRateBench',
          'MonthlyRateDifference',
          true
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          `MonthlyBookRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.FLOOR}, ${this.formatService.selectedCurrencySymbol}`,
          `MonthlyFloorRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MAX}, ${this.formatService.selectedCurrencySymbol}`,
          `MonthlyRateMaxBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_TQ}, ${this.formatService.selectedCurrencySymbol}`,
          `MonthlyRateTQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_BQ}, ${this.formatService.selectedCurrencySymbol}`,
          `MonthlyRateBQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MIN}, ${this.formatService.selectedCurrencySymbol}`,
          `MonthlyRateMinBench`
        ),
      ],
    };
  }
  WeeklyRevenueTotalColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_TOTAL}, ${this.formatService.selectedCurrencySymbol}`,
      'WeeklyRevenueTotal'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      columnSelectorTitle: I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          'WeeklyRevenueBook'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'WeeklyRevenueTotal',
          'WeeklyRevenueBook',
          'WeeklyRevenueBookDifference'
        ),
      ],
    };
  }
  WeeklyRevenueBenchmarkedColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_COMPARED}, ${this.formatService.selectedCurrencySymbol}`,
      'WeeklyRevenueBenchmarked'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      columnSelectorTitle:
        I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'WeeklyRevenueBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'WeeklyRevenueBenchmarked',
          'WeeklyRevenueBench',
          'WeeklyRevenueBenchmarkedDifference'
        ),
      ],
    };
  }
  WeeklyRatesColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.WEEKLY_RATE_COMPARISON,
      columnSelectorTitle: I18N_TITLES.WEEKLY_RATE_COMPARISON,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT}, ${this.formatService.selectedCurrencySymbol}`,
          'WeeklyRate'
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'WeeklyRateBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'WeeklyRate',
          'WeeklyRateBench',
          'WeeklyRateDifference',
          true
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          `WeeklyBookRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.FLOOR}, ${this.formatService.selectedCurrencySymbol}`,
          `WeeklyFloorRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MAX}, ${this.formatService.selectedCurrencySymbol}`,
          `WeeklyRateMaxBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_TQ}, ${this.formatService.selectedCurrencySymbol}`,
          `WeeklyRateTQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_BQ}, ${this.formatService.selectedCurrencySymbol}`,
          `WeeklyRateBQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MIN}, ${this.formatService.selectedCurrencySymbol}`,
          `WeeklyRateMinBench`
        ),
      ],
    };
  }
  HourlyRevenueTotalColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_TOTAL}, ${this.formatService.selectedCurrencySymbol}`,
      'HourlyRevenueTotal'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.HOURLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      columnSelectorTitle: I18N_TITLES.HOURLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          'HourlyRevenueBook'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'HourlyRevenueTotal',
          'HourlyRevenueBook',
          'HourlyRevenueBookDifference'
        ),
      ],
    };
  }
  HourlyRevenueBenchmarkedColumnGroup(): IGridColumnGroup {
    const column = this.CurrencyColumn(
      `${I18N_COLUMNS.CLIENT_COMPARED}, ${this.formatService.selectedCurrencySymbol}`,
      'HourlyRevenueBenchmarked'
    );
    column.width = 120;
    return {
      title: I18N_TITLES.HOURLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      columnSelectorTitle:
        I18N_TITLES.HOURLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      expandable: true,
      columns: [
        column,
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'HourlyRevenueBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'HourlyRevenueBenchmarked',
          'HourlyRevenueBench',
          'HourlyRevenueBenchmarkedDifference'
        ),
      ],
    };
  }
  HourlyRatesColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.HOURLY_RATE_COMPARISON,
      columnSelectorTitle: I18N_TITLES.HOURLY_RATE_COMPARISON,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT}, ${this.formatService.selectedCurrencySymbol}`,
          'HourlyRate'
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'HourlyRateBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'HourlyRate',
          'HourlyRateBench',
          'HourlyRateDifference',
          true
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          `HourlyBookRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.FLOOR}, ${this.formatService.selectedCurrencySymbol}`,
          `HourlyFloorRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MAX}, ${this.formatService.selectedCurrencySymbol}`,
          `HourlyRateMaxBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_TQ}, ${this.formatService.selectedCurrencySymbol}`,
          `HourlyRateTQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_BQ}, ${this.formatService.selectedCurrencySymbol}`,
          `HourlyRateBQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MIN}, ${this.formatService.selectedCurrencySymbol}`,
          `HourlyRateMinBench`
        ),
      ],
    };
  }
  DailyRevenueTotalColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.DAILY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      columnSelectorTitle: I18N_TITLES.DAILY_RATE_REVENUE_VS_BOOK_RATE_REVENUE,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_TOTAL}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRevenueTotal'
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRevenueBook'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRevenueTotal',
          'DailyRevenueBook',
          'DailyRevenueBookDifference'
        ),
      ],
    };
  }
  DailyRevenueBenchmarkedColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.DAILY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      columnSelectorTitle:
        I18N_TITLES.DAILY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT_COMPARED}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRevenueBenchmarked',
          120
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRevenueBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRevenueBenchmarked',
          'DailyRevenueBench',
          'DailyRevenueBenchmarkedDifference'
        ),
      ],
    };
  }
  DailyRatesColumnGroup(): IGridColumnGroup {
    return {
      title: I18N_TITLES.DAILY_RATE_COMPARISON,
      columnSelectorTitle: I18N_TITLES.DAILY_RATE_COMPARISON,
      expandable: true,
      columns: [
        this.CurrencyColumn(
          `${I18N_COLUMNS.CLIENT}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRate'
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCHMARK}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRateBench'
        ),
        this.CurrencyDifferenceColumn(
          `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
          'DailyRate',
          'DailyRateBench',
          'DailyRateDifference',
          true
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BOOK}, ${this.formatService.selectedCurrencySymbol}`,
          `DailyBookRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.FLOOR}, ${this.formatService.selectedCurrencySymbol}`,
          `DailyFloorRate`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MAX}, ${this.formatService.selectedCurrencySymbol}`,
          `DailyRateMaxBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_TQ}, ${this.formatService.selectedCurrencySymbol}`,
          `DailyRateTQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_BQ}, ${this.formatService.selectedCurrencySymbol}`,
          `DailyRateBQBench`
        ),
        this.CurrencyColumn(
          `${I18N_COLUMNS.BENCH_MIN}, ${this.formatService.selectedCurrencySymbol}`,
          `DailyRateMinBench`
        ),
      ],
    };
  }

  StandardMetricSetColumnGroups(
    includeNewMonthlyMetrics: boolean = false
  ): IGridColumnGroup[] {
    const baseColumnGroups = [
      this.TotalRevenueColumnGroup(),
      this.BenchmarkedRevenueComparisonColumnGroup(),
      this.TopQuartileColumnGroup(),
      this.BottomQuartileColumnGroup(),
    ];
    const baseRentalRatesColumnGroups = [
      this.MonthlyRevenueTotalColumnGroup(),
      this.MonthlyRevenueBenchmarkedColumnGroup(),
      this.MonthlyRatesColumnGroup(),
      this.WeeklyRevenueTotalColumnGroup(),
      this.WeeklyRevenueBenchmarkedColumnGroup(),
      this.WeeklyRatesColumnGroup(),
      this.DailyRevenueTotalColumnGroup(),
      this.DailyRevenueBenchmarkedColumnGroup(),
      this.DailyRatesColumnGroup(),
      this.HourlyRevenueTotalColumnGroup(),
      this.HourlyRevenueBenchmarkedColumnGroup(),
      this.HourlyRatesColumnGroup(),
    ];
    if (includeNewMonthlyMetrics) {
      const newMonthlyColumnGroups = [
        this.NewMonthlyRevenueTotalColumnGroup(),
        this.NewMonthlyRevenueBenchmarkedColumnGroup(),
        this.NewMonthlyRatesColumnGroup(),
      ];
      return [
        ...baseColumnGroups,
        ...newMonthlyColumnGroups,
        ...baseRentalRatesColumnGroups,
      ];
    } else {
      return [...baseColumnGroups, ...baseRentalRatesColumnGroups];
    }
  }

  FullChangesLogColumnGroups(): IGridColumnGroup[] {
    const checkbox = {
      field: null,
      sortColumn: null,
      width: 50,
      skipExport: true,
    };
    const locale = this.localeService.getLocale();
    const entryTime = {
      title: 'main.tabs.changes_log.tables.entry_details.entry_time',
      field: 'DateCreated',
      sortColumn: 'DateCreated',
      width: 150,
      // We need the date formatted according to the client settings.
      // But we need the characters in the string be be localized according to
      // the user's chosen language.
      // Otherwise, we can have an issue where say an english speaker is given japanese
      // AM or PM characters.
      valueFactory: this.getFormattedDateTimeFactory(
        'M/d/YYYY, h:mm a',
        'America/Los_Angeles'
      ),
      /*valueFactory: (v, r) => {
        let result = '';

        const clientDateString = this.getFormattedDateTimeFactory(
          'M/d/YYYY, h:mm a',
          'America/Los_Angeles'
        )(v, r);
        const userDateString = this.getFormattedDateTimeFactory(
          'M/d/YYYY, h:mm a',
          'America/Los_Angeles',
          locale
        )(v, r);
        const clientArr = clientDateString.split('');
        clientArr.forEach((str, index) => {
          const int = parseInt(str);
          const userStr = userDateString[index];
          if (Number.isNaN(int)) {
            if (userStr !== str) {
              result = `${result}${userStr}`;
            } else {
              result = `${result}${str}`;
            }
          } else {
            result = `${result}${str}`;
          }
        });

        return result;
      },*/
    };
    const comment = {
      title: 'main.tabs.changes_log.tables.entry_details.comments',
      field: 'Comment',
      sortColumn: 'Comment',
      width: 375,
      headerStyle: {
        'text-align': 'left',
      },
      valueFactory: (v, r) => {
        return v === null ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
      cellStyle: {
        'text-align': 'left',
      },
    };
    const description = {
      title: 'main.tabs.changes_log.tables.product_type',
      field: 'Description',
      sortColumn: 'Description',
      headerStyle: {
        'text-align': 'left',
      },
      cellStyle: {
        'text-align': 'left',
      },
      width: 100,
      maxWidth: 500,
      minWidth: 115,
      autoWidth: true,
      valueFactory: (v, r) => {
        return v === null ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
    };
    const clientProductCode = {
      title: 'Client Product Code',
      field: 'ClientProductType',
      sortColumn: 'ClientProductType',
      width: 175,
      valueFactory: (v, r) => {
        return v === null ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
    };
    const country = {
      title: 'Country',
      field: 'Country',
      sortColumn: 'Country',
      valueFactory: (v, r) => {
        return v === null ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
    };
    const region = {
      title: 'main.tabs.changes_log.tables.client_geography.region',
      field: 'Region',
      sortColumn: 'Region',
      valueFactory: (v, r) => {
        return v === null
          ? 'main.tabs.changes_log.tables.client_geography.all'
          : v;
      },
      classFactory: (v, r) => {
        return {
          'left-text':
            v !== 'main.tabs.changes_log.tables.client_geography.all',
          'center-text text-muted':
            v === 'main.tabs.changes_log.tables.client_geography.all',
        };
      },
    };
    const district = {
      title: 'main.tabs.changes_log.tables.client_geography.district',
      field: 'District',
      sortColumn: 'District',
      valueFactory: (v, r) => {
        return v === null
          ? 'main.tabs.changes_log.tables.client_geography.all'
          : v;
      },
      classFactory: (v, r) => {
        return {
          'left-text':
            v !== 'main.tabs.changes_log.tables.client_geography.all',
          'center-text text-muted':
            v === 'main.tabs.changes_log.tables.client_geography.all',
        };
      },
    };
    const branch = {
      title: 'main.tabs.changes_log.tables.client_geography.branch',
      field: 'LocationCode',
      sortColumn: 'LocationCode',
      width: 175,
      valueFactory: (v, r) => {
        return v === null
          ? 'main.tabs.changes_log.tables.client_geography.all'
          : v;
      },
      classFactory: (v, r) => {
        return {
          'left-text':
            v !== 'main.tabs.changes_log.tables.client_geography.all',
          'center-text text-muted':
            v === 'main.tabs.changes_log.tables.client_geography.all',
        };
      },
    };

    const bookNewMonthlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.monthly, ${this.formatService.selectedCurrencySymbol}`,
      'NewMonthlyBookRevenueRate'
    );
    const bookNewWeeklyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.weekly, ${this.formatService.selectedCurrencySymbol}`,
      'NewWeeklyBookRevenueRate'
    );
    const bookNewDailyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.daily, ${this.formatService.selectedCurrencySymbol}`,
      'NewDailyBookRevenueRate'
    );
    const bookNewHourlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.hourly, ${this.formatService.selectedCurrencySymbol}`,
      'NewHourlyBookRevenueRate'
    );

    const floorNewMonthlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.monthly, ${this.formatService.selectedCurrencySymbol}`,
      'NewMonthlyFloorRevenueRate'
    );
    const floorNewWeeklyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.weekly, ${this.formatService.selectedCurrencySymbol}`,
      'NewWeeklyFloorRevenueRate'
    );
    const floorNewDailyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.daily, ${this.formatService.selectedCurrencySymbol}`,
      'NewDailyFloorRevenueRate'
    );
    const floorNewHourlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.hourly, ${this.formatService.selectedCurrencySymbol}`,
      'NewHourlyFloorRevenueRate'
    );

    const bookMonthlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.monthly, ${this.formatService.selectedCurrencySymbol}`,
      'MonthlyBookRevenueRate'
    );
    const bookWeeklyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.weekly, ${this.formatService.selectedCurrencySymbol}`,
      'WeeklyBookRevenueRate'
    );
    const bookDailyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.daily, ${this.formatService.selectedCurrencySymbol}`,
      'DailyBookRevenueRate'
    );
    const bookHourlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.hourly, ${this.formatService.selectedCurrencySymbol}`,
      'HourlyBookRevenueRate'
    );

    const floorMonthlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.monthly, ${this.formatService.selectedCurrencySymbol}`,
      'MonthlyFloorRevenueRate'
    );
    const floorWeeklyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.weekly, ${this.formatService.selectedCurrencySymbol}`,
      'WeeklyFloorRevenueRate'
    );
    const floorDailyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.daily, ${this.formatService.selectedCurrencySymbol}`,
      'DailyFloorRevenueRate'
    );
    const floorHourlyColumn = this.CurrencyColumn(
      `main.tabs.changes_log.tables.common.hourly, ${this.formatService.selectedCurrencySymbol}`,
      'HourlyFloorRevenueRate'
    );

    const fullName = {
      title: 'main.tabs.changes_log.tables.user_details.full_name',
      field: 'FullName',
      sortColumn: 'FullName',
      width: 175,
      headerStyle: {
        'text-align': 'left',
      },
      valueFactory: (v, r) => {
        return v === null ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
      cellStyle: {
        'text-align': 'left',
      },
    };
    const emailAddress = {
      title: 'main.tabs.changes_log.tables.user_details.email_address',
      field: 'EmailAddress',
      sortColumn: 'EmailAddress',
      width: 175,
      headerStyle: {
        'text-align': 'left',
      },
      valueFactory: (v, r) => {
        return v === null ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
      cellStyle: {
        'text-align': 'left',
      },
    };
    return [
      {
        title: '',
        columnSelectorTitle: 'main.tabs.changes_log.tables.product_type',
        tooltip: 'product_type',
        visible: true,
        locked: true,
        columns: [checkbox, description],
      },
      {
        title: 'main.tabs.changes_log.tables.client_geography.title',
        columnSelectorTitle:
          'main.tabs.changes_log.tables.client_geography.title',
        tooltip: 'client_geography',
        visible: true,
        applyBackgroundColor: true,
        columns: [region, district, branch],
      },
      {
        title: 'main.tabs.changes_log.tables.prior_book_rates.title',
        columnSelectorTitle:
          'main.tabs.changes_log.tables.prior_book_rates.title',
        tooltip: 'prior_book_rates',
        applyBackgroundColor: true,
        columns: [
          bookMonthlyColumn,
          bookWeeklyColumn,
          bookDailyColumn,
          bookHourlyColumn,
        ],
      },
      {
        title: 'main.tabs.changes_log.tables.new_book_rates.title',
        columnSelectorTitle:
          'main.tabs.changes_log.tables.new_book_rates.title',
        tooltip: 'new_book_rates',
        visible: true,
        columns: [
          bookNewMonthlyColumn,
          bookNewWeeklyColumn,
          bookNewDailyColumn,
          bookNewHourlyColumn,
        ],
      },
      {
        title: 'main.tabs.changes_log.tables.prior_floor_rates.title',
        columnSelectorTitle:
          'main.tabs.changes_log.tables.prior_floor_rates.title',
        tooltip: 'prior_floor_rates',
        applyBackgroundColor: true,
        columns: [
          floorMonthlyColumn,
          floorWeeklyColumn,
          floorDailyColumn,
          floorHourlyColumn,
        ],
      },
      {
        title: 'main.tabs.changes_log.tables.new_floor_rates.title',
        columnSelectorTitle:
          'main.tabs.changes_log.tables.new_floor_rates.title',
        tooltip: 'new_floor_rates',
        visible: true,
        expanderClass: 'expander-white',
        columns: [
          floorNewMonthlyColumn,
          floorNewWeeklyColumn,
          floorNewDailyColumn,
          floorNewHourlyColumn,
        ],
      },
      {
        title: 'main.tabs.changes_log.tables.entry_details.title',
        columnSelectorTitle: 'main.tabs.changes_log.tables.entry_details.title',
        tooltip: 'entry_details',
        visible: true,
        applyBackgroundColor: true,
        columns: [entryTime, comment],
      },
      {
        title: 'main.tabs.changes_log.tables.user_details.title',
        columnSelectorTitle: 'main.tabs.changes_log.tables.user_details.title',
        tooltip: 'user_details',
        applyBackgroundColor: true,
        columns: [fullName, emailAddress],
      },
    ];
  }

  rentalAssetColumn = (
    title: string,
    field: string,
    width?: number,
    options?: any
  ): any => {
    const column: any = {
      title: title,
      field: field,
      sortColumn: field,
      valueFactory: (v, r) => {
        return _.isNil(v) ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
    };

    if (width) {
      column.width = width;
    }

    if (options) {
      if (options.isString) {
        column.isString = true;
      }
    }
    return column;
  };

  RentalAssetsColumnGroups = (
    linkDsl: any = null,
    equipmentIdLength: number = 100,
    productTypeLength: number = 175
  ): IGridColumnGroup[] => {
    const equipmentIdColumn = {
      title: 'main.tabs.equipment.rental_assets.equipment_id',
      field: 'EquipmentId',
      sortColumn: 'EquipmentId',
      width: 100, //equipmentIdLength,
      maxWidth: 255,
      autoWidth: true,
      headerStyle: {
        'text-align': 'left',
      },
      cellStyle: {
        'text-align': 'left',
      },
      linkDsl: linkDsl,
      valueFactory: (v, r) => {
        return _.isNil(v) ? '-' : v;
      },
      classFactory: (v, r) => {
        return { 'left-text': v !== '-', 'center-text text-muted': v === '-' };
      },
      isString: true,
    };

    // Summary Information Columns
    const clientProductTypeDescriptionColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.summary_information.description',
      'ClientProductTypeDescription',
      productTypeLength,
      { isString: true }
    );
    const onRentColumn = {
      title: 'main.tabs.equipment.rental_assets.summary_information.on_rent',
      field: 'OnRent',
      sortColumn: 'OnRent',
    };
    const availableColumn = {
      title: 'main.tabs.equipment.rental_assets.summary_information.available',
      field: 'IsAvailable',
      sortColumn: 'IsAvailable',
    };
    const inFleetColumn = {
      title: 'main.tabs.equipment.rental_assets.summary_information.in_fleet',
      field: 'IsInFleet',
      sortColumn: 'IsInFleet',
    };
    const rentalStatusColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.summary_information.rental_status',
      'RentalStatus',
      200,
      { isString: true }
    );
    const daysInStatusColumn = {
      title:
        'main.tabs.equipment.rental_assets.summary_information.days_in_status',
      field: 'DaysInSameStatus',
      sortColumn: 'DaysInSameStatus',
      valueFactory: (v, r) => {
        return v > 90
          ? 'main.tabs.equipment.rental_assets.current_rental_status.more_than_90'
          : v;
      },
    };

    const equipmentTypeColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.asset_details.equipment_type',
      'EquipmentType',
      150,
      { isString: true }
    );
    const regionColumn = this.rentalAssetColumn(
      'main.tabs.geography.regions.singular',
      'Region',
      200,
      { isString: true }
    );
    const districtColumn = this.rentalAssetColumn(
      'main.tabs.geography.districts.singular',
      'District',
      200,
      { isString: true }
    );
    const branchColumn = this.rentalAssetColumn(
      'main.tabs.geography.branches.singular',
      'Branch',
      200,
      { isString: true }
    );
    const branchAddressColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.branch_location.branch_address',
      'BranchAddress',
      200,
      { isString: true }
    );
    const branchCityColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.branch_location.city',
      'BranchCity',
      200,
      { isString: true }
    );
    const branchStateColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.branch_location.state',
      'BranchState',
      200,
      { isString: true }
    );
    const branchZipColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.branch_location.postal_code',
      'BranchZipCode',
      null,
      { isString: true }
    );
    const contractColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.current_rental_status.contract_no',
      'ContractNo',
      170,
      { isString: true }
    );
    const customerNameColumn = this.rentalAssetColumn(
      'main.core.common.counts.customers.singular',
      'CustomerName',
      200,
      { isString: true }
    );
    const customerNumberColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.current_rental_status.customer_no',
      'CustomerNumber',
      200,
      { isString: true }
    );
    const rouseCategoryColumn = this.rentalAssetColumn(
      'main.core.tables.common.category',
      'RouseCategory',
      150,
      { isString: true }
    );
    const rouseProductTypeColumn = this.rentalAssetColumn(
      'main.core.common.counts.product_types.singular',
      'RouseProductType',
      200,
      { isString: true }
    );
    const rouseMakeColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.rouse_classification.rouse_make',
      'RouseMake',
      160,
      { isString: true }
    );
    const rouseModelColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.rouse_classification.rouse_model',
      'RouseModel',
      150,
      { isString: true }
    );
    const serialNumberColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.asset_details.serial_no',
      'SerialNo',
      150,
      { isString: true }
    );
    const clientCategoryColumn = this.rentalAssetColumn(
      'main.core.tables.common.category',
      'ClientCategory',
      null,
      { isString: true }
    );
    const clientProductTypeColumn = this.rentalAssetColumn(
      'main.core.tables.titles.dimensions.cat_class',
      'ClientProductType',
      null,
      { isString: true }
    );
    const clientMakeColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.asset_details.client_make',
      'Make',
      160,
      { isString: true }
    );
    const clientModelColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.asset_details.client_model',
      'Model',
      150,
      { isString: true }
    );
    const clientModelYearColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.asset_details.model_year',
      'ModelYear',
      null,
      { isString: true }
    );
    const costColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.age_and_residual_value.cost, ${this.formatService.selectedCurrencySymbol}`,
      'Cost'
    );
    const nbvColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.age_and_residual_value.nbv, ${this.formatService.selectedCurrencySymbol}`,
      'NetBookValue'
    );
    const meterColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.age_and_residual_value.meter',
      'Meter'
    );
    const ageColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.age_and_residual_value.age_in_months',
      'Age',
      110
    );
    const isRpoColumn = {
      title: 'main.tabs.equipment.rental_assets.current_rental_status.is_rpo',
      field: 'IsRPO',
      sortColumn: 'IsRPO',
    };
    const isReRentColumn = {
      title:
        'main.tabs.equipment.rental_assets.current_rental_status.is_rerent',
      field: 'IsReRent',
      sortColumn: 'IsReRent',
    };

    const acquisitionDateColumn = {
      title:
        'main.tabs.equipment.rental_assets.age_and_residual_value.acquisition_date',
      field: 'AcquisitionDate',
      sortColumn: 'AcquisitionDate',
      width: 160,
      valueFactory: this.getFormattedDateFactory(),
    };

    const rentalDateColumn = {
      title:
        'main.tabs.equipment.rental_assets.age_and_residual_value.date_put_in_rental',
      field: 'DatePutInRental',
      sortColumn: 'DatePutInRental',
      width: 150,
      valueFactory: this.getFormattedDateFactory(),
    };

    const ltdTotalRevenueColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_total_revenue, ${this.formatService.selectedCurrencySymbol}`,
      'LifeToDateRevenue',
      170
    );
    const ltdDaysOnRentColumn = this.rentalAssetColumn(
      'main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_days_on_rent',
      'LifeToDateDaysOnRent',
      150
    );
    const ltdRentRevenueColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_rent_revenue, ${this.formatService.selectedCurrencySymbol}`,
      'LifeToDateRentRevenue',
      150
    );
    const ytdRentRevenueColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.ytd_rent_revenue, ${this.formatService.selectedCurrencySymbol}`,
      'YearToDateRentRevenue',
      150
    );
    const avgRentRevenueColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.avg_rent_revenue, ${this.formatService.selectedCurrencySymbol}`,
      'AverageRevenue',
      150
    );

    const ltdMaintenanceColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_maintenance, ${this.formatService.selectedCurrencySymbol}`,
      'LifeToDateMaintainance',
      150
    );
    const ltdLaborColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_labor, ${this.formatService.selectedCurrencySymbol}`,
      'LifeToDateLabor',
      150
    );
    const ltdPartsColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_parts, ${this.formatService.selectedCurrencySymbol}`,
      'LifeToDateParts',
      150
    );
    const ytdMaintenanceColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.ytd_maintenance, ${this.formatService.selectedCurrencySymbol}`,
      'YearToDateMaintainance',
      150
    );
    const avgMaintenanceColumn = this.CurrencyColumn(
      `main.tabs.equipment.rental_assets.revenue_and_maintenance.avg_maintenance, ${this.formatService.selectedCurrencySymbol}`,
      'AverageMantainance',
      150
    );

    return <IGridColumnGroup[]>[
      {
        title: '',
        columnSelectorTitle: I18N_COLUMNS.EQUIPMENT_ID,
        visible: true,
        locked: true,
        columns: [equipmentIdColumn],
      },
      {
        title: 'main.tabs.equipment.rental_assets.summary_information.title',
        columnSelectorTitle:
          'main.tabs.equipment.rental_assets.summary_information.title',
        expandable: true,
        visible: true,
        expanderClass: 'expander-white',
        expanded: true,
        columns: [
          clientProductTypeDescriptionColumn,
          onRentColumn,
          availableColumn,
          inFleetColumn,
          rentalStatusColumn,
          daysInStatusColumn,
        ],
      },
      {
        title: 'main.tabs.equipment.rental_assets.asset_details.title',
        columnSelectorTitle:
          'main.tabs.equipment.rental_assets.asset_details.title',
        expandable: true,
        visible: true,
        expanderClass: 'expander-white',
        expanded: true,
        columns: [
          clientMakeColumn,
          clientModelColumn,
          clientModelYearColumn,
          clientCategoryColumn,
          clientProductTypeColumn,
          equipmentTypeColumn,
          serialNumberColumn,
        ],
      },
      {
        title: 'main.tabs.equipment.rental_assets.rouse_classification.title',
        columnSelectorTitle:
          'main.tabs.equipment.rental_assets.rouse_classification.title',
        expandable: true,
        visible: true,
        expanderClass: 'expander-white',
        columns: [
          rouseMakeColumn,
          rouseModelColumn,
          rouseCategoryColumn,
          rouseProductTypeColumn,
        ],
      },
      {
        title: 'main.tabs.equipment.rental_assets.branch_location.title',
        columnSelectorTitle:
          'main.tabs.equipment.rental_assets.branch_location.title',
        expandable: true,
        visible: true,
        expanderClass: 'expander-white',
        columns: [
          regionColumn,
          districtColumn,
          branchColumn,
          branchAddressColumn,
          branchCityColumn,
          branchStateColumn,
          branchZipColumn,
        ],
      },
      {
        title: 'main.tabs.equipment.rental_assets.current_rental_status.title',
        columnSelectorTitle:
          'main.tabs.equipment.rental_assets.current_rental_status.title',
        expandable: true,
        visible: true,
        expanderClass: 'expander-white',
        columns: [
          contractColumn,
          customerNameColumn,
          customerNumberColumn,
          isRpoColumn,
          isReRentColumn,
        ],
      },
      {
        title:
          'main.tabs.equipment.rental_assets.revenue_and_maintenance.title',
        columnSelectorTitle:
          'main.tabs.equipment.rental_assets.revenue_and_maintenance.title',
        expandable: true,
        visible: true,
        expanderClass: 'expander-white',
        columns: [
          ltdTotalRevenueColumn,
          ltdDaysOnRentColumn,
          ltdRentRevenueColumn,
          ytdRentRevenueColumn,
          avgRentRevenueColumn,
          ltdMaintenanceColumn,
          ltdLaborColumn,
          ltdPartsColumn,
          ytdMaintenanceColumn,
          avgMaintenanceColumn,
        ],
      },
      {
        title: 'main.tabs.equipment.rental_assets.age_and_residual_value.title',
        columnSelectorTitle:
          'main.tabs.equipment.rental_assets.age_and_residual_value.title',
        expandable: true,
        visible: true,
        expanderClass: 'expander-white',
        columns: [
          acquisitionDateColumn,
          rentalDateColumn,
          ageColumn,
          meterColumn,
          costColumn,
          nbvColumn,
        ],
      },
    ];
  };

  ExtendedMetricSetColumnGroups(): IGridColumnGroup[] {
    return [
      ...this.StandardMetricSetColumnGroups(true),
      {
        title: I18N_TITLES.MOM_RATE_CHANGE,
        columnSelectorTitle: I18N_TITLES.MOM_RATE_CHANGE,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_TOTAL}`,
            'RateChangeMoMTotal',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_COMPARED}`,
            'RateChangeMoMBenchmarked',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.BENCHMARK}`,
            'RateChangeMoMBench',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.DIFFERENCE}`,
            'RateChangeMoMDifference',
            true,
            null,
            false,
            null
          ),
        ],
      },
      {
        title: I18N_TITLES.YOY_RATE_CHANGE,
        columnSelectorTitle: I18N_TITLES.YOY_RATE_CHANGE,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_TOTAL}`,
            'RateChangeYoYTotal',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_COMPARED}`,
            'RateChangeYoYBenchmarked',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.BENCHMARK}`,
            'RateChangeYoYBench',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.DIFFERENCE}`,
            'RateChangeYoYDifference',
            true
          ),
        ],
      },
      {
        title: I18N_TITLES.PHYSICAL_UTILIZATION,
        columnSelectorTitle: I18N_TITLES.PHYSICAL_UTILIZATION,
        visible: true,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_TOTAL,
            'PhysicalUtilizationTotal',
            true
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_COMPARED,
            'PhysicalUtilizationBenchmarked',
            true
          ),
          this.PercentColumn(
            I18N_COLUMNS.BENCHMARK,
            'PhysicalUtilizationBench',
            true
          ),
          this.PercentDifferenceColumn(
            `${I18N_COLUMNS.DIFFERENCE}`,
            'PhysicalUtilizationBenchmarked',
            'PhysicalUtilizationBench',
            'PhysicalUtilizationDifference',
            null
          ),
        ],
      },
      {
        title: I18N_TITLES.FINANCIAL_UTILIZATION,
        columnSelectorTitle: I18N_TITLES.FINANCIAL_UTILIZATION,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_TOTAL}`,
            'DollarUtilizationTotal',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_COMPARED}`,
            'DollarUtilizationBenchmarked',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.BENCHMARK}`,
            'DollarUtilizationBench',
            true
          ),
          this.PercentDifferenceColumn(
            `${I18N_COLUMNS.DIFFERENCE}`,
            'DollarUtilizationBenchmarked',
            'DollarUtilizationBench',
            'DollarUtilizationDifference',
            null
          ),
        ],
      },
      {
        title: I18N_TITLES.UNAVAILABLE_UTILIZATION,
        columnSelectorTitle: I18N_TITLES.UNAVAILABLE_UTILIZATION,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_TOTAL}`,
            'ClientUnavailableTotal',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.CLIENT_COMPARED}`,
            'ClientUnavailableBenchmarked',
            true
          ),
          this.PercentColumn(
            `${I18N_COLUMNS.BENCHMARK}`,
            'ClientUnavailableBench',
            true
          ),
          this.PercentDifferenceColumn(
            `${I18N_COLUMNS.DIFFERENCE}`,
            'ClientUnavailableBenchmarked',
            'ClientUnavailableBench',
            'ClientUnavailableDifference',
            null
          ),
        ],
      },
      {
        title: I18N_TITLES.FLEET_AGE,
        columnSelectorTitle: I18N_TITLES.FLEET_AGE,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.NumberColumn(`${I18N_COLUMNS.CLIENT_TOTAL}`, 'FleetAgeTotal', 1),
          this.NumberColumn(
            `${I18N_COLUMNS.CLIENT_COMPARED}`,
            'FleetAgeBenchmarked',
            1
          ),
          this.NumberColumn(`${I18N_COLUMNS.BENCHMARK}`, 'FleetAgeBench', 1),
          this.NumberDifferenceColumn(
            `${I18N_COLUMNS.DIFFERENCE}`,
            'FleetAgeBenchmarked',
            'FleetAgeBench',
            'FleetAgeDifference'
          ),
        ],
      },
      {
        title: I18N_TITLES.TOTAL_FLEET_INVESTMENT,
        columnSelectorTitle: I18N_TITLES.TOTAL_FLEET_INVESTMENT,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.COST_IN_FLEET}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetTotal'
          ),
          this.NumberColumn(I18N_COLUMNS.UNITS_IN_FLEET, 'UnitCountTotal', 0),
          this.NumberColumn(I18N_COLUMNS.DAYS_IN_FLEET, 'DaysInFleet', 0),
          this.NumberColumn(I18N_COLUMNS.DAYS_ON_RENT, 'DaysOnRent', 0),
          this.NumberColumn(I18N_COLUMNS.DAYS_AVAILABLE, 'DaysAvailable', 0),
          this.NumberColumn(
            I18N_COLUMNS.DAYS_UNAVAILABLE,
            'DaysUnavailable',
            0
          ),
        ],
      },
      {
        title: I18N_TITLES.COMPARED_FLEET_INVESTMENT,
        columnSelectorTitle: I18N_TITLES.COMPARED_FLEET_INVESTMENT,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.COST_IN_FLEET}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetBenchmarked'
          ),
          this.NumberColumn(
            I18N_COLUMNS.UNITS_IN_FLEET,
            'UnitCountBenchmarked',
            0
          ),
        ],
      },
      {
        title: I18N_TITLES.TOTAL_MOM_GROWTH_IN_FLEET,
        columnSelectorTitle: I18N_TITLES.TOTAL_MOM_GROWTH_IN_FLEET,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetMoMTotal'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthMoMDiFTotal',
            true
          ),
        ],
      },
      {
        title: I18N_TITLES.COMPARED_MOM_GROWTH_IN_FLEET,
        columnSelectorTitle: I18N_TITLES.COMPARED_MOM_GROWTH_IN_FLEET,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetMoMBenchmarked'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthMoMDiFBenchmarked',
            true
          ),
          this.PercentColumn(
            I18N_COLUMNS.BENCHMARK_CHANGE,
            'GrowthMoMDiFBench',
            true
          ),
          this.CurrencyDifferenceWithoutUnitsColumn(
            `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetMoMDifference',
            'GrowthMoMDiFBenchmarked',
            'GrowthMoMDiFBench',
            'CostInFleetMoMDifference'
          ),
        ],
      },
      {
        title: I18N_TITLES.TOTAL_MOM_GROWTH_ON_RENT,
        columnSelectorTitle: I18N_TITLES.TOTAL_MOM_GROWTH_ON_RENT,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostOnRentMoMTotal'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthMoMDoRTotal',
            true
          ),
        ],
      },
      {
        title: I18N_TITLES.COMPARED_MOM_GROWTH_ON_RENT,
        columnSelectorTitle: I18N_TITLES.COMPARED_MOM_GROWTH_ON_RENT,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostOnRentMoMBenchmarked'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthMoMDoRBenchmarked',
            true
          ),
          this.PercentColumn(
            I18N_COLUMNS.BENCHMARK_CHANGE,
            'GrowthMoMDoRBench',
            true
          ),
          this.CurrencyDifferenceWithoutUnitsColumn(
            `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
            'CostOnRentMoMDifference',
            'GrowthMoMDoRBenchmarked',
            'GrowthMoMDoRBench',
            'CostOnRentMoMDifference'
          ),
        ],
      },
      {
        title: I18N_TITLES.TOTAL_YOY_GROWTH_IN_FLEET,
        columnSelectorTitle: I18N_TITLES.TOTAL_YOY_GROWTH_IN_FLEET,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetYoYTotal'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthYoYDiFTotal',
            true
          ),
        ],
      },
      {
        title: I18N_TITLES.COMPARED_YOY_GROWTH_IN_FLEET,
        columnSelectorTitle: I18N_TITLES.COMPARED_YOY_GROWTH_IN_FLEET,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetYoYBenchmarked'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthYoYDiFBenchmarked',
            true
          ),
          this.PercentColumn(
            I18N_COLUMNS.BENCHMARK_CHANGE,
            'GrowthYoYDiFBench',
            true
          ),
          this.CurrencyDifferenceWithoutUnitsColumn(
            `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
            'CostInFleetYoYDifference',
            'GrowthYoYDiFBenchmarked',
            'GrowthYoYDiFBench',
            'CostInFleetYoYDifference'
          ),
        ],
      },
      {
        title: I18N_TITLES.TOTAL_YOY_GROWTH_ON_RENT,
        columnSelectorTitle: I18N_TITLES.TOTAL_YOY_GROWTH_ON_RENT,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostOnRentYoYTotal'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthYoYDoRTotal',
            true
          ),
        ],
      },
      {
        title: I18N_TITLES.COMPARED_YOY_GROWTH_ON_RENT,
        columnSelectorTitle: I18N_TITLES.COMPARED_YOY_GROWTH_ON_RENT,
        expandable: true,
        expanderClass: 'expander-white',
        columns: [
          this.CurrencyColumn(
            `${I18N_COLUMNS.CLIENT_COST}, ${this.formatService.selectedCurrencySymbol}`,
            'CostOnRentYoYBenchmarked'
          ),
          this.PercentColumn(
            I18N_COLUMNS.CLIENT_CHANGE,
            'GrowthYoYDoRBenchmarked',
            true
          ),
          this.PercentColumn(
            I18N_COLUMNS.BENCHMARK_CHANGE,
            'GrowthYoYDoRBench',
            true
          ),
          this.CurrencyDifferenceWithoutUnitsColumn(
            `${I18N_COLUMNS.DIFFERENCE}, ${this.formatService.selectedCurrencySymbol}`,
            'CostOnRentYoYDifference',
            'GrowthYoYDoRBenchmarked',
            'GrowthYoYDoRBench',
            'CostOnRentYoYDifference'
          ),
        ],
      },
    ];
  }
}

export type IUrlResolver = (value: any, record: any, parent?: any) => string;

export type ILinkClickHandler = (
  column: IColumnConfig,
  record: any,
  parent?: any
) => boolean;
export type ILinkDslResolver = (
  column: IColumnConfig,
  record: any,
  parent?: any
) => any[];

export enum I18N_COUNTS {
  CUSTOMERS = 'main.core.common.counts.customers.count',
  PRODUCT_TYPES = 'main.core.common.counts.product_types.count',
  SALES_REPS = 'main.core.common.counts.sales_reps.count',
  TRANSACTIONS = 'main.core.common.counts.transactions.count',
}

export enum I18N_COLUMNS {
  CLIENT_TOTAL = 'main.core.tables.columns.client_total',
  CLIENT_BOOK = 'main.core.tables.columns.client_book',
  DIFFERENCE = 'main.core.tables.columns.difference',

  CLIENT_COMPARED = 'main.core.tables.columns.client_compared',
  BENCHMARK = 'main.core.tables.columns.benchmark',

  CLIENT_TQ_PREMIUM = 'main.core.tables.columns.client_tq_premium',
  REVENUE_ABOVE_TQ = 'main.core.tables.columns.revenue_above_tq',
  TRANSACTIONS = 'main.core.tables.columns.transactions',
  CLIENT_BQ_DISCOUNT = 'main.core.tables.columns.client_bq_discount',
  REVENUE_BELOW_BQ = 'main.core.tables.columns.revenue_below_bq',

  CLIENT = 'main.core.tables.columns.client',
  BOOK = 'main.core.tables.columns.book',
  FLOOR = 'main.tabs.changes_log.entry.floor',
  BENCH_MAX = 'main.core.tables.columns.bench_max',
  BENCH_TQ = 'main.core.tables.columns.bench_tq',
  BENCH_BQ = 'main.core.tables.columns.bench_bq',
  BENCH_MIN = 'main.core.tables.columns.bench_min',

  COST_IN_FLEET = 'main.core.tables.columns.cost_in_fleet',
  UNITS_IN_FLEET = 'main.core.tables.columns.units_in_fleet',
  DAYS_IN_FLEET = 'main.core.tables.columns.days_in_fleet',
  DAYS_ON_RENT = 'main.core.tables.columns.days_on_rent',
  DAYS_AVAILABLE = 'main.core.tables.columns.days_available',
  DAYS_UNAVAILABLE = 'main.core.tables.columns.days_unavailable',

  CLIENT_COST = 'main.core.tables.columns.client_cost',
  CLIENT_CHANGE = 'main.core.tables.columns.client_change',
  BENCHMARK_CHANGE = 'main.core.tables.columns.benchmark_change',

  PRODUCT_TYPE_DESCRIPTION = 'main.core.tables.columns.product_type_description',
  CLIENT_CATEGORY = 'main.core.tables.columns.client_category',
  CLIENT_PRODUCT_TYPE = 'main.core.tables.columns.client_product_type',
  EQUIPMENT_ID = 'main.core.tables.columns.equipment_id',
  ROUSE_CATEGORY = 'main.core.tables.columns.rouse_category',
  ROUSE_PRODUCT_TYPE = 'main.core.tables.columns.rouse_product_type',

  RE_RENT_REVENUE = 'main.core.tables.columns.re_rent_revenue',
  CREDIT_AMOUNT = 'main.core.tables.columns.credit_amount',
  EXCESS_MILEAGE_REVENUE = 'main.core.tables.columns.excess_mileage_revenue',
  UNCLASSIFIED_REVENUE = 'main.core.tables.columns.unclassified_revenue',

  SALES_REP_NAME = 'main.core.tables.columns.sales_rep_name',
  CUSTOMER_NAME = 'main.core.tables.columns.customer_name',
  CUSTOMER_NO = 'main.core.tables.columns.customer_no',

  IS_SUBSTITUTION = 'main.core.tables.columns.is_substitution',
  NATIONAL_ACCT = 'main.core.tables.columns.national_acct',
  NATIONAL_ACCT_CODE = 'main.core.tables.columns.national_acct_code',
  RPO = 'main.core.tables.columns.rpo',
  OFFSHORE = 'main.core.tables.columns.offshore',
  SPECIAL_PRICING = 'main.core.tables.columns.special_pricing',
  CONTRACT = 'main.core.tables.columns.contract',
  CONTRACT_PRICE_NO = 'main.core.tables.columns.contract_price_no',
  CYCLE_BILL_NO = 'main.core.tables.columns.cycle_bill_no',
  CONTRACT_START_DATE = 'main.core.tables.columns.contract_start_date',
  OUTLIER = 'main.core.tables.columns.outlier',
  OUTLIER_REASON = 'main.core.tables.columns.outlier_reason',

  JOB_SITE_ZIP_CODE = 'main.core.tables.columns.job_site_zip_code',
  BRANCH_ZIP_CODE = 'main.core.tables.columns.branch_zip_code',
  CLIENT_REGION = 'main.core.tables.columns.client_region',
  CLIENT_DISTRICT = 'main.core.tables.columns.client_district',
  LOCATION_CODE = 'main.core.tables.columns.location_code',
  ROUSE_REGION = 'main.core.tables.columns.rouse_region',
  ROUSE_DISTRICT = 'main.core.tables.columns.rouse_district',
  ROUSE_MARKET = 'main.core.tables.columns.rouse_market',
}

export enum I18N_TITLES {
  BASE_PATH = 'main.tabs.geography',

  // dimensions
  CAT_CLASS = 'main.core.tables.titles.dimensions.cat_class',

  // overall_rate_performance
  REVENUE_VS_BOOK_RATE_REVENUE = 'main.core.tables.titles.overall_rate_performance.revenue_vs_book_rate_revenue',
  REVENUE_VS_BENCHMARK_RATE_REVENUE = 'main.core.tables.titles.overall_rate_performance.revenue_vs_benchmark_rate_revenue',
  PREMIUM_TO_BENCHMARK_TOP_QUARTILE = 'main.core.tables.titles.overall_rate_performance.premium_to_benchmark_top_quartile',
  DISCOUNT_TO_BENCHMARK_BOTTOM_QUARTILE = 'main.core.tables.titles.overall_rate_performance.discount_to_benchmark_bottom_quartile',

  // new_monthly_rate_performance
  NEW_MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE = 'main.core.tables.titles.new_monthly_rate_performance.monthly_rate_revenue_vs_book_rate_revenue',
  NEW_MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE = 'main.core.tables.titles.new_monthly_rate_performance.monthly_rate_revenue_vs_benchmark_rate_revenue',
  NEW_MONTHLY_RATE_COMPARISON = 'main.core.tables.titles.new_monthly_rate_performance.monthly_rate_comparison',

  // monthly_rate_performance
  MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE = 'main.core.tables.titles.monthly_rate_performance.monthly_rate_revenue_vs_book_rate_revenue',
  MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE = 'main.core.tables.titles.monthly_rate_performance.monthly_rate_revenue_vs_benchmark_rate_revenue',
  MONTHLY_RATE_COMPARISON = 'main.core.tables.titles.monthly_rate_performance.monthly_rate_comparison',

  // weekly_rate_performance
  WEEKLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE = 'main.core.tables.titles.weekly_rate_performance.weekly_rate_revenue_vs_book_rate_revenue',
  WEEKLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE = 'main.core.tables.titles.weekly_rate_performance.weekly_rate_revenue_vs_benchmark_rate_revenue',
  WEEKLY_RATE_COMPARISON = 'main.core.tables.titles.weekly_rate_performance.weekly_rate_comparison',

  // daily_rate_performance
  DAILY_RATE_REVENUE_VS_BOOK_RATE_REVENUE = 'main.core.tables.titles.daily_rate_performance.daily_rate_revenue_vs_book_rate_revenue',
  DAILY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE = 'main.core.tables.titles.daily_rate_performance.daily_rate_revenue_vs_benchmark_rate_revenue',
  DAILY_RATE_COMPARISON = 'main.core.tables.titles.daily_rate_performance.daily_rate_comparison',

  // hourly_rate_performance
  HOURLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE = 'main.core.tables.titles.hourly_rate_performance.hourly_rate_revenue_vs_book_rate_revenue',
  HOURLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE = 'main.core.tables.titles.hourly_rate_performance.hourly_rate_revenue_vs_benchmark_rate_revenue',
  HOURLY_RATE_COMPARISON = 'main.core.tables.titles.hourly_rate_performance.hourly_rate_comparison',

  // rate_change
  MOM_RATE_CHANGE = 'main.core.tables.titles.rate_change.mom_rate_change',
  YOY_RATE_CHANGE = 'main.core.tables.titles.rate_change.yoy_rate_change',

  // utilization
  PHYSICAL_UTILIZATION = 'main.core.tables.titles.utilization.physical_utilization',
  FINANCIAL_UTILIZATION = 'main.core.tables.titles.utilization.financial_utilization',
  UNAVAILABLE_UTILIZATION = 'main.core.tables.titles.utilization.unavailable_utilization',

  // current_fleet
  FLEET_AGE = 'main.core.tables.titles.current_fleet.fleet_age',
  TOTAL_FLEET_INVESTMENT = 'main.core.tables.titles.current_fleet.total_fleet_investment',
  COMPARED_FLEET_INVESTMENT = 'main.core.tables.titles.current_fleet.compared_fleet_investment',

  // mom_growth_in_fleet_and_fleet_on_rent
  TOTAL_MOM_GROWTH_IN_FLEET = 'main.core.tables.titles.mom_growth_in_fleet_and_fleet_on_rent.total_mom_growth_in_fleet',
  COMPARED_MOM_GROWTH_IN_FLEET = 'main.core.tables.titles.mom_growth_in_fleet_and_fleet_on_rent.compared_mom_growth_in_fleet',
  TOTAL_MOM_GROWTH_ON_RENT = 'main.core.tables.titles.mom_growth_in_fleet_and_fleet_on_rent.total_mom_growth_on_rent',
  COMPARED_MOM_GROWTH_ON_RENT = 'main.core.tables.titles.mom_growth_in_fleet_and_fleet_on_rent.compared_mom_growth_on_rent',

  // yoy_growth_in_fleet_and_fleet_on_rent
  TOTAL_YOY_GROWTH_IN_FLEET = 'main.core.tables.titles.yoy_growth_in_fleet_and_fleet_on_rent.total_yoy_growth_in_fleet',
  COMPARED_YOY_GROWTH_IN_FLEET = 'main.core.tables.titles.yoy_growth_in_fleet_and_fleet_on_rent.compared_yoy_growth_in_fleet',
  TOTAL_YOY_GROWTH_ON_RENT = 'main.core.tables.titles.yoy_growth_in_fleet_and_fleet_on_rent.total_yoy_growth_on_rent',
  COMPARED_YOY_GROWTH_ON_RENT = 'main.core.tables.titles.yoy_growth_in_fleet_and_fleet_on_rent.compared_yoy_growth_on_rent',

  // transactions
  EQUIPMENT_TYPE_DETAIL = 'main.core.tables.titles.transactions.equipment_type_detail',
  LOCATION_DETAIL = 'main.core.tables.titles.transactions.location_detail',
  OTHER_REVENUE = 'main.core.tables.titles.transactions.other_revenue',
  SALES_REP_AND_CUSTOMER = 'main.core.tables.titles.transactions.sales_rep_and_customer',
  TRANSACTION_ATTRIBUTES = 'main.core.tables.titles.transactions.transaction_attributes',
}
