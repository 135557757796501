import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import {
    ActiveFilterService,
    MathService,
    BreadcrumbService,
    RentalGrowthData,
    IRentalGrowthItem,
    RentalGrowthItemType,
    RentalGrowthType,
} from './../../../core';
import { EquipmentService } from './../../equipment.service';
import { ProductTypeBaseComponent } from './../product-type-base.component';
import { SelectedProductTypeService } from './../selected-product-type.service';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'rdo-product-type-mom-change',
    templateUrl: 'product-type-mom-change.component.html',
    styleUrls: ['rental-growth-charts.scss']
})
export class ProductTypeMoMChangeComponent extends ProductTypeBaseComponent implements OnInit, OnDestroy {
    data: RentalGrowthData;
    type = RentalGrowthType.monthly;
    loadingProductTypesRentalGrowth = true;
    loadingChart = true;
    currentMonthRaw = null;
    previousMonthRaw = null;
    chartData: Array<RentalGrowthData>;

    constructor(
        public filterService: ActiveFilterService,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private equipmentService: EquipmentService,
        private mathService: MathService,
        selectedProductTypeService: SelectedProductTypeService,
        breadcrumbService: BreadcrumbService
    ) {
        super(selectedProductTypeService, breadcrumbService, filterService);
    }

    ngOnInit() {
        this.subscriptions.push(this.route.parent.params.subscribe(params => {
            const routeParams = this.getRouteParams(params);

            if (this.hasRouteChanged(this.params, routeParams)) {
                this.params = routeParams;

                const filter = this.filterService.getCurrentFilter();
                // eslint-disable-next-line no-prototype-builtins
                if (filter && filter.hasOwnProperty('dateFrom')) {
                    this.load();
                } else {
                    const filterSubscription = this.filterService.filterParams.subscribe(searchParams => {
                        filterSubscription.unsubscribe();
                        this.load();
                    });
                }
            }
        }));

        this.subscriptions.push(this.filterService.filterChange.subscribe(() => this.load()));

        this.subscriptions.push(this.selectedProductTypeService.productTypeChange.subscribe(p => {

            this.updateBreadcrumbs([
                {
                    title: 'main.tabs.equipment.product_types.mom_change',
                    class: 'active'
                }
            ]);

            if (this.params.productType !== p.ProductType) {
                this.selectedProductTypeService.loading = true;
                this.params.productType = p.ProductType;
                this.load();
            }
        }));

        if (!this.selectedProductTypeService.selectedProductType) {
            this.subscriptions.push(this.selectedProductTypeService.productType.subscribe(p => {
                this.updateBreadcrumbs([
                    {
                        title: 'main.tabs.equipment.product_types.mom_change',
                        class: 'active'
                    }
                ]);
            }));
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    load() {
        this.loadingProductTypesRentalGrowth = true;
        this.loadingChart = true;

        this.currentMonthRaw = null;
        this.previousMonthRaw = null;

        this.data = null;
        this.chartData = null;

        if (this.isDateValid()) {
            this.loadProductTypesRentalGrowth();
            this.loadChart();
        } else {
            this.loadingProductTypesRentalGrowth = false;
        }
    }

    private isDateValid(): boolean {
        const currentFilter = this.getCurrentFilter();
        const dateFrom = new Date(currentFilter.dateFrom.toDateString());
        const dateTo = new Date(currentFilter.dateTo.toDateString());
        const period = dateTo.getTime() - dateFrom.getTime();
        return (period <= 2678400000);
    }

    private loadProductTypesRentalGrowth = () => {
        this.loadingProductTypesRentalGrowth = true;
        const currentFilter = this.getCurrentFilter();
        this.equipmentService.getProductTypesRentalGrowth(currentFilter.monthFromId, currentFilter.monthToId,
            this.params.productType, false, this.params.category)
            .subscribe(result => {
                this.previousMonthRaw = result[0];
                this.currentMonthRaw = result[1];
                this.loadingProductTypesRentalGrowth = false;
                this.finishedLoad();
            });
    }

    private finishedLoad() {
        if (!this.loadingProductTypesRentalGrowth) {
            this.data = this.getRentalGrowthData(this.getCurrentFilter().dateFrom, this.currentMonthRaw, this.previousMonthRaw);
            this.selectedProductTypeService.loading = true;
        }
    }

    private loadChart() {
        this.loadingChart = true;

        this.equipmentService.getProductTypesRentalGrowthChart(this.params.productType, false, this.params.category)
            .subscribe(result => {
                if (result && result.length) {
                    this.finishedLoadChart(result);
                    this.loadingChart = false;
                }
            });
    }

    private finishedLoadChart(result: any) {
        const cd: RentalGrowthData[] = new Array<RentalGrowthData>();
        let i = 0;
        while (i < 13) {
            if (result[i] && result[i + 1]) {
                // Month is formatted like '2017-01-01T00:00:00' and javascript can't properly parse it
                const dateParts = result[i].Month.split('T')[0].split('-');
                const dateFrom = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
                cd.push(this.getRentalGrowthData(dateFrom, result[i], result[i + 1]));
            }
            i++;
        }
        cd.reverse();
        this.chartData = cd;
    }

    private getRentalGrowthData(startDate: Date, currentMonthRaw: any, previousMonthRaw: any): RentalGrowthData {
        if (currentMonthRaw && previousMonthRaw) {
            const physicalUtil: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.physical_utilization',
                ItemType: RentalGrowthItemType.physicalUtil,
                ClientAvg: currentMonthRaw.PhysicalUtilizationBenchmarked,
                ClientGrowth:
                    (
                        currentMonthRaw.PhysicalUtilizationBenchmarked !== null &&
                        previousMonthRaw.PhysicalUtilizationBenchmarked != null
                    ) ?
                        this.mathService.substract(
                            currentMonthRaw.PhysicalUtilizationBenchmarked,
                            previousMonthRaw.PhysicalUtilizationBenchmarked,
                            1)
                        : null,
                BenchmarkAvg: currentMonthRaw.PhysicalUtilizationBench,
                BenchmarkGrowth:
                    (
                        currentMonthRaw.PhysicalUtilizationBench != null &&
                        previousMonthRaw.PhysicalUtilizationBench != null
                    ) ?
                        this.mathService.substract(
                            currentMonthRaw.PhysicalUtilizationBench,
                            previousMonthRaw.PhysicalUtilizationBench,
                            1)
                        : null,
                CvsBGrowthUnitsDiff: null,
                CvsBGrowthPointsDiff: null,
                ClientTotal: currentMonthRaw.PhysicalUtilizationTotal,
                GrowthTotal:
                    (
                        currentMonthRaw.PhysicalUtilizationTotal != null &&
                        previousMonthRaw.PhysicalUtilizationTotal != null
                    ) ?
                        this.mathService.substract(
                            currentMonthRaw.PhysicalUtilizationTotal,
                            previousMonthRaw.PhysicalUtilizationTotal,
                            1)
                        : null
            };

            if ((physicalUtil.ClientGrowth != null && physicalUtil.BenchmarkGrowth != null)) {
                physicalUtil.CvsBGrowthPointsDiff = this.mathService.substract(physicalUtil.ClientGrowth, physicalUtil.BenchmarkGrowth, 1);
            }


            const unitCost: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.unit_cost',
                ItemType: RentalGrowthItemType.unitCost,
                ClientAvg: null,
                ClientGrowth: null,
                BenchmarkAvg: null,
                BenchmarkGrowth: null,
                CvsBGrowthUnitsDiff: null,
                CvsBGrowthPointsDiff: null,
                ClientTotal: null,
                GrowthTotal: null
            }

            const financialUtil: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.financial_utilization',
                ItemType: RentalGrowthItemType.financialUtil,
                ClientAvg: currentMonthRaw.DollarUtilizationBenchmarked,
                ClientGrowth: (currentMonthRaw.DollarUtilizationBenchmarked != null && previousMonthRaw.DollarUtilizationBenchmarked != null) ?
                    this.mathService.substract(currentMonthRaw.DollarUtilizationBenchmarked, previousMonthRaw.DollarUtilizationBenchmarked, 1) : null,
                BenchmarkAvg: currentMonthRaw.DollarUtilizationBench,
                BenchmarkGrowth: (currentMonthRaw.DollarUtilizationBench != null && previousMonthRaw.DollarUtilizationBench != null) ?
                    this.mathService.substract(currentMonthRaw.DollarUtilizationBench, previousMonthRaw.DollarUtilizationBench, 1) : null,
                CvsBGrowthUnitsDiff: null,
                CvsBGrowthPointsDiff: null,
                ClientTotal: currentMonthRaw.DollarUtilizationTotal,
                GrowthTotal: (currentMonthRaw.DollarUtilizationTotal != null && previousMonthRaw.DollarUtilizationTotal != null) ?
                    this.mathService.substract(currentMonthRaw.DollarUtilizationTotal, previousMonthRaw.DollarUtilizationTotal, 1) : null
            }

            if (financialUtil.ClientGrowth != null && financialUtil.BenchmarkGrowth != null) {
                financialUtil.CvsBGrowthPointsDiff = this.mathService.substract(financialUtil.ClientGrowth, financialUtil.BenchmarkGrowth, 1);
            }

            const unitsOnRent: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.units_on_rent',
                ItemType: RentalGrowthItemType.unitsOnRent,
                ClientAvg: currentMonthRaw.UnitsOnRentAvgMoMBenchmarked,
                ClientGrowth: currentMonthRaw.GrowthMoMDoRBenchmarked,
                BenchmarkAvg: null,
                BenchmarkGrowth: currentMonthRaw.GrowthMoMDoRBench,
                CvsBGrowthUnitsDiff: currentMonthRaw.UnitsOnRentAvgMoMDifference,
                CvsBGrowthPointsDiff: (currentMonthRaw.GrowthMoMDoRBenchmarked != null && currentMonthRaw.GrowthMoMDoRBench != null) ?
                    this.mathService.substract(currentMonthRaw.GrowthMoMDoRBenchmarked, currentMonthRaw.GrowthMoMDoRBench, 1) : null,
                ClientTotal: currentMonthRaw.UnitsOnRentAvgTotal,
                GrowthTotal: currentMonthRaw.GrowthMoMDoRTotal
            }

            const unitsOnFleet: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.units_in_fleet',
                ItemType: RentalGrowthItemType.unitsOnFleet,
                ClientAvg: currentMonthRaw.UnitsInFleetAvgMoMBenchmarked,
                ClientGrowth: currentMonthRaw.GrowthMoMDiFBenchmarked,
                BenchmarkAvg: null,
                BenchmarkGrowth: currentMonthRaw.GrowthMoMDiFBench,
                CvsBGrowthUnitsDiff: currentMonthRaw.UnitsInFleetAvgMoMDifference,
                CvsBGrowthPointsDiff: (currentMonthRaw.GrowthMoMDiFBenchmarked != null && currentMonthRaw.GrowthMoMDiFBench != null) ?
                    this.mathService.substract(currentMonthRaw.GrowthMoMDiFBenchmarked, currentMonthRaw.GrowthMoMDiFBench, 1) : null,
                ClientTotal: currentMonthRaw.UnitsInFleetAvgTotal,
                GrowthTotal: currentMonthRaw.GrowthMoMDiFTotal
            }

            //normalize monthly rates
            currentMonthRaw.MonthlyRateBenchmarked = currentMonthRaw.MonthlyRateBenchmarked || 0;
            currentMonthRaw.MonthlyRateBench = currentMonthRaw.MonthlyRateBench || 0;
            currentMonthRaw.MonthlyRateTotal = currentMonthRaw.MonthlyRateTotal || 0;
            previousMonthRaw.MonthlyRateBenchmarked = previousMonthRaw.MonthlyRateBenchmarked || 0;
            previousMonthRaw.MonthlyRateBench = previousMonthRaw.MonthlyRateBench || 0;
            previousMonthRaw.MonthlyRateTotal = previousMonthRaw.MonthlyRateTotal || 0;

            const monthlyRentalRates: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.monthly_rates',
                ItemType: RentalGrowthItemType.monthlyRentalRates,
                ClientAvg: currentMonthRaw.MonthlyRateBenchmarked,
                ClientGrowth: (currentMonthRaw.MonthlyRateBenchmarked !== 0 && previousMonthRaw.MonthlyRateBenchmarked !== 0) ? this.mathService.differenceNumber(currentMonthRaw.MonthlyRateBenchmarked, previousMonthRaw.MonthlyRateBenchmarked) : null,
                BenchmarkAvg: currentMonthRaw.MonthlyRateBench,
                BenchmarkGrowth: (currentMonthRaw.MonthlyRateBench !== 0 && previousMonthRaw.MonthlyRateBench !== 0) ? this.mathService.differenceNumber(currentMonthRaw.MonthlyRateBench, previousMonthRaw.MonthlyRateBench) : null,
                CvsBGrowthUnitsDiff: null,
                CvsBGrowthPointsDiff: null,
                ClientTotal: currentMonthRaw.MonthlyRateTotal,
                GrowthTotal: (currentMonthRaw.MonthlyRateTotal !== 0 && previousMonthRaw.MonthlyRateTotal !== 0) ? this.mathService.differenceNumber(currentMonthRaw.MonthlyRateTotal, previousMonthRaw.MonthlyRateTotal) : null
            }

            if (monthlyRentalRates.ClientGrowth !== null && monthlyRentalRates.BenchmarkGrowth !== null) {
                monthlyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(monthlyRentalRates.ClientGrowth, monthlyRentalRates.BenchmarkGrowth, 1);
            }

            //normalize weekly rates
            currentMonthRaw.WeeklyRateBenchmarked = currentMonthRaw.WeeklyRateBenchmarked || 0;
            currentMonthRaw.WeeklyRateBench = currentMonthRaw.WeeklyRateBench || 0;
            currentMonthRaw.WeeklyRateTotal = currentMonthRaw.WeeklyRateTotal || 0;
            previousMonthRaw.WeeklyRateBenchmarked = previousMonthRaw.WeeklyRateBenchmarked || 0;
            previousMonthRaw.WeeklyRateBench = previousMonthRaw.WeeklyRateBench || 0;
            previousMonthRaw.WeeklyRateTotal = previousMonthRaw.WeeklyRateTotal || 0;

            const weeklyRentalRates: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.weekly_rates',
                ItemType: RentalGrowthItemType.weeklyRentalRates,
                ClientAvg: currentMonthRaw.WeeklyRateBenchmarked,
                ClientGrowth: (currentMonthRaw.WeeklyRateBenchmarked !== 0 && previousMonthRaw.WeeklyRateBenchmarked !== 0) ? this.mathService.differenceNumber(currentMonthRaw.WeeklyRateBenchmarked, previousMonthRaw.WeeklyRateBenchmarked) : null,
                BenchmarkAvg: currentMonthRaw.WeeklyRateBench,
                BenchmarkGrowth: (currentMonthRaw.WeeklyRateBench !== 0 && previousMonthRaw.WeeklyRateBench !== 0) ? this.mathService.differenceNumber(currentMonthRaw.WeeklyRateBench, previousMonthRaw.WeeklyRateBench) : null,
                CvsBGrowthUnitsDiff: null,
                CvsBGrowthPointsDiff: null,
                ClientTotal: currentMonthRaw.WeeklyRateTotal,
                GrowthTotal: (currentMonthRaw.WeeklyRateTotal !== 0 && previousMonthRaw.WeeklyRateTotal !== 0) ? this.mathService.differenceNumber(currentMonthRaw.WeeklyRateTotal, previousMonthRaw.WeeklyRateTotal) : null
            }

            if (weeklyRentalRates.ClientGrowth !== null && weeklyRentalRates.BenchmarkGrowth !== null) {
                weeklyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(weeklyRentalRates.ClientGrowth, weeklyRentalRates.BenchmarkGrowth, 1);
            }

            //normalize daily rates
            currentMonthRaw.DailyRateBenchmarked = currentMonthRaw.DailyRateBenchmarked || 0;
            currentMonthRaw.DailyRateBench = currentMonthRaw.DailyRateBench || 0;
            currentMonthRaw.DailyRateTotal = currentMonthRaw.DailyRateTotal || 0;
            previousMonthRaw.DailyRateBenchmarked = previousMonthRaw.DailyRateBenchmarked || 0;
            previousMonthRaw.DailyRateBench = previousMonthRaw.DailyRateBench || 0;
            previousMonthRaw.DailyRateTotal = previousMonthRaw.DailyRateTotal || 0;

            const dailyRentalRates: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.daily_rates',
                ItemType: RentalGrowthItemType.dailyRentalRates,
                ClientAvg: currentMonthRaw.DailyRateBenchmarked,
                ClientGrowth: (currentMonthRaw.DailyRateBenchmarked !== 0 && previousMonthRaw.DailyRateBenchmarked !== 0) ? this.mathService.differenceNumber(currentMonthRaw.DailyRateBenchmarked, previousMonthRaw.DailyRateBenchmarked) : null,
                BenchmarkAvg: currentMonthRaw.DailyRateBench,
                BenchmarkGrowth: (currentMonthRaw.DailyRateBench !== 0 && previousMonthRaw.DailyRateBench !== 0) ? this.mathService.differenceNumber(currentMonthRaw.DailyRateBench, previousMonthRaw.DailyRateBench) : null,
                CvsBGrowthUnitsDiff: null,
                CvsBGrowthPointsDiff: null,
                ClientTotal: currentMonthRaw.DailyRateTotal,
                GrowthTotal: (currentMonthRaw.DailyRateTotal !== 0 && previousMonthRaw.DailyRateTotal !== 0) ? this.mathService.differenceNumber(currentMonthRaw.DailyRateTotal, previousMonthRaw.DailyRateTotal) : null
            }

            if (dailyRentalRates.ClientGrowth != null && dailyRentalRates.BenchmarkGrowth != null) {
                dailyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(dailyRentalRates.ClientGrowth, dailyRentalRates.BenchmarkGrowth, 1);
            }

            //normalize hourly rates
            currentMonthRaw.HourlyRateBenchmarked = currentMonthRaw.HourlyRateBenchmarked || 0;
            currentMonthRaw.HourlyRateBench = currentMonthRaw.HourlyRateBench || 0;
            currentMonthRaw.HourlyRateTotal = currentMonthRaw.HourlyRateTotal || 0;
            previousMonthRaw.HourlyRateBenchmarked = previousMonthRaw.HourlyRateBenchmarked || 0;
            previousMonthRaw.HourlyRateBench = previousMonthRaw.HourlyRateBench || 0;
            previousMonthRaw.HourlyRateTotal = previousMonthRaw.HourlyRateTotal || 0;

            const hourlyRentalRates: IRentalGrowthItem = {
                Title: 'main.tabs.equipment.product_types.change.table.hourly_rates',
                ItemType: RentalGrowthItemType.hourlyRentalRates,
                ClientAvg: currentMonthRaw.HourlyRateBenchmarked,
                ClientGrowth: (currentMonthRaw.HourlyRateBenchmarked !== 0 && previousMonthRaw.HourlyRateBenchmarked !== 0) ? this.mathService.differenceNumber(currentMonthRaw.HourlyRateBenchmarked, previousMonthRaw.HourlyRateBenchmarked) : null,
                BenchmarkAvg: currentMonthRaw.HourlyRateBench,
                BenchmarkGrowth: (currentMonthRaw.HourlyRateBench !== 0 && previousMonthRaw.HourlyRateBench !== 0) ? this.mathService.differenceNumber(currentMonthRaw.HourlyRateBench, previousMonthRaw.HourlyRateBench) : null,
                CvsBGrowthUnitsDiff: null,
                CvsBGrowthPointsDiff: null,
                ClientTotal: currentMonthRaw.HourlyRateTotal,
                GrowthTotal: (currentMonthRaw.HourlyRateTotal !== 0 && previousMonthRaw.HourlyRateTotal !== 0) ? this.mathService.differenceNumber(currentMonthRaw.HourlyRateTotal, previousMonthRaw.HourlyRateTotal) : null
            }

            if (hourlyRentalRates.ClientGrowth !== null && hourlyRentalRates.BenchmarkGrowth !== null) {
                hourlyRentalRates.CvsBGrowthPointsDiff = this.mathService.substract(hourlyRentalRates.ClientGrowth, hourlyRentalRates.BenchmarkGrowth, 1);
            }

            return new RentalGrowthData(startDate,
                this.type,
                unitsOnRent,
                unitsOnFleet,
                physicalUtil,
                unitCost,
                financialUtil,
                monthlyRentalRates,
                weeklyRentalRates,
                dailyRentalRates,
                hourlyRentalRates);
        }
        return null;
    }
}
