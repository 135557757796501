import * as _ from 'lodash';
import { BreadcrumbService, InstructionInfo, ActiveFilterService, SortOptions } from './../../core';
import { SelectedProductTypeService } from './selected-product-type.service';
import { ProductTypeCard } from './../../models';
import { OnDestroy } from '@angular/core';
// import { AuthenticationService } from 'src/app/core/authentication/authentication.service';

export class ProductTypesRouteParams {
	category: string;
	productType: string;
	geography: string;
	alert: string;
	sortOn: string;
	desc: boolean;
	reloadCards: boolean;

	get sortOptions(): SortOptions {
		return { sortOn: this.sortOn, descending: this.desc };
	}

	clone = (): any => {
		return {
			category: this.category || '',
			sortOn: this.sortOn,
			desc: this.desc
		};
	}
}


// TODO: Add Angular decorator.
export class ProductTypeBaseComponent implements OnDestroy {
	// parameters
	protected params: ProductTypesRouteParams;

	protected subscriptions = [];

	constructor(
		protected selectedProductTypeService: SelectedProductTypeService,
		protected breadcrumbService: BreadcrumbService,
		public filterService: ActiveFilterService
	) { }

	protected updateBreadcrumbs = (instructions: Array<InstructionInfo>) => {
		const category = this.params.category;
		const baseInstructions = [
			{
				title: category || 'main.tabs.equipment.all',
				linkDSL: ['/equipment', { category: category || '' }]
			},
			{
				title: this.selectedProductTypeService.selectedProductType.Description,
				linkDSL: ['product-types', this.selectedProductTypeService.selectedProductType.ProductType, { category: category || '' }]
			}
		];
		const breadcrumInstructions = _.union<InstructionInfo>(baseInstructions, instructions);
		this.breadcrumbService.update(breadcrumInstructions);
	}

	protected getCurrentFilter = () => {
		const filter = this.filterService.getCurrentFilter();
		// eslint-disable-next-line
		if (filter && filter.hasOwnProperty('dateFrom')) {
			return filter;
		}

		const options = sessionStorage.getItem('FILTER');
		const result = !options ? JSON.parse('{}') : JSON.parse(options);
		result.dateFrom = new Date(result.dateFrom);
		result.dateTo = new Date(result.dateTo);

		return result;
	}

	ngOnDestroy() {
		while (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.pop().unsubscribe();
		}
	}

	protected hasRouteChanged = (existingParams: ProductTypesRouteParams, newParams: ProductTypesRouteParams): boolean => {
		if (!existingParams) {
			return true;
		}

		return existingParams.productType !== newParams.productType ||
			existingParams.category !== newParams.category ||
			existingParams.geography !== newParams.geography ||
			existingParams.alert !== newParams.alert;
	}

	protected reloadCards = (existingParams: ProductTypesRouteParams, newParams: ProductTypesRouteParams): boolean => {
		if (!existingParams) {
			return true;
		}

		return existingParams.category !== newParams.category ||
			existingParams.geography !== newParams.geography ||
			existingParams.alert !== newParams.alert ||
			newParams.reloadCards;
	}

	protected hasProductTypeChanged = (existingParams: ProductTypesRouteParams, newParams: ProductTypesRouteParams): boolean => {
		if (!existingParams) {
			return true;
		}

		return existingParams.productType !== newParams.productType;
	}

	protected getRouteParams = (params): ProductTypesRouteParams => {
		const routeParams = new ProductTypesRouteParams();

		routeParams.productType = params.productType || null;
		routeParams.category = params.category ? decodeURIComponent(params.category) : null;
		routeParams.geography = params.geography ? decodeURIComponent(params.geography) : null;
		routeParams.alert = params.alert || null;

		routeParams.sortOn = params.sortOn || '';
		routeParams.desc = params.desc === 'true';
		routeParams.reloadCards = params.reloadCards === 'true';
		return routeParams;
	}
}
