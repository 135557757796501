import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UnsubscribeService } from './unsubscribe.service';
import { catchError } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-unsubscribe',
  templateUrl: './unsubscribe.component.html',
  styleUrls: ['./unsubscribe.component.scss']
})
export class UnsubscribeComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private unsubscribeService: UnsubscribeService,
              private translateService: TranslateService,
              private titleService: Title) {
  }

  private hash: string;
  private lang: string;
  private resultText: string;
  ngOnInit(): void {
    //this.hash = window.location.pathname.split('?')[0].split('/').pop(); // this.route.snapshot.paramMap.get('hash');

    this.hash = this.route.snapshot.paramMap.get('hash');
    this.lang = this.route.snapshot.queryParams.lang;
    this.unsubscribeService.setLanguages(this.lang);
    this.titleService.setTitle(this.translateService.instant('unsubscribe_page_title'));
    if (this.hash) {
      this.unsubscribeService.unsubscribeUserFromEmailList(this.hash)
        .pipe(
          catchError(() => this.resultText = this.translateService.instant('unsubscribe_fail'))).subscribe((result) => {
            if (result) {
              this.resultText = this.translateService.instant('unsubscribe_success');
            }
            else {
              this.resultText = this.translateService.instant('unsubscribe_fail');
            }
          });
    }
    else {
      this.resultText = this.translateService.instant('unsubscribe_fail');
    }

  }
}
