<rdo-overlay [enabled]="loading"></rdo-overlay>
<div *ngIf="data">
<div class="row">
	<div class="col-xs-4">
		<div class="panel panel-default rdo-card rdo-card-default-blue">
			<div class="panel-heading">
				<div class="panel-title">{{'main.tabs.equipment.rental_assets.asset_details.title' | translate}}</div>
			</div>
			<div class="panel-body large">
				<form class="form-horizontal">
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.asset_details.client_make' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.Make | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.asset_details.client_model' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.Model | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.asset_details.model_year' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.ModelYear | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.core.tables.common.category' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.ClientCategory | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.asset_details.cat_class' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.ClientProductType | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.summary_information.description' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.ClientProductTypeDescription | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.equipment_id' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.EquipmentId | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.asset_details.equipment_type' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.EquipmentType | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.asset_details.serial_no' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.SerialNo | rdoString}}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div class="col-xs-4">
		<div class="panel panel-default rdo-card rdo-card-default-blue">
			<div class="panel-heading">
				<div class="panel-title">{{'main.tabs.equipment.rental_assets.rouse_classification.title' | translate}}</div>
			</div>
			<div class="panel-body large">
				<form class="form-horizontal">
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.rouse_classification.rouse_make' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.RouseMake}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.rouse_classification.rouse_model' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.RouseModel}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.core.tables.common.category' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.RouseCategory}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.core.common.counts.product_types.singular' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.RouseProductType}}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div class="col-xs-4">
		<div class="panel panel-default rdo-card rdo-card-default-blue">
			<div class="panel-heading">
				<div class="panel-title">{{'main.tabs.equipment.rental_assets.branch_location.title' | translate}}</div>
			</div>
			<div class="panel-body large">
				<form class="form-horizontal">
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.branch_location.region' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.Region | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.branch_location.district' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.District | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.branch_location.branch' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.Branch | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.branch_location.branch_address' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.BranchAddress | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.branch_location.city' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.BranchCity | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.branch_location.state' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.BranchState | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.branch_location.postal_code' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.BranchZipCode | rdoString}}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<div class="row">
	<div class="col-xs-4">
		<div class="panel panel-default rdo-card rdo-card-default-blue">
			<div class="panel-heading">
				<div class="panel-title">{{'main.tabs.equipment.rental_assets.current_rental_status.title' | translate}}</div>
			</div>
			<div class="panel-body large">
				<form class="form-horizontal">
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.summary_information.on_rent' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.OnRent | rdoBoolean:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.current_rental_status.status' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.RentalStatus | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.summary_information.days_in_status' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{getDaysInSameStatusQty()}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.current_rental_status.contract_no' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.ContractNo | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.core.common.counts.customers.singular' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.CustomerName | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.current_rental_status.customer_no' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.CustomerNumber | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.current_rental_status.is_rpo' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.IsRPO | rdoBoolean:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.current_rental_status.is_rerent' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.IsReRent | rdoBoolean:translateService.currentLang}}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div class="col-xs-4">
		<div class="panel panel-default rdo-card rdo-card-default-blue">
			<div class="panel-heading">
				<div class="panel-title">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.history' | translate}}</div>
			</div>
			<div class="panel-body large">
				<form class="form-horizontal">
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_total_revenue' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.LifeToDateRevenue | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_days_on_rent' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.LifeToDateDaysOnRent | rdoNumber:1}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_rent_revenue' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.LifeToDateRentRevenue | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ytd_rent_revenue' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.YearToDateRentRevenue | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.avg_rent_revenue' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.AverageRevenue | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_maintenance' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.LifeToDateMaintainance | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_labor' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.LifeToDateLabor | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ltd_parts' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.LifeToDateParts | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.ytd_maintenance' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.YearToDateMaintainance | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.revenue_and_maintenance.avg_maintenance' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.AverageMantainance | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>

	<div class="col-xs-4">
		<div class="panel panel-default rdo-card rdo-card-default-blue">
			<div class="panel-heading">
				<div class="panel-title">{{'main.tabs.equipment.rental_assets.age_and_residual_value.title' | translate}}</div>
			</div>
			<div class="panel-body large">
				<form class="form-horizontal">
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.age_and_residual_value.acquisition_date' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.AcquisitionDate | rdoLocalizedDate:'MMMM d, y':locale | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.age_and_residual_value.date_put_in_rental' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.DatePutInRental | rdoLocalizedDate:'MMMM d, y':locale | rdoString}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.age_and_residual_value.age_in_months' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.Age | rdoNumber:1}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.age_and_residual_value.meter' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.Meter | rdoCurrency:false:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.age_and_residual_value.cost' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.Cost | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
					<div class="form-group">
						<label class="col-xs-5 control-label">{{'main.tabs.equipment.rental_assets.age_and_residual_value.nbv' | translate}}</label>
						<div class="col-xs-7">
							<p class="form-control-static">{{data.NetBookValue | rdoCurrency:true:translateService.currentLang}}</p>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

</div>
