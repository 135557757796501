import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';

import * as _ from 'lodash';

import { ActiveFilterService } from './../query/active-filter.service';
import { ConfigService } from './config.service';
//import { AuthService } from './../user';

import { PagedQuery } from '../../models';
import { BreadcrumbService } from '../view';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocaleService } from '../services';

Injectable();
//TODO PRS DEDUPE
export class ExportSpreadsheetBody {
	Columns: any;
	Filters: any;
	groupBySalesRep?: string;
	groupByProductType?: string;
	useRouseSchema?: string;
	groupByCustomer?: string;

	constructor(columns: any, filters: any) {
		this.Columns = JSON.stringify(columns);
		this.Filters = JSON.stringify(filters);
	}
}

Injectable();
export class ChartExportSpreadsheetBody extends ExportSpreadsheetBody {
	Data: any;
	Image: any;

	/**
	 *
	 */
	constructor(columns: any, filters: any, data: any, image: any) {
		super(columns, filters);
		this.Data = data;
		this.Image = image;
	}
}

@Injectable()
export class ExcelExportService {
	constructor(
		private authenticationService: AuthenticationService,
		private filterService: ActiveFilterService,
		private config: ConfigService,
		private breadCrumbService: BreadcrumbService,
		private localeService: LocaleService
	) {
	}



	generateGridSpreadsheet = (endpoint: string, exportData: ExportSpreadsheetBody, pagedSorted: PagedQuery): Observable<Record<any, any>[]> => {
		return Observable.create(observer => {

			this.authenticationService
				.getAuthToken()
				.subscribe(token => {
					const today = new Date();
					const dd = today.getDate();
					const mm = today.getMonth() + 1;
					const yyyy = today.getFullYear();

					const todayFormatted = `${mm < 10 ? '0' + mm.toString() : mm.toString()}-${dd < 10 ? '0' + dd.toString() : dd.toString()}-${yyyy}`;
					endpoint = endpoint.indexOf('/') === 0 ? endpoint : '/' + endpoint;
					const breadCrumbInfo = this.breadCrumbService.instructions.map((ins) => ins.title).join(' - ');
					const postBody: any = {};
					postBody.name = `${breadCrumbInfo.replace('ALL ', '')}-${todayFormatted}`;
					postBody.name = postBody.name.replace(/[/\\?%*:|"<>]/g, '');
					Object.assign(postBody, pagedSorted);
					Object.assign(postBody, exportData);

					this.filterService.filterParams.subscribe(f => {
						const rouseCategoryList = postBody.RouseCategoryList;
						Object.assign(postBody, f);
						if (pagedSorted && pagedSorted.OrderBy) {
							Object.assign(postBody, pagedSorted);
						}
						if (rouseCategoryList && rouseCategoryList.length) {
							postBody.RouseCategoryList = rouseCategoryList;
						}

						if (this.authenticationService &&
							this.authenticationService._userInfoView &&
							this.authenticationService._userInfoView) {

							postBody.ClientId = this.authenticationService._userInfoView.SelectedClient.ClientID.toString();
						}
						this.config.baseApiUrl.subscribe(apiUrl => {
							const url = apiUrl + endpoint;

							const xhr = new XMLHttpRequest();

							xhr.open('POST', url, true);
							xhr.setRequestHeader('Content-Type', 'application/json');
							xhr.setRequestHeader('Authorization', 'Bearer ' + token.AccessToken);
							xhr.setRequestHeader('Accept-Language', this.localeService.getLocale());
							xhr.withCredentials = true;
							xhr.responseType = 'blob';

							xhr.onreadystatechange = () => {
								if (xhr.readyState === 4) {
									if (xhr.status === 200) {
										const contentType = xhr.getResponseHeader('content-type') ||
											'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
										const blob = new Blob([xhr.response], { type: contentType });
										observer.next(blob);
										observer.complete();
									} else {
										observer.error(xhr.response);
									}
								}
							};

							xhr.send(JSON.stringify(postBody));
						});
					});
				});
		});
	}

	generateChartSpreadsheet = (endpoint: string, exportData: ChartExportSpreadsheetBody, pagedSorted: PagedQuery): Observable<Record<any, any>[]> => {
		return Observable.create(observer => {

			this.authenticationService
				.getAuthToken()
				.subscribe(token => {
					const today = new Date();
					const dd = today.getDate();
					const mm = today.getMonth() + 1;
					const yyyy = today.getFullYear();

					const todayFormatted = `${mm < 10 ? '0' + mm.toString() : mm.toString()}-${dd < 10 ? '0' + dd.toString() : dd.toString()}-${yyyy}`;
					endpoint = endpoint.indexOf('/') === 0 ? endpoint : '/' + endpoint;
					//let breadCrumbInfo = this.breadCrumbService.instructions.map((ins) => ins.title).join(' - ');
					const postBody: any = {};
					//postBody.name = `${breadCrumbInfo.replace('ALL ', '')}-${todayFormatted}`;
					//postBody.name = postBody.name.replace(/[/\\?%*:|"<>]/g, '');
					Object.assign(postBody, pagedSorted);
					Object.assign(postBody, exportData);

					this.filterService.filterParams.subscribe(f => {
						const rouseCategoryList = postBody.RouseCategoryList;
						Object.assign(postBody, f);
						if (pagedSorted && pagedSorted.OrderBy) {
							Object.assign(postBody, pagedSorted);
						}
						if (rouseCategoryList && rouseCategoryList.length) {
							postBody.RouseCategoryList = rouseCategoryList;
						}

						if (this.authenticationService &&
							this.authenticationService._userInfoView &&
							this.authenticationService._userInfoView) {

							postBody.ClientId = this.authenticationService._userInfoView.SelectedClient.ClientID.toString();
						}
						this.config.baseApiUrl.subscribe(apiUrl => {
							const url = apiUrl + endpoint;

							const xhr = new XMLHttpRequest();

							xhr.open('POST', url, true);
							xhr.setRequestHeader('Content-Type', 'application/json');
							xhr.setRequestHeader('Authorization', 'Bearer ' + token.AccessToken);
							xhr.setRequestHeader('Accept-Language', this.localeService.getLocale());
							xhr.withCredentials = true;
							xhr.responseType = 'blob';

							xhr.onreadystatechange = () => {
								if (xhr.readyState === 4) {
									if (xhr.status === 200) {
										const contentType = xhr.getResponseHeader('content-type') ||
											'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
										const blob = new Blob([xhr.response], { type: contentType });
										observer.next(blob);
										observer.complete();
									} else {
										observer.error(xhr.response);
									}
								}
							};

							xhr.send(JSON.stringify(postBody));
						});
					});
				});
		});
	}


	generateCustomGridsSpreadsheet = (endpoint: string, exportSettings: any): Observable<Record<any, any>[]> => {
		return Observable.create(observer => {

			this.authenticationService
				.getAuthToken()
				.subscribe(token => {
					const today = new Date();
					const dd = today.getDate();
					const mm = today.getMonth() + 1;
					const yyyy = today.getFullYear();

					const todayFormatted = `${mm < 10 ? '0' + mm.toString() : mm.toString()}-${dd < 10 ? '0' + dd.toString() : dd.toString()}-${yyyy}`;
					endpoint = endpoint.indexOf('/') === 0 ? endpoint : '/' + endpoint;
					const postBody: any = {};
					(<any>Object).assign(postBody, exportSettings);

					this.filterService.filterParams.subscribe(f => {
						if (this.authenticationService && this.authenticationService._userInfoView)
							{postBody.ClientId = this.authenticationService._userInfoView.SelectedClient.ClientID.toString();}

						this.config.baseMetricsApiUrl.subscribe(metricsApiUrl => {
							const url = metricsApiUrl + endpoint;

							const xhr = new XMLHttpRequest();

							xhr.open('POST', url, true);
							xhr.setRequestHeader('Content-Type', 'application/json');
							xhr.setRequestHeader('Authorization', 'Bearer ' + token.AccessToken);
							xhr.setRequestHeader('Accept-Language', this.localeService.getLocale());
							xhr.withCredentials = true;
							xhr.responseType = 'blob';

							xhr.onreadystatechange = () => {
								if (xhr.readyState === 4) {
									if (xhr.status === 200) {
										const contentType = xhr.getResponseHeader('content-type') ||
											'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
										const blob = new Blob([xhr.response], { type: contentType });
										observer.next(blob);
										observer.complete();
									} else {
										observer.error(xhr.response);
									}
								}
							};

							xhr.send(JSON.stringify(postBody/*, () => {
								const seen = new WeakSet();
								return (key, value) => {
								  if (typeof value === "object" && value !== null) {
									if (seen.has(value)) {
									  return;
									}
									seen.add(value);
								  }
								  return value;
								};
							  }*/
							));
						});
					});
				});
		});
	}
}
