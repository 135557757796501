import { Component, Input, Output, OnInit, OnDestroy, EventEmitter, OnChanges, SimpleChanges, HostListener } from '@angular/core';

import * as _ from 'lodash';

import { MetricsGridConfig } from './MetricsGridConfig';
import { IGridColumnGroup } from './IGridColumnGroup.interface';
import { AuthenticationService } from '../authentication/authentication.service';
import { ActiveFilterService, FormatService } from '../query';
//import { FilterValues } from 'src/app/models';
import { FilterValues } from '../../models';
import { TranslateService } from '@ngx-translate/core';
import { I18N_TITLES } from './column-definition.service';
import { IntroJsService } from '../introJs';

@Component({
	selector: 'rdo-column-selector-product-type',
	templateUrl: './column-selector-product-type.component.html',
    styleUrls: ['./column-selector-product-type.component.scss']
})
export class ColumnSelectorProductTypeComponent implements OnChanges, OnInit, OnDestroy {
	@Input() gridConfig: MetricsGridConfig;
	@Input() size = 'xs';
	@Output() visibilityChanged: EventEmitter<IGridColumnGroup> = new EventEmitter<IGridColumnGroup>();

	// eslint-disable-next-line @angular-eslint/no-output-rename
	@Output('downloadExcelClick') excelClick = new EventEmitter();
	@Output() deleteClick: EventEmitter<MetricsGridConfig> = new EventEmitter<MetricsGridConfig>();
	listItems: Array<any> = [];
	protected subscriptions = [];
	public showDropDown = false;

	constructor(
		public authenticationService: AuthenticationService,
		private filterService: ActiveFilterService,
		private translateService: TranslateService,
		private formatService: FormatService,
		private introJsService: IntroJsService
	) { }

	private translate(path: string) {
		return this.translateService.instant(path);
	}

	private get headers() {
		const headers: Array<string> = [
			'main.core.tables.titles.dimensions.title',
			'main.core.tables.titles.overall_rate_performance.title',
			'main.core.tables.titles.new_monthly_rate_performance.title',
			'main.core.tables.titles.monthly_rate_performance.title',
			'main.core.tables.titles.weekly_rate_performance.title',
			'main.core.tables.titles.daily_rate_performance.title',
			'main.core.tables.titles.hourly_rate_performance.title',
			'main.core.tables.titles.rate_change.title',
			'main.core.tables.titles.utilization.title',
			'main.core.tables.titles.current_fleet.title',
			'main.core.tables.titles.mom_growth_in_fleet_and_fleet_on_rent.title',
			'main.core.tables.titles.yoy_growth_in_fleet_and_fleet_on_rent.title',
		];
		return headers;
	}

	ngOnInit() {
		const catClassConfigured = typeof _.find(this.gridConfig.groups,
			x => x.columnSelectorTitle === 'main.core.tables.titles.dimensions.cat_class') !== 'undefined';
		if (!catClassConfigured || (this.filterService.getCurrentFilter().useRouseSchema && this.listItems.length > 1)) {
			this.listItems[0].hidden = true;
			this.listItems[1].hidden = true;
		}
		if (this.filterService.getCurrentFilter().useRouseSchema && this.gridConfig && this.gridConfig.groups && catClassConfigured) {
			this.gridConfig.groups[0].visible = false;
			this.visibilityChanged.next(this.gridConfig.groups[0]);
		}
		this.subscriptions.push(this.filterService.filterChange.subscribe((filterValues: FilterValues) => {
			if (this.gridConfig && this.gridConfig.groups) {
				if (this.listItems.length > 1) {
					this.listItems[0].hidden = filterValues.useRouseSchema || !catClassConfigured;
					this.listItems[1].hidden = filterValues.useRouseSchema || !catClassConfigured;
				}
				if (filterValues.useRouseSchema && catClassConfigured) {
					this.gridConfig.groups[0].visible = false;
					this.visibilityChanged.next(this.gridConfig.groups[0]);
				}
			}
		}));
	}

	ngOnDestroy() {
		while (this.subscriptions && this.subscriptions.length > 0) {
			this.subscriptions.pop().unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		for (const propName in changes) {
			if (propName === 'gridConfig') {
				this.buildChildren();
			}
		}
	}
    
    handleTranslation(text: string): string {
        return this.formatService.translateAndFormat(text, false);
    }

	toggleVisibility(e: any, group: IGridColumnGroup) {
		e.stopPropagation();
		if (!group.locked || group.forceCanClose) {
			group.visible = !group.visible;
			this.visibilityChanged.next(group);
		}
	}

	clickExcelDownload(e: any, group: IGridColumnGroup) {
		e.stopPropagation();
		this.excelClick.next(group);
	}

	clickDelete(e: any) {
		e.stopPropagation();
		this.deleteClick.next();
	}

	private pushColumn(column: I18N_TITLES) {
		this.listItems.push(_.find(this.gridConfig.groups, x => x.columnSelectorTitle === column));
	}

	private buildChildren = () => {
		this.listItems = [];
		for (let i = 0; i < this.headers.length; i++) {
			this.listItems.push({ title: this.headers[i], isHeader: true });
			switch (i) {
				case 0:
					this.listItems.push(_.find(this.gridConfig.groups,
						x => x.columnSelectorTitle === 'main.core.tables.titles.dimensions.cat_class') || { title: '' });
					break;
				case 1:
					this.pushColumn(I18N_TITLES.REVENUE_VS_BOOK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.REVENUE_VS_BENCHMARK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.PREMIUM_TO_BENCHMARK_TOP_QUARTILE);
					this.pushColumn(I18N_TITLES.DISCOUNT_TO_BENCHMARK_BOTTOM_QUARTILE);
					break;
				case 2:
					this.pushColumn(I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.NEW_MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.NEW_MONTHLY_RATE_COMPARISON);
					break;
				case 3:
					this.pushColumn(I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.MONTHLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.MONTHLY_RATE_COMPARISON);
					break;
				case 4:
					this.pushColumn(I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.WEEKLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.WEEKLY_RATE_COMPARISON);
					break;
				case 5:
					this.pushColumn(I18N_TITLES.DAILY_RATE_REVENUE_VS_BOOK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.DAILY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.DAILY_RATE_COMPARISON);
					break;
				case 6:
					this.pushColumn(I18N_TITLES.HOURLY_RATE_REVENUE_VS_BOOK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.HOURLY_RATE_REVENUE_VS_BENCHMARK_RATE_REVENUE);
					this.pushColumn(I18N_TITLES.HOURLY_RATE_COMPARISON);
					break;
				case 7:
					this.pushColumn(I18N_TITLES.MOM_RATE_CHANGE);
					this.pushColumn(I18N_TITLES.YOY_RATE_CHANGE);
					break;
				case 8:
					this.pushColumn(I18N_TITLES.PHYSICAL_UTILIZATION);
					this.pushColumn(I18N_TITLES.FINANCIAL_UTILIZATION);
					this.pushColumn(I18N_TITLES.UNAVAILABLE_UTILIZATION);
					break;
				case 9:
					this.pushColumn(I18N_TITLES.FLEET_AGE);
					this.pushColumn(I18N_TITLES.TOTAL_FLEET_INVESTMENT);
					this.pushColumn(I18N_TITLES.COMPARED_FLEET_INVESTMENT);
					break;
				case 10:
					this.pushColumn(I18N_TITLES.TOTAL_MOM_GROWTH_IN_FLEET);
					this.pushColumn(I18N_TITLES.COMPARED_MOM_GROWTH_IN_FLEET);
					this.pushColumn(I18N_TITLES.TOTAL_MOM_GROWTH_ON_RENT);
					this.pushColumn(I18N_TITLES.COMPARED_MOM_GROWTH_ON_RENT);
					break;
				case 11:
					this.pushColumn(I18N_TITLES.TOTAL_YOY_GROWTH_IN_FLEET);
					this.pushColumn(I18N_TITLES.COMPARED_YOY_GROWTH_IN_FLEET);
					this.pushColumn(I18N_TITLES.TOTAL_YOY_GROWTH_ON_RENT);
					this.pushColumn(I18N_TITLES.COMPARED_YOY_GROWTH_ON_RENT);
					break;
				default:
					break;
			}
		}
	}
	
	@HostListener('document:click', ['$event'])
    onClickOut(event: PointerEvent) {
        this.introJsService.handleClickOut(event, ['column-selector-'], () => {this.showDropDown = false});
    }
}

class SelectionHeader {
	title: string;
	children: Array<IGridColumnGroup>;

	constructor(init?: Partial<SelectionHeader>) {
		(<any>Object).assign(this, init);
	}
}
